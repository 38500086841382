import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledTreatmentModalContainer = styled.article`
    h2 {
        margin: 0;
        padding: 0;

        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;

        margin-bottom: ${pxToRem(30)};
    }
`

export const StyledTextArea = styled.textarea`
    width: 100%;
    height: ${pxToRem(150)};
    border: 1px solid #dee5ef;
    border-radius: ${pxToRem(4)};
    resize: none;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: ${pxToRem(16)};
    line-height: 140%;

    color: ${dark_gray};
    padding: ${pxToRem(18)};

    &:focus {
        outline: none;
    }

    ::placeholder {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;

        color: #a4b0c0;
    }

    margin-bottom: 30px;
`

const buttonSpecs = css`
    border: none;
    background-color: transparent;
    width: 120px;
    height: 50px;
    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #ffffff;

    cursor: pointer;
`

export const StyledModalButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: end;
`

export const StyledCancelButton = styled.button`
    ${buttonSpecs}
    background-color: #A4B0C0;
    margin-right: 24px;
`

export const StyledSaveButton = styled.button`
    ${buttonSpecs}
    background-color: #2281C4;
`
