import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

const tableReset = css`
    list-style: none;
    padding: 0;
    margin: 0;
`
export const StyledQualityTableContainerWrap = styled.article`
    ${DEVICE.desktop} {
        width: ${pxToRem(1118)};
    }
`

export const StyledReviewTableHeader = styled.ul`
    width: 1145px;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ebeff7;

    display: flex;
    flex-direction: row;
`

export const StyledReviewTableHeaderItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #73839c;

    padding: 12px 0;

    :nth-child(1) {
        margin-left: 26px;
        width: 516px;
        /* background-color: red; */
    }

    :nth-child(2) {
        width: 113px;
        /* background-color: blue; */
    }

    :nth-child(3) {
        width: 143px;
        /*   background-color: orange; */
    }

    :nth-child(4) {
        width: 241px;
        /*  background-color: green; */
    }

    :nth-child(5) {
        width: 10px;
        /* background-color: yellow; */
    }
`

export const StyledTableBodyRowContainer = styled.article`
    width: 1145px;
`

export const StyledTableBodyRow = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ffffff;
    border-bottom: 1px solid #dee5ef;

    display: flex;
    flex-direction: row;
    padding: 40px 0 30px;
`

export const StyledIncentiveTitle = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* identical to box height, or 21px */

    color: #293854;
`

export const StyledTableBodyItem = styled.li<{ status?: string }>`
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #293854;
    }

    padding: 0px 0;

    :nth-child(1) {
        margin-left: 26px;

        width: 516px;
    }

    :nth-child(2) {
        width: 113px;
        /* background-color: blue; */
    }

    :nth-child(3) {
        width: 143px;
        /* background-color: orange; */
    }

    :nth-child(4) {
        width: 241px;
        /* background-color: green; */
    }

    :nth-child(5) {
        width: 50px;
        /* background-color: yellow; */
    }
`
export const StyledFeedbackLabel = styled.label`
    :hover {
        cursor: pointer;
    }
`

export const StyledFeedbackList = styled.ul`
    ${tableReset}
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 10px 0;
`

export const StyledFeedbackListItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #394a64;

    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 10px;

    input {
        margin: 0;
        margin-right: 6px;
    }
`

export const StyledReadOnlyFeedbackStatus = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #394a64;
        height: 30px;
    }
`

export const StyledReferralActionContainer = styled.article`
    width: 100%;

    display: flex;
    flex-direction: column;

    figure {
        margin: 0;
    }

    button {
        text-align: left;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;

        color: #2281c4;
        cursor: pointer;

        :disabled {
            cursor: default;
            color: #293854;
        }
    }

    button {
        border: 0;
        background-color: transparent;
        cursor: pointer;

        :hover {
            border-radius: 15px;
        }
    }
`

export const StyledFeedbackActionContainer = styled.article`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    figure {
        margin: 0;
    }

    button {
        border: 0;
        background-color: transparent;
        cursor: pointer;

        :hover {
            border-radius: 15px;
        }
    }
`

export const StyledUploadButtonContainer = styled.article`
    width: 100%;
    display: flex;
    flex-direction: row;

    a {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        color: #2281c4;
        cursor: pointer;
        align-self: center;
    }

    button {
        width: 35px;
        align-self: center;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: center;

        :hover {
            border-radius: 15px;
        }
    }
`

export const StyledReferralContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    figure {
        margin: 0;
    }

    button {
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        color: #2281c4;
        cursor: pointer;
        :disabled {
            cursor: default;
            color: #293854;
        }
    }
`

export const StyledUploadedDocsList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 10px 0;
`

export const StyledUploadedDocsListItem = styled.li`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    :hover {
        text-decoration: underline;
        color: #2281c4;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        color: #2281c4;

        margin-right: 10px;
        margin-top: 0;
        margin-bottom: 0;

        cursor: pointer;

        max-width: 50ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const StyledUploadedQualityDocsListItem = styled.li`
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    :hover {
        text-decoration: underline;
        color: #2281c4;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        color: #2281c4;

        margin-right: 10px;
        margin-top: 0;
        margin-bottom: 0;

        cursor: pointer;

        width: 260px;

        max-width: 50ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const StyledDownloadFileButton = styled.button`
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    text-align: left;

    cursor: pointer;
`

export const StyledButton = styled.button`
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    text-align: left;

    figure {
        margin: 0;
    }

    cursor: pointer;
`

export const StyledCalendarButton = styled.button`
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;

    figure {
        margin: 0;
        margin-top: 8px;
    }

    cursor: pointer;
`
export const StyledCalendarContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
`

export const StyledDateContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
`

export const StyledApproveMakePendingButtonsContainer = styled.article`
    margin-top: 23px;
    display: flex;
    flex-direction: row;
`

export const StyledMakePendingButton = styled.button`
    width: 195px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #2281c4;
    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #2281c4;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-right: 15px;

    figure {
        margin: 0;
        margin-right: 5px;
    }

    cursor: pointer;
`

export const StyledApproveButton = styled.button`
    width: 120px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #2281c4;
    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #2281c4;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    figure {
        margin: 0;
        margin-right: 5px;
    }

    cursor: pointer;
`
