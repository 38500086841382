import { IDropDownListStyleParams } from 'components/DropDownList/index.style'
import styled from 'styled-components'
import { UI_COLORS } from 'theme/constants'
import { DEVICE, pxToRem } from 'utilities/style'

export const StyledReferralModalDialogContainer = styled.div`
    display: flex;
    flex-direction: row;

    figure {
        margin: 0;
        position: relative;
        left: ${pxToRem(40)};
        top: ${pxToRem(58)};
        z-index: 1;
    }
`

export const StyledReferralTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-left: ${pxToRem(-10)};
    margin-top: ${pxToRem(-40)};
    justify-content: left;
    height: ${pxToRem(30)};
    margin-bottom: ${pxToRem(50)};
`

export const StyledReferralDropDownContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: ${pxToRem(30)};
    margin-bottom: ${pxToRem(20)};
    margin-right: ${pxToRem(40)};
`

export const StyledDropDownGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-top: ${pxToRem(-42)};
    margin-left: ${pxToRem(-5)};
`

export const SpecialtyDropDownListStyleParams: IDropDownListStyleParams = {
    width: 250,
    fontSize: '16px',
    paddingTop: '8px',
    paddingLeft: '25px !important',
    marginTop: '-10px',
    marginLeft: '0px',
    indicatorLeftTranslate: 20,
    indicatorMarginTop: '-50px',
    placeholderTop: '5px !important',
    singleValueMarginTop: '12px',
    singleValueFontSize: '16px',
    singleValueFontWeight: '400',
    singleValueColor: UI_COLORS.black,
    placeholderColor: UI_COLORS.light_gray3,
    placeholderFontSize: '16px',
    placeholderFontWeight: '500',
    inputColor: UI_COLORS.black,
    inputPaddingLeft: '45px',
    inputPaddingTop: '10px'
}

export const StyledButtonContainer = styled.article`
    display: flex;
    flex-direction: row;
    margin-top: ${pxToRem(25)};

    ${DEVICE.tablet} {
        position: absolute;
        bottom: 30px;
        right: 30px;
    }
`

export const StyledIconContainer = styled.div`
    width: 16px;

    content: '';
    position: absolute;
    transform: TranslateX(-100%);

    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    margin-top: ${pxToRem(10)};
    margin-left: ${pxToRem(-15)};
    z-index: 1;
`

export const StyledReferralSearchResultsWrap = styled.article`
    position: relative;
`

export const StyledReferralSearchResultsContainer = styled.ul`
    position: absolute;
    margin: 0;
    padding: 0;
    list-style: none;

    width: ${pxToRem(520)};
    z-index: 1;

    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
`

export const StyledReferralSearchResult = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    color: #293854;

    padding: 19px 18px;

    :hover {
        background: #f5f5f5;
    }

    span {
        color: #73839c;
        font-style: italic;
    }
`
