import { FC } from 'react'
import { ISectionElement, ITwoColumnPanel } from '../index.models'
import { StyledAWVDigitalInputWrap } from '../index.style'

const processElement = (element: any, parentName: string) => {
    if (element && parentName) {
        return element['name'] ? { ...element, name: `${parentName}.${element['name']}` } : element
    } else {
        return element
    }
}

interface ITwoColumnPanelProps {
    name: string
    parentName: string
    elements: ISectionElement[]
    renderSectionElement: (element: ISectionElement, key: string, parentName?: string) => JSX.Element
}

const TwoColumnPanel: FC<ITwoColumnPanelProps> = ({ name, elements, renderSectionElement, parentName }) => (
    <>
        {Array.from({ length: elements.length / 2 + (elements.length % 2) }, (_, index) => [
            elements[index * 2],
            elements[index * 2 + 1]
        ]).map((x, idx) => {
            const leftElement = processElement(x[0], parentName)
            const rightElement = processElement(x[1], parentName)
            const rootKey = parentName ? `${parentName}-${name}-${idx}` : `${name}-${idx}`
            return (
                <StyledAWVDigitalInputWrap key={`${rootKey}-${idx}`}>
                    {renderSectionElement(leftElement, `${rootKey}-A`, parentName)}
                    {rightElement && renderSectionElement(rightElement, `${rootKey}-B`, parentName)}
                </StyledAWVDigitalInputWrap>
            )
        })}
    </>
)

export const renderTwoColumnPanel = (
    element: ISectionElement,
    key: string,
    renderSectionElement: (element: ISectionElement, key: string) => JSX.Element,
    parentName: string
) => {
    const twoColumnPanel = element as ITwoColumnPanel
    return (
        <TwoColumnPanel
            key={key}
            name={key}
            parentName={parentName}
            elements={twoColumnPanel.elements}
            renderSectionElement={renderSectionElement}
        />
    )
}
