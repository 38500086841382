import { FC } from 'react'
import { RadioSelection } from '../RadioSelection'
import { INullableRadioBoolean, IRadioBoolean, ISectionElement } from '../index.models'

interface IRadioBooleanProps {
    name: string
    label: string
    subLabel: string
    nullable: boolean
    trueLabel: string
    falseLabel: string
    nullLabel?: string
}

const RadioBoolean: FC<IRadioBooleanProps> = ({
    name,
    label,
    subLabel,
    nullable,
    trueLabel,
    falseLabel,
    nullLabel
}) => {
    const options = [
        { label: trueLabel ?? 'Yes', value: true },
        { label: falseLabel ?? 'No', value: false }
    ]
    if (nullable) {
        options.push({ label: nullLabel ?? 'N/A', value: null })
    }

    return <RadioSelection name={name} label={label} subLabel={subLabel} options={options} isVertical={false} />
}

export const renderRadioBoolean = (element: ISectionElement, key: string) => {
    const radioBoolean = element as IRadioBoolean
    return <RadioBoolean key={key} nullable={false} {...radioBoolean} />
}

export const renderNullableRadioBoolean = (element: ISectionElement, key: string) => {
    const nullableRadioBoolean = element as INullableRadioBoolean
    return <RadioBoolean key={key} nullable={true} {...nullableRadioBoolean} />
}
