import { FC } from 'react'
import { StyledRadioFormContainer, StyledRadioFormWrapper } from './index.style'
import { Controller, useFormContext } from 'react-hook-form'
import { IRadioSelection, ISectionElement, IVerticalRadioSelection } from '../index.models'
import { Label } from '../Label'

interface IOption {
    label: string
    value: any
}

interface IRadioSelectionProps {
    name: string
    label: string
    subLabel: string
    options: IOption[]
    isVertical: boolean
}

export const RadioSelection: FC<IRadioSelectionProps> = ({ name, label, subLabel, options, isVertical }) => {
    const { control, setValue, register } = useFormContext()

    return (
        <StyledRadioFormWrapper>
            <Label htmlFor={name} label={label} subLabel={subLabel} wrapInParagraph={true} />
            <StyledRadioFormContainer isVertical={isVertical}>
                {options.map((option: IOption, idx: number) => (
                    <label key={`${name}-${idx}`}>
                        <Controller
                            name={name}
                            control={control}
                            render={({ field }) => (
                                <input
                                    id={name}
                                    type="radio"
                                    {...field}
                                    value={option.value}
                                    checked={field.value === option.value}
                                    onChange={() => setValue(name, option.value)}
                                />
                            )}
                        />
                        {option.label}
                    </label>
                ))}
            </StyledRadioFormContainer>
        </StyledRadioFormWrapper>
    )
}

export const renderRadioSelection = (element: ISectionElement, key: string) => {
    const radioSelection = element as IRadioSelection
    return <RadioSelection key={key} {...radioSelection} isVertical={false} />
}

export const renderVerticalRadioSelection = (element: ISectionElement, key: string) => {
    const verticalRadioSelection = element as IVerticalRadioSelection
    return <RadioSelection key={key} {...verticalRadioSelection} isVertical={true} />
}
