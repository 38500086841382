import { FC, useEffect, useState } from 'react'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import {
    IPreventiveScreeningListItem,
    IPreventiveScreeningsList,
    IPreventiveScreeningsListItemGroup,
    IPreventiveScreeningsListLeaf,
    ISectionElement
} from '../index.models'
import {
    StyledPreventiveScreeningsListContainer,
    StyledTableFormBody,
    StyledTableFormBodyItem,
    StyledTableFormHeader,
    StyledTableFormInputContainer,
    StyledTableFormItem
} from './index.style'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { performFieldSynchronizationStep } from '../utils'

interface IPreventiveScreeningsListProps {
    name: string
    recommendedScreeningColumnLabel: string
    resultColumnLabel: string
    dateLastCompletedColumnLabel: string
    dateNextScheduledColumnLabel: string
    recommendedScreeningColumnWidth: number
    resultColumnWidth: number
    dateLastCompletedColumnWidth: number
    dateNextScheduledColumnWidth: number
    items: IPreventiveScreeningListItem[]
}

interface ILeaf extends IPreventiveScreeningsListLeaf {
    depth: number
}

const getGroupsAndLeafs = (
    items: IPreventiveScreeningListItem[],
    depth: number = 0
): { groups: any; leafs: ILeaf[] } => {
    const getGroupsAndLeafs = (
        groups: any,
        leafs: ILeaf[],
        items: IPreventiveScreeningListItem[],
        depth: number
    ): void => {
        items.forEach((item) => {
            if (item.$type === 'leaf') {
                leafs.push({ ...(item as IPreventiveScreeningsListLeaf), depth })
            } else if (item.$type === 'group') {
                const group = item as IPreventiveScreeningsListItemGroup
                groups[leafs.length] = groups[leafs.length] ? [...groups[leafs.length], group.label] : [group.label]
                getGroupsAndLeafs(groups, leafs, group.items, depth + 1)
            }
        })
    }

    const groups: any = {}
    const leafs: ILeaf[] = []
    getGroupsAndLeafs(groups, leafs, items, depth)
    return { groups, leafs }
}

const PreventiveScreeningsList: FC<IPreventiveScreeningsListProps> = ({
    name,
    recommendedScreeningColumnLabel,
    resultColumnLabel,
    dateLastCompletedColumnLabel,
    dateNextScheduledColumnLabel,
    recommendedScreeningColumnWidth,
    resultColumnWidth,
    dateLastCompletedColumnWidth,
    dateNextScheduledColumnWidth,
    items
}) => {
    const [groupsAndLeafs] = useState(getGroupsAndLeafs(items))
    const [leafsByName, setLeafsByName] = useState<any>({})

    const { register, control } = useFormContext()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    useEffect(() => {
        performFieldSynchronizationStep(groupsAndLeafs.leafs, fields, append, remove, move)
    }, [groupsAndLeafs, fields, append, remove, move])

    useEffect(() => {
        const leafsByName = groupsAndLeafs.leafs.reduce((acc: any, leaf: ILeaf) => {
            acc[leaf.name] = leaf
            return acc
        }, {})
        setLeafsByName(leafsByName)
    }, [groupsAndLeafs])

    const renderIndentation = (depth: number): JSX.Element => {
        let indentation = ''
        for (let i = 0; i < depth * 8; i++) {
            indentation += '&nbsp;'
        }
        return <span dangerouslySetInnerHTML={{ __html: indentation }} />
    }

    return (
        <StyledPreventiveScreeningsListContainer>
            <StyledTableFormHeader>
                <StyledTableFormItem style={{ width: `${recommendedScreeningColumnWidth}px` }}>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{recommendedScreeningColumnLabel}</Typography>
                </StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${resultColumnWidth}px` }}>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{resultColumnLabel}</Typography>
                </StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${dateLastCompletedColumnWidth}px` }}>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{dateLastCompletedColumnLabel}</Typography>
                </StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${dateNextScheduledColumnWidth}px` }}>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{dateNextScheduledColumnLabel}</Typography>
                </StyledTableFormItem>
            </StyledTableFormHeader>
            {fields.map(
                (field: any, idx: number) =>
                    leafsByName[field.name] && (
                        <>
                            {groupsAndLeafs.groups[idx] &&
                                groupsAndLeafs.groups[idx].map((label: string, groupIndex: number) => (
                                    <StyledTableFormBody>
                                        <StyledTableFormBodyItem style={{ fontWeight: 600 }}>
                                            {renderIndentation(
                                                leafsByName[field.name].depth -
                                                    groupsAndLeafs.groups[idx].length +
                                                    groupIndex
                                            )}
                                            {label}
                                        </StyledTableFormBodyItem>
                                    </StyledTableFormBody>
                                ))}
                            <StyledTableFormBody key={field.id}>
                                <StyledTableFormBodyItem style={{ width: `${recommendedScreeningColumnWidth}px` }}>
                                    {renderIndentation(leafsByName[field.name].depth)}
                                    {leafsByName[field.name].label}
                                </StyledTableFormBodyItem>
                                <StyledTableFormInputContainer style={{ width: `${resultColumnWidth}px` }}>
                                    <input {...register(`${name}.${idx}.result`)} />
                                </StyledTableFormInputContainer>
                                <StyledTableFormInputContainer style={{ width: `${dateLastCompletedColumnWidth}px` }}>
                                    <input type="date" {...register(`${name}.${idx}.dateLastCompleted`)} />
                                </StyledTableFormInputContainer>
                                <StyledTableFormInputContainer style={{ width: `${dateNextScheduledColumnWidth}px` }}>
                                    <input type="date" {...register(`${name}.${idx}.dateNextScheduled`)} />
                                </StyledTableFormInputContainer>
                            </StyledTableFormBody>
                        </>
                    )
            )}
        </StyledPreventiveScreeningsListContainer>
    )
}

export const renderPreventiveScreeningsList = (element: ISectionElement, key: string) => {
    const preventiveScreeningsList = element as IPreventiveScreeningsList
    return <PreventiveScreeningsList key={key} {...preventiveScreeningsList} />
}
