import {
    StyledTableQuestionnaireBody,
    StyledTableQuestionnaireBodyItem,
    StyledTableQuestionnaireBodyItemTotals,
    StyledTableQuestionnaireContainer,
    StyledTableQuestionnaireHeader,
    StyledTableQuestionnaireHeaderItem
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import {
    IScoredMultipleChoiceList,
    IScoredMultipleChoiceListItem,
    IScoredMultipleChoiceListOption,
    IScoredMultipleChoiceListOutcome,
    ISectionElement
} from '../index.models'
import { FC, useEffect } from 'react'
import { performFieldSynchronizationStep } from '../utils'

interface IScoredMultipleChoiceListProps {
    name: string
    titleColumnLabel: string
    titleColumnWidth: number
    titleColumnMargin: number
    totalScoreLabel: string
    options: IScoredMultipleChoiceListOption[]
    coreItems: IScoredMultipleChoiceListItem[]
    items: IScoredMultipleChoiceListItem[]
    outcomes: IScoredMultipleChoiceListOutcome[]
}

const ScoredMultipleChoiceList: FC<IScoredMultipleChoiceListProps> = ({
    name,
    titleColumnLabel,
    titleColumnWidth,
    titleColumnMargin,
    totalScoreLabel,
    options,
    coreItems,
    items,
    outcomes
}) => {
    const { control, watch, setValue } = useFormContext()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    const isCoreItem = (itemName: string) => coreItems.some((item) => item.name === itemName)

    const hideNonCoreItems =
        watch(name)
            ?.filter((x: any) => isCoreItem(x.name))
            .some((x: any) => x.value !== 0) === false

    const totalScore = watch(name)?.some((x: any) => x.value !== null && x.value !== undefined)
        ? watch(name).reduce((acc: number, item: any) => acc + (item.value ?? 0), 0)
        : null

    const outcome =
        totalScore !== null && totalScore !== undefined ? outcomes.find((x) => x.maxScore >= totalScore)?.outcome : null

    useEffect(() => {
        performFieldSynchronizationStep(
            hideNonCoreItems ? coreItems : [...coreItems, ...items],
            fields,
            append,
            remove,
            move
        )
    }, [coreItems, items, watch, fields, append, remove, move, hideNonCoreItems])

    const itemsByName = [...coreItems, ...items].reduce((acc: any, item: any) => {
        acc[item.name] = item
        return acc
    }, {})

    return (
        <StyledTableQuestionnaireContainer>
            <StyledTableQuestionnaireHeader>
                <StyledTableQuestionnaireHeaderItem
                    key={`header-${name}-title`}
                    style={{ width: `${titleColumnWidth}px`, marginRight: `${titleColumnMargin}px` }}
                >
                    <Typography type={TYPOGRAPHY_TYPES.p}>{titleColumnLabel}</Typography>
                </StyledTableQuestionnaireHeaderItem>
                {options.map((option: IScoredMultipleChoiceListOption, idx: number) => (
                    <StyledTableQuestionnaireHeaderItem
                        key={`header-${name}-${idx}`}
                        style={{ width: `${option.columnWidth}px`, marginRight: `${option.columnMargin}px` }}
                    >
                        <Typography type={TYPOGRAPHY_TYPES.p}>{option.label}</Typography>
                    </StyledTableQuestionnaireHeaderItem>
                ))}
            </StyledTableQuestionnaireHeader>
            {fields.map((field: any, index: number) => (
                <StyledTableQuestionnaireBody key={field.id}>
                    <StyledTableQuestionnaireBodyItem
                        style={{ width: `${titleColumnWidth}px`, marginRight: `${titleColumnMargin}px` }}
                    >
                        <Typography type={TYPOGRAPHY_TYPES.p}>{`${index + 1}. ${
                            itemsByName[field.name].label
                        }`}</Typography>
                    </StyledTableQuestionnaireBodyItem>
                    {options.map((option: IScoredMultipleChoiceListOption, optionIndex: number) => (
                        <StyledTableQuestionnaireBodyItem
                            key={`option-${field.id}-${optionIndex}`}
                            style={{
                                width: `${option.columnWidth}px`,
                                marginRight: `${option.columnMargin}px`
                            }}
                        >
                            <Controller
                                name={`${name}.${index}.value`}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        id={`${name}.${index}.value`}
                                        {...field}
                                        type="radio"
                                        value={option.value}
                                        checked={field.value === option.value}
                                        onChange={() => setValue(`${name}.${index}.value`, option.value)}
                                    />
                                )}
                            />
                        </StyledTableQuestionnaireBodyItem>
                    ))}
                </StyledTableQuestionnaireBody>
            ))}
            <StyledTableQuestionnaireBody>
                <StyledTableQuestionnaireBodyItemTotals>
                    <Typography type={TYPOGRAPHY_TYPES.p}>{totalScoreLabel}</Typography>
                </StyledTableQuestionnaireBodyItemTotals>
                <StyledTableQuestionnaireBodyItemTotals>
                    <input readOnly={true} disabled={true} value={totalScore ?? ''} />
                </StyledTableQuestionnaireBodyItemTotals>
                <StyledTableQuestionnaireBodyItemTotals>
                    <input readOnly={true} disabled={true} value={outcome ?? ''} />
                </StyledTableQuestionnaireBodyItemTotals>
            </StyledTableQuestionnaireBody>
        </StyledTableQuestionnaireContainer>
    )
}

export const renderScoredMultipleChoiceList = (element: ISectionElement, key: string) => {
    const scoredMultipleChoiceList = element as IScoredMultipleChoiceList
    return <ScoredMultipleChoiceList key={key} {...scoredMultipleChoiceList} />
}
