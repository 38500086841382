import { FC, useEffect, useState } from 'react'
import { StyledAWVDigitalInput } from '../index.style'
import { StyledAwvHeightContainer, StyledAwvHeightInput, StyledAwvHeightUnit } from './index.style'
import { useFormContext } from 'react-hook-form'
import { IHeightField, ISectionElement } from '../index.models'
import { Label } from '../Label'

const minFeet = 1
const maxFeet = 9

const minInches = 0
const maxInches = 11

const inchesPerFoot = 12

interface IHeightFieldProps {
    name: string
    label: string
    subLabel: string
    feetLabel: string
    feetPlaceholder: string
    inchesLabel: string
    inchesPlaceholder: string
}

const HeightField: FC<IHeightFieldProps> = ({
    name,
    label,
    subLabel,
    feetLabel,
    feetPlaceholder,
    inchesLabel,
    inchesPlaceholder
}) => {
    const {
        watch,
        setValue,
        formState: { errors }
    } = useFormContext()

    const height = watch(name)

    const [internalHeight, setInternalHeight] = useState(
        height ? { feet: Math.floor(height / inchesPerFoot), inches: height % inchesPerFoot } : null
    )

    useEffect(() => {
        if (height && !internalHeight) {
            setInternalHeight({ feet: Math.floor(height / inchesPerFoot), inches: height % inchesPerFoot })
        } else if (internalHeight) {
            const heightInInches = internalHeight.feet * inchesPerFoot + internalHeight.inches
            if (heightInInches !== height) {
                setValue(name, heightInInches)
            }
        }
    }, [height, internalHeight])

    return (
        <StyledAWVDigitalInput style={{ marginBottom: '-15px' }}>
            <Label label={label} subLabel={subLabel} />
            <StyledAwvHeightContainer>
                <div style={{ paddingRight: '20px' }}>
                    <StyledAwvHeightInput
                        name={`${name}-feet`}
                        type="number"
                        min={minFeet}
                        max={maxFeet}
                        value={internalHeight?.feet ?? ''}
                        onChange={(e) => {
                            if (!isNaN(e.target.valueAsNumber)) {
                                setInternalHeight({
                                    feet: Math.floor(e.target.valueAsNumber),
                                    inches: internalHeight?.inches ?? 0
                                })
                            } else {
                                setInternalHeight(null)
                                setValue(name, undefined)
                            }
                        }}
                        aria-label={`${label} (feet)`}
                        placeholder={feetPlaceholder ?? feetLabel}
                    />
                    <StyledAwvHeightUnit>{feetLabel}</StyledAwvHeightUnit>
                </div>
                <div>
                    <StyledAwvHeightInput
                        name={`${name}-inches`}
                        type="number"
                        min={minInches}
                        max={maxInches}
                        value={internalHeight?.inches ?? ''}
                        onChange={(e) => {
                            if (!isNaN(e.target.valueAsNumber)) {
                                setInternalHeight({
                                    feet: internalHeight?.feet ?? 0,
                                    inches: Math.floor(e.target.valueAsNumber)
                                })
                            } else {
                                setInternalHeight(null)
                                setValue(name, undefined)
                            }
                        }}
                        aria-label={`${label} (inches)`}
                        placeholder={inchesPlaceholder ?? inchesLabel}
                    />
                    <StyledAwvHeightUnit>{inchesLabel}</StyledAwvHeightUnit>
                </div>
            </StyledAwvHeightContainer>
            {errors?.[name] && <small>{errors?.[name].message}</small>}
        </StyledAWVDigitalInput>
    )
}

export const renderHeightField = (element: ISectionElement, key: string) => {
    const heightField = element as IHeightField
    return <HeightField key={key} {...heightField} />
}
