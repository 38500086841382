import { QualityFeedbackStatus } from './../../../users/create/index.models'
import { ConditionFeedbackStatus } from 'pages/hcp/users/create/index.models'

export const considerationFeedbackOptionsMap: Map<ConditionFeedbackStatus, string> = new Map([
    [ConditionFeedbackStatus.ConditionPresent, 'Condition Present'],
    [ConditionFeedbackStatus.ConditionNotPresent, 'Condition Not Present'],
    [ConditionFeedbackStatus.NeedsFurtherEvaluation, 'Needs Further Evaluation']
])

export const qualityFeedbackOptionsMap: Map<QualityFeedbackStatus, string> = new Map([
    [QualityFeedbackStatus.CareActionTaken, 'Care Action Taken'],
    [QualityFeedbackStatus.UpToDate, 'Up To Date'],
    [QualityFeedbackStatus.PatientRefused, 'Patient Refused'],
    [QualityFeedbackStatus.NeedsFurtherEvaluation, 'Needs Further Evaluation']
])

export interface IProviderConsiderationFeedbackResponse {
    Id: string
    Status: ConditionFeedbackStatus
}

export interface IProviderQualityFeedbackResponse {
    Id: string
    Status: QualityFeedbackStatus
}

export interface IProviderReviewFeedbackRequest {
    PointOfCareId: string
    ReviewValue: string
}

export interface IProviderReviewFeedbackResponse {
    PointOfCareId: string
    ValueSaved: ConditionFeedbackStatus
    Success: boolean
}

export interface IGapUploadArgs {
    file: File
    pointOfCareId: string
    gapInCareId: string
}

export interface IReviewDocument {
    reviewDocumentId: string
    documentRecaptureId: string
    documentId: string
    dateOfSubmission: string | null
    documentType: string
    documentName: string
}

export interface IUploadRecaptureDocumentResponse {
    documentInfo: IReviewDocument
    recaptureId: string
}

export interface IUploadRecaptureFileRequest {
    file: File
    pointOfCareId: string
    recaptureId: string
}

export interface ISupportDocumentUploadArgs {
    file: File
    memberId: number
    npi: string
}

export interface IGetMemberAwvSummariesRequest {
    memberId: number | string
}

export interface IGetMemberNpiAwvSummariesRequest {
    memberId: number | string
    npi: string
}

export interface IGetReviewableAwvFormsRequest {
    pageNumber: number
    size: number
    sortColumn: string
    filter: string
    isDescending: boolean
}

export interface IReviewableAwvForm {
    awvId: string
    memberId: number
    patient: string
    year: number
    provider: string
    providerNpi: string
    type: string
    submissionDate: string
    status: string
    statusEffective: string
    hasSupportingDocuments: boolean
    hasNewFeedback: boolean
}

export interface IGetReviewableAwvFormsResponse {
    reviewableAwvForms: IReviewableAwvForm[]
    totalNumberOfReviewableAwvForms: number
    filteredNumberOfReviewableAwvForms: number
}

export interface ISetAwvFormResponseStatusRequest {
    memberId: number
    npi: string
    year: number
}
export interface IGetAwvHistoryRequest {
    memberId: number
    npi: string
    year: number
}
