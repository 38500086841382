import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    approvePointsInCare,
    deleteGapForm,
    downloadGapsForm,
    fetchPointsInCare,
    fetchPointsInCareAdmin,
    PointsInCareState,
    resendPointsInCare,
    saveDraftForPointsInCare,
    submitPointsOfCareQuality,
    submitPointsOfCareReview,
    uploadSupportingDocument
} from 'redux/slices/pointsInCare'
import { dispatch } from 'redux/store'
import { MemberProfileState, getMemberNpiAwvSummaries } from 'redux/slices/memberProfile'
import {
    StyledControllersContainer,
    StyledDropzoneContainer,
    StyledPICContainer,
    StyledPICDetailsContainer,
    StyledPICUploadAreaContainer,
    StyledSaveAsDraftButton,
    StyledSubmitButton,
    StyledTabTitle
} from './index.style'
import PICTabController, { EPICTabs } from './PICTabController'
import ReviewTable from './ReviewTable'
import ConsiderationTable from './ConsiderationTable'
import QualityTable from './Quality'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'
import { createGlobalStyle } from 'styled-components'
import DropZone from 'components/DropZone'
import { IProviderConsiderationFeedbackResponse, ISupportDocumentUploadArgs } from './PointsInCare.models'
import useMemberInfoFromUrl from 'hooks/useMemberInfoFromUrl'
import { StyledButton, StyledUploadedDocsListItem } from './Quality/index.style'
import { setFormatedDate } from 'components/UsersTable/index.utils'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { deleteDocIcon } from './Quality/TableBody/icons'
import {
    IDeletePointsInCareDocument,
    IPointsInCareSupportingDoc,
    IUpdateGapFormResponse,
    IUpdateSupportDocumentResponse
} from 'pages/hcp/users/create/index.models'
import { useDropzone } from 'react-dropzone'
import SmallSpinner from 'components/SmallSpinner'
import { isFileOutsideMaxSizeLimit } from 'utilities/uploadUtilities'

interface IPointsInCareProps {}

const PointsInCare: FC<IPointsInCareProps> = ({}) => {
    const currentUser = useCurrentUser()
    const memberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const pointsInCare = useSelector((state: { pointsInCare: PointsInCareState }) => state.pointsInCare)

    const awvData = useSelector((state: { memberProfile: MemberProfileState }) => state.memberProfile.awvSummaries)

    const [file, setFile] = useState(null)
    const [uploadInProgress, setUploadInProgress] = useState(-1)
    const [downloadInProgress, setDownloadInProgress] = useState(-1)
    const [deleteInProgress, setDeleteInProgress] = useState<number>(-1)
    const [isReviewSubmittedVisible, setIsReviewSubmittedVisible] = useState(true)
    const [isQualitySubmittedVisible, setIsQualitySubmittedVisible] = useState(true)

    const [supportingDocs, setSupportingDocs] = useState<IPointsInCareSupportingDoc[]>([])

    useEffect(() => {
        if (pointsInCare?.pointsInCare?.support) {
            setSupportingDocs(pointsInCare?.pointsInCare?.support)
        }
    }, [pointsInCare?.pointsInCare?.support])

    const { getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles) => setFile(acceptedFiles[0]),
        accept: {
            'application/pdf': ['.pdf']
        }
    })

    useEffect(() => {
        handleTabSubmitted()
    }, [pointsInCare?.pointsInCare])

    const uploadFile = async (file: any) => {
        if (!file) return

        const args: ISupportDocumentUploadArgs = {
            file: file[0],
            memberId: memberId,
            npi: npi
        }

        setUploadInProgress(supportingDocs.length)
        dispatch(uploadSupportingDocument(args)).then((res) => {
            setUploadInProgress(-1)
            if (res.meta.requestStatus === 'fulfilled') {
                const response = res.payload as IUpdateSupportDocumentResponse
                const supportingDocsWithAppend = [...supportingDocs, response.documentInfo]
                setSupportingDocs(supportingDocsWithAppend)
            }
        })
    }

    useEffect(() => {
        if (file && !isFileOutsideMaxSizeLimit(file)) {
            uploadFile(file)
        }
    }, [file])

    const [validFile, setValidFile] = useState()

    const [memberId, npi] = useMemberInfoFromUrl()

    const isAdmin = () => {
        return currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep
    }

    useEffect(() => {
        const isAdmin =
            currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep
        if (isAdmin) {
            dispatch(fetchPointsInCareAdmin({ memberId: memberId, npi: npi }))
        } else {
            dispatch(fetchPointsInCare({ memberId: memberId, npi: npi }))
        }

        // also fill the awv form info
        dispatch(getMemberNpiAwvSummaries({ memberId: memberId, npi: memberProfileDetails?.npi }))
    }, [])

    const [tab, setTab] = useState<string>(EPICTabs.REVIEW)

    const handleActiveTab = (activeTab: string) => {
        setTab(activeTab)
        handleTabSubmitted()
    }

    const SaveDraft = (reviews: IProviderConsiderationFeedbackResponse[]) => {
        const request = {
            memberId: pointsInCare.memberId,
            npi: '',
            reviews: reviews.map((selection, index) => {
                return {
                    Id: selection.Id,
                    feedback: selection.Status
                }
            }),
            qualities: {
                awv: { scheduledDateOfService: null as string },
                qualities: pointsInCare.pointsInCare.quality
            }
        }
        dispatch(saveDraftForPointsInCare(request))
    }

    const handleSaveAsDraft = (selections: IProviderConsiderationFeedbackResponse[]) => {
        SaveDraft(selections)
    }

    const handleSubmit = () => {
        if (tab === EPICTabs.REVIEW) {
            dispatch(
                submitPointsOfCareReview({ memberId: pointsInCare?.memberId, npi: memberProfileDetails?.npi })
            ).then(() => dispatch(fetchPointsInCare({ memberId: memberId, npi: npi })))
        } else if (tab === EPICTabs.QUALITY) {
            dispatch(
                submitPointsOfCareQuality({ memberId: pointsInCare?.memberId, npi: memberProfileDetails?.npi })
            ).then(() => dispatch(fetchPointsInCare({ memberId: memberId, npi: npi })))
        }
    }

    const handleResend = () => {
        dispatch(resendPointsInCare({ memberId: pointsInCare?.memberId, npi: '1234567890' }))
    }

    const handleApprove = () => {
        dispatch(approvePointsInCare({ memberId: pointsInCare?.memberId, npi: '1234567890' }))
    }

    const handleSupportDocDownload = (documentId: string, filename: string, docIndex: number) => {
        setDownloadInProgress(docIndex)
        dispatch(
            downloadGapsForm({
                documentId: documentId,
                memberId: memberId,
                npi: npi,
                fileName: filename
            })
        ).then(() => {
            setDownloadInProgress(-1)
        })
    }

    const handleDeleteSupportFile = (supportDocumentId: string, documentId: string, docIdx: number) => {
        setDeleteInProgress(docIdx)
        dispatch(
            deleteGapForm({
                pointOfCareDocumentId: supportDocumentId,
                documentId: documentId
            } as IDeletePointsInCareDocument)
        ).then(() => {
            // delete the gap file here
            setDeleteInProgress(-1)
            const updateSupportingDocs = [...supportingDocs]
            const updateSupportingDocsAfterDelete = updateSupportingDocs.filter(
                (doc) => doc.supportDocumentId !== supportDocumentId
            )
            setSupportingDocs(updateSupportingDocsAfterDelete)
        })
    }

    const handleTabSubmitted = () => {
        const isReviewNotSubmitted =
            pointsInCare?.pointsInCare?.reviews?.length > 0 &&
            pointsInCare?.pointsInCare?.reviews[0]?.recaptureStatus !== 'Submitted'
        setIsReviewSubmittedVisible(isReviewNotSubmitted)

        const isQualityNotSubmitted =
            pointsInCare?.pointsInCare?.quality?.length > 0 &&
            pointsInCare?.pointsInCare?.quality[0]?.gapStatus !== 'Submitted'
        setIsQualitySubmittedVisible(isQualityNotSubmitted)
    }

    const isReadOnly =
        currentUser.primaryRole === ERoles.CallCenterAdmin || currentUser.primaryRole === ERoles.ProviderRep

    return (
        <StyledPICContainer>
            <StyledPICDetailsContainer>
                <StyledTabTitle>
                    <Typography type={TYPOGRAPHY_TYPES.h3}>Points In Care</Typography>
                </StyledTabTitle>
                <Typography type={TYPOGRAPHY_TYPES.h4}>Targeted Conditions</Typography>
                <Typography type={TYPOGRAPHY_TYPES.p}>
                    Fusce posuere, enim eget semper sagittis, urna orci semper turpis, nec congue nisl purus quis metus.
                    Phasellus mollis orci ipsum, sit amet tempus turpis eleifend quis. Fusce mauris felis, dapibus at
                    fringilla id,
                </Typography>
            </StyledPICDetailsContainer>
            <PICTabController selectedTab={handleActiveTab} />
            {tab === EPICTabs.REVIEW ? (
                <ReviewTable
                    pointsInCareReview={pointsInCare.pointsInCare.reviews}
                    saveDraft={handleSaveAsDraft}
                    isSubmitted={!isReviewSubmittedVisible}
                />
            ) : (
                ''
            )}
            {/* {tab === EPICTabs.CONSIDERATION ? (
                <ConsiderationTable pointsInCareConsiderations={pointsInCare.pointsInCare.considerations} />
            ) : (
                ''
            )} */}
            {tab === EPICTabs.QUALITY ? (
                <QualityTable
                    pointsInCareQuality={pointsInCare.pointsInCare.quality}
                    pointsInCareAwv={awvData.awvSummaries}
                    isSubmitted={!isQualitySubmittedVisible}
                />
            ) : (
                ''
            )}
            {!isAdmin() ? (
                <StyledPICUploadAreaContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h3}>Upload Supporting Files </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.p}>
                        The following are acceptable file extensions: .pdf
                    </Typography>
                    <StyledDropzoneContainer>
                        <DropZone selectedFile={setFile} acceptableFile={setValidFile} showSelectedFiles={false} />
                    </StyledDropzoneContainer>
                </StyledPICUploadAreaContainer>
            ) : (
                <Typography type={TYPOGRAPHY_TYPES.h3}>Supporting Files </Typography>
            )}

            {supportingDocs?.map((supportingDoc: IPointsInCareSupportingDoc, docIdx: number) => {
                return (
                    <StyledUploadedDocsListItem key={`doc-${docIdx}`}>
                        {downloadInProgress === docIdx ? (
                            <SmallSpinner />
                        ) : (
                            <StyledButton
                                onClick={() =>
                                    handleSupportDocDownload(
                                        supportingDoc?.documentId,
                                        supportingDoc.documentName,
                                        docIdx
                                    )
                                }
                            >
                                <Typography type={TYPOGRAPHY_TYPES.p}>
                                    {`${supportingDoc?.documentName} ${setFormatedDate(
                                        supportingDoc?.dateOfSubmission
                                    )}`}
                                </Typography>
                            </StyledButton>
                        )}
                        {deleteInProgress === docIdx ? (
                            <SmallSpinner />
                        ) : (
                            !isReadOnly && (
                                <StyledButton
                                    onClick={() =>
                                        handleDeleteSupportFile(
                                            supportingDoc?.supportDocumentId,
                                            supportingDoc.documentId,
                                            docIdx
                                        )
                                    }
                                >
                                    <CustomSvgIcon iconSet={{ icon: deleteDocIcon }} svg></CustomSvgIcon>
                                </StyledButton>
                            )
                        )}
                    </StyledUploadedDocsListItem>
                )
            })}
            {uploadInProgress > -1 ? <SmallSpinner /> : ''}

            {isReadOnly ? (
                <StyledControllersContainer>
                    <StyledSaveAsDraftButton onClick={handleResend}>Resend</StyledSaveAsDraftButton>
                    <StyledSubmitButton onClick={handleApprove}>Approve</StyledSubmitButton>
                </StyledControllersContainer>
            ) : (
                ((tab === EPICTabs.REVIEW && isReviewSubmittedVisible) ||
                    (tab === EPICTabs.QUALITY && isQualitySubmittedVisible)) && (
                    <StyledControllersContainer>
                        <StyledSubmitButton onClick={handleSubmit}>Submit</StyledSubmitButton>
                    </StyledControllersContainer>
                )
            )}
        </StyledPICContainer>
    )
}

export default PointsInCare
