import { useForm, Controller, useFieldArray, FieldValues } from 'react-hook-form'
import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import React, { FC, useEffect, useRef, useState } from 'react'
import { editPen } from '../../details/OfficeAdminUser/icons'
import TextField from 'components/TextField'
import { inputLabels } from 'messages/inputLabels'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    IEditAddress,
    IUser,
    emptyAddress,
    ILocationDetailData,
    ILocation,
    ILocationData,
    emptyLocationError,
    IUserIdTracker,
    IProviderUpdateDetails,
    IProviderCreateDetails,
    INpiLocationsOption,
    IProviderCommonDetails
} from '../index.models'
import { IExternalState } from 'hooks/useExternalState'
import { isExistingAddress } from 'utilities/addressUtilities'
import {
    locationDropDownListStyleParams,
    locationTypeDropDownListStyleParams,
    ProviderError,
    ProviderLocationTypeHorizontalLine,
    ProviderOfficePhoneHorizontalLine,
    StyledAddLocationButton,
    StyledAddLocationButtonWrap,
    StyledEditPCPLocationButton,
    StyledInputContainer,
    StyledInputContainerGroup,
    StyledInputContainerWrap,
    StyledInputSelectLegend,
    StyledLocationTypeInputContainer,
    StyledPCPToolTip,
    StyledUserDetailsFieldSet
} from '../index.style'
import { StyledDeleteLocationButton } from '../../details/PCPNPUser/style'
import AddressModal from '../NewOfficeAdmin/AddressModal'
import MaskedTextField from 'components/MaskedTextField'
import { StyledAddressModalInputContainer } from '../NewOfficeAdmin/AddressModal/index.style'
import { StyledInput } from 'components/MaskedTextField/index.style'
import {
    clearNPIValidations,
    createProvider,
    getLocationsFromNpi,
    getValidAreaCodes,
    updateProvider,
    validateNPI
} from 'redux/slices/user'
import { dispatch, useSelector } from 'redux/store'
import { getUsaStateAbbreviationByName } from '../NewOfficeAdmin/AddressModal/index.models'
import { StyledMemberSelect } from 'pages/hcp/members/information/Personal/index.style'
import useCurrentUser from 'hooks/useCurrentUser'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { ERoles } from 'models/enums/role'
import {
    getIndexFromYupPath,
    validateAreaCode,
    validatePhoneIsCorrectLength,
    validatePhoneIsNotBlank
} from '../../../../../utilities/validationUtilities'
import Select from 'react-select'
import { createDropdownIndicator, createStyles } from 'components/DropDownList/index.style'
import { appPath } from 'utilities/appPath'
import Spinner from 'components/Spinner'

const validateState = (value: string): boolean => {
    return value !== 'State' && value !== '' && value !== undefined && value !== null
}

interface INewPCPNPProps {
    userDetails?: IUser | null
    readonly?: boolean
    commonState?: IExternalState
    formSubmitted: boolean
    creatingANewUser: boolean
    resetHasBeenSubmitted: () => void
}

const NewPCPNP: FC<INewPCPNPProps> = ({
    userDetails,
    readonly: isReadOnly,
    commonState,
    formSubmitted,
    creatingANewUser,
    resetHasBeenSubmitted
}) => {
    // *************  Custom Hooks  *************
    const currentUser = useCurrentUser()
    const appInsights = useAppInsightsContext()
    const location = useLocation()
    const navigate = useNavigate()
    const focusRef = useRef(null)
    // ***************************************

    const providerValidationsSchema = yup.object().shape({
        firstName: yup.string().required('Required'),
        lastName: yup.string().required('Required'),
        email: yup.string().email('Invalid Email').required('Required'),
        phoneNumber: yup
            .string()
            .test('phone required', 'Required', (val: any) => validatePhoneIsNotBlank(val))
            .test('valid phone', 'Not a valid phone number', (val: any) => validatePhoneIsCorrectLength(val))
            .test('valid area code', 'Not a US phone number', (val: any) => validateAreaCode(val, validAreaCodes)),
        npi: yup
            .string()
            .required('Required')
            .test('valid npi length', 'Invalid NPI', (val: any) => val?.length === 10)
            .test('valid npi', 'Invalid NPI', (val: any) => npiInfo?.npiIsValid)
            .test(
                'valid npi unique',
                'NPI already exists',
                (val: any) => !npiInfo?.providerWithNpiAlreadyExists || getUsersNpiFromDetails() === val
            ),
        providerLocations: yup.array().of(
            yup.object().shape({
                locationType: yup
                    .mixed()
                    .required('Required')
                    .test(
                        'validate one primary location',
                        'Multiple Primary Locations',
                        (val: any, context) =>
                            validateUniqueLabels(getIndexFromYupPath(context.path), getAllLocationTypeLabelsFromForm) ||
                            val.value === 2
                    )
                    .test('missing primary location type', 'Primary Location Required', (val: any, context) =>
                        validateAtLeastOnePrimaryLocation(
                            getIndexFromYupPath(context.path),
                            getAllLocationTypeLabelsFromForm
                        )
                    ),
                locationAddress: yup
                    .mixed()
                    .test('valid location', 'Required', (val: any) => {
                        const testing = val != null && val.value != null && val?.value?.location?.address1
                        return testing?.length > 0
                    })
                    .test('valid location uniqueness', 'Duplicate Location', (val: any, context) =>
                        validateUniqueLabels(getIndexFromYupPath(context.path), getAllLocationLabelsFromForm)
                    ),
                officePhoneNumber: yup
                    .string()
                    .test('office phone required', 'Required', (val: any) => validatePhoneIsNotBlank(val))
                    .test('valid phone', 'Not a valid phone number', (val: any) => validatePhoneIsCorrectLength(val))
                    .test('valid area code', 'Not a US phone number', (val: any) =>
                        validateAreaCode(val, validAreaCodes)
                    )
            })
        )
    })

    const {
        register,
        handleSubmit,
        reset,
        trigger,
        watch,
        formState: { errors },
        control,
        setValue,
        setError,
        clearErrors,
        getValues
    } = useForm({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(providerValidationsSchema)
    })

    const { fields, append, prepend, remove, update } = useFieldArray({
        name: 'providerLocations',
        control,
        rules: {
            required: 'Please append at least 1 item'
        }
    })

    // ************** use selector ***************
    const npiInfo = useSelector((state: { user: any }) => state.user.isValidNPI)

    const emailInfo = useSelector((state: { user: any }) => state.user.emailInfo)

    const validAreaCodes: number[] = useSelector((state: { user: any }) => state.user.validAreaCodes.areaCodes)

    const npiLocations = useSelector((state: { user: any }) => state?.user?.npiInfo?.locations)

    // ****************************

    // ************** states ***************
    const [addressModalIsVisible, setAddressModalIsVisible] = useState(false)
    const [editingAddress, setEditingAddress] = useState<IEditAddress>({
        isEditable: false,
        address: emptyAddress,
        id: null,
        isNewAddress: false
    })
    const [npiLocationsArray, setNpiLocationsArray] = useState<ILocationDetailData[]>([])
    const [trackUserOpeningLocationDialog, setTrackUserOpeningLocationDialog] = useState<IUserIdTracker>()
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [selectedEditableLocationId, setSelectedEditableLocationID] = useState<any>(0)
    const [indicesFilled, setIndicesFilled] = useState<boolean>(false)
    const [npiLocationsOptions, setNpiLocationsOptions] = useState<INpiLocationsOption[]>([])
    const locationInitialState: ILocationData[] = [emptyAddress]
    const [formSubmittedState, setFormSubmitted] = useState<boolean>(false)

    const [watchState, setWatchState] = useState<[name: string, type: string]>(null)
    const [waitSpinner, setWaitSpinner] = useState<boolean>(false)

    // **************** Maps and constant data ***************
    const locationTypeMap = new Map<number, string>()
    locationTypeMap.set(1, 'Primary Office')
    locationTypeMap.set(2, 'Secondary Office')

    const locationTypeOptions = [
        { label: 'Primary Office', value: 1 },
        { label: 'Secondary Office', value: 2 }
    ]

    enum ELocationOptionSelect {
        NOT_LISTED = 'notListed',
        CHOOSE_A_LOCATION = 'choose a location'
    }

    const controlsDisabled = npiLocationsArray && npiLocationsArray.length > 0 ? false : true

    const concatenateLocationInfo = (location: ILocationDetailData) => {
        if (location == null || location.address1 == null) {
            return ''
        }

        return `${location.address1}, ${
            location.address2 == null || location.address2.trim() === '' ? '' : location.address2 + ','
        } ${location.city}, ${location.state} ${location.zip}`
    }

    // ***************************************

    // *********** utilities  ***************
    const getUsersNpiFromDetails = (): string => {
        return (
            userDetails &&
            userDetails.taxIdProviderGroupings &&
            userDetails.taxIdProviderGroupings.length > 0 &&
            userDetails.taxIdProviderGroupings[0].providers &&
            userDetails.taxIdProviderGroupings[0].providers.length > 0 &&
            userDetails?.taxIdProviderGroupings[0]?.providers[0]?.npi
        )
    }

    const getErrorObject = (index: number): any => {
        return (errors.providerLocations as unknown as Array<any>)?.[index]
    }

    // ***************************************

    // ******************** effects ***************

    const NPI_LENGTH = 10
    const npiValue = watch('npi')

    // Check if the npi has reached 10 characters and execute
    // the onBlur function to validate the npi and retrieve
    // the npi details and locations

    React.useEffect(() => {
        if (npiValue && npiValue.length === NPI_LENGTH) {
            handleNPIonBlur(npiValue)
        }
    }, [npiValue])

    React.useEffect(() => {
        // reset form submitted state
        if (formSubmittedState) {
            setFormSubmitted(false)
        }
    }, [formSubmittedState])

    React.useEffect(() => {
        if (watchState != null) {
            const name = watchState[0]
            const type = watchState[1]
            setWatchState(null)
            if (type == 'change' && name.startsWith('providerLocations') && name.endsWith('locationAddress')) {
                const index = parseInt(name.split('.')[1])
                handleOfficeLocationSelect(index, getValues(`providerLocations.${index}.locationAddress`).value)
            } else if (type == 'change' && name.startsWith('providerLocations') && name.endsWith('locationType')) {
                const index = parseInt(name.split('.')[1])
                handleOfficeLocationTypeSelectErrors(index, getValues(`providerLocations.${index}.locationType`).value)
            }
        }
    }, [watchState])

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            setWatchState([name, type])
            return () => subscription.unsubscribe()
        })
    }, [watch])

    const TranslateLocationDetailToNpiLocationsOption = (location: ILocationDetailData, index: number): any => {
        return {
            label: concatenateLocationInfo(location),
            value: { location: location, index: index }
        }
    }

    useEffect(() => {
        if (npiLocationsArray.length > 0) {
            const temp = [...npiLocationsArray]
                .map((location, index) => TranslateLocationDetailToNpiLocationsOption(location, index))
                .concat([{ label: 'My Location is not listed', value: { location: null, index: -1 } }])

            setNpiLocationsOptions([...temp])
        }
    }, [npiLocationsArray])

    useEffect(() => {
        const currentNpi = getValues('npi')

        if (currentNpi?.length == 10) {
            trigger('npi')
            if (npiInfo.npiIsValid && !npiInfo.providerWithNpiAlreadyExists) {
                dispatch(getLocationsFromNpi({ npi: currentNpi, userId: userDetails?.id }))
            }
        }
    }, [npiInfo])

    useEffect(() => {
        dispatch(getValidAreaCodes())
        if (userDetails) {
            setValue('firstName', userDetails?.firstName)
            setValue('lastName', userDetails?.lastName ?? '')
            setValue('email', userDetails?.email ?? '')
            setValue('phoneNumber', userDetails?.phoneNumber ?? '')
            if (userDetails?.taxIdProviderGroupings && userDetails?.taxIdProviderGroupings.length > 0)
                setValue('npi', userDetails?.taxIdProviderGroupings[0]?.providers[0]?.npi ?? '')
            else setValue('npi', '')
            remove()
            let index = 0
            if (userDetails?.taxIdProviderGroupings && userDetails?.taxIdProviderGroupings.length > 0)
                userDetails.taxIdProviderGroupings[0].providers[0]?.locations.forEach((loc) => {
                    append(translateToClientLocation(loc))
                    setValue(`providerLocations[${index}].locationType`, {
                        label: locationTypeMap.get(loc.locationType),
                        value: loc.locationType
                    })
                    setValue(`providerLocations[${index}].officePhoneNumber`, loc.officePhoneNumber)
                    index++
                })
        } else {
            // if we are creating a new user, we need to get the common state values
            const firstNameValue = commonState?.getValue('firstName', '').get()
            setValue('firstName', firstNameValue)
            setValue('lastName', commonState?.getValue('lastName', '').get())
            setValue('email', commonState?.getValue('email', '').get())
            setValue('phoneNumber', commonState?.getValue('phoneNumber', '').get())
            const locationInitialStateWithLabelledLocationType = {
                ...locationInitialState[0],
                locationType: {
                    label: locationTypeMap.get(locationInitialState[0].locationType),
                    value: locationInitialState[0].locationType
                }
            }
            append(locationInitialStateWithLabelledLocationType)

            if (firstNameValue?.length == 0) {
                focusRef.current.focus()
            }
        }
    }, [userDetails])

    useEffect(() => {
        if (location?.pathname.includes('edit')) {
            setIsDisabled(true)
        }

        if (userDetails?.email) {
            setIsDisabled(true)
        }

        if (userDetails && userDetails?.taxIdProviderGroupings && userDetails?.taxIdProviderGroupings.length > 0) {
            const existingLocations = userDetails?.taxIdProviderGroupings[0]?.providers[0]?.locations?.map((loc) =>
                translateToClientLocation(loc)
            )

            const existingNpi = userDetails?.taxIdProviderGroupings[0]?.providers[0]?.npi
            if (existingNpi) {
                handleNPI(existingNpi)
            }
        }
    }, [location, userDetails])

    useEffect(() => {
        dispatch(clearNPIValidations()) // unmount
        if (currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep) {
            if (!validAreaCodes || validAreaCodes.length === 0) {
                dispatch(getValidAreaCodes())
            }
        }
    }, [currentUser])

    useEffect(() => {
        if (npiLocations?.length) {
            setNpiLocationsArray(npiLocations)
        }
    }, [npiLocations])

    useEffect(() => {
        if (npiLocationsArray?.length) {
            const tempOptions = [...npiLocationsArray]
                .map((location, index) => ({
                    label: concatenateLocationInfo(location),
                    value: { location: location, index: index }
                }))
                .concat([{ label: 'My Location is not listed', value: { location: null, index: -1 } }])

            setNpiLocationsOptions([...tempOptions])
        }
    }, [npiLocationsArray])

    const setCurrentLocation = (index: number, location: ILocationData) => {
        const selectedLocationIndex = npiLocationsOptions.findIndex((locationChoice) =>
            testLocationMatch(location, locationChoice.value.location)
        )
        if (selectedLocationIndex >= 0) {
            const selectedLocationAddress = { ...npiLocationsOptions[selectedLocationIndex] }
            setValue(`providerLocations.${index}.locationAddress`, {
                label: selectedLocationAddress.label,
                value: selectedLocationAddress.value
            })
            setValue(`providerLocations.${index}.selectedLocationIndex`, selectedLocationIndex)
        }
    }

    useEffect(() => {
        const providerLocations = getValues('providerLocations')
        //   const temp = fillSelectedIndices(providerLocations)

        providerLocations.forEach((location: any, index: number) => {
            if (location != null && location.selectedLocationIndex != -1) {
                setCurrentLocation(index, location)
            }
        })

        // force a re-render
        setIndicesFilled(!indicesFilled)
    }, [npiLocationsOptions])

    // ***************************************

    // ***************** Location Finder functions ***************
    function testLocationMatch(testLocation: ILocationData, sourceLocation: ILocationDetailData) {
        const testResult =
            testLocation?.address === sourceLocation?.address1 &&
            testLocation?.city === sourceLocation?.city &&
            testLocation?.suite === sourceLocation?.address2 &&
            testLocation?.state === sourceLocation?.state &&
            testLocation?.zipCode === sourceLocation?.zip
        return testResult
    }

    function findLocationIndex(possibleLocations: ILocationDetailData[], testLocation: ILocationData) {
        if (possibleLocations == null) return -1

        return possibleLocations.findIndex((location) => testLocationMatch(testLocation, location))
    }

    const fillSelectedIndices = (existingLocations: ILocationData[]) => {
        const tempExistingLocations = [...existingLocations]
        tempExistingLocations.forEach((existingLocation) => {
            const locationIndex = findLocationIndex(npiLocationsArray, existingLocation)
            existingLocation.selectedLocationIndex = locationIndex
        })

        return tempExistingLocations
    }

    // ***************************************

    // ******************** translate client/server object ***************

    const getLocationFromForm = (idx: number): ILocationData => {
        const location = getValues('providerLocations')[idx]
        return location
    }

    const getAllLocationsFromForm = (): ILocationData[] => {
        const locations = getValues('providerLocations')
        return locations.map((location: ILocationData) => location)
    }

    const getAllLocationLabelsFromForm = (): ILocationData[] => {
        const providerLocations = getValues('providerLocations')
        const locationLabels = providerLocations.map((locBundle: any) => locBundle?.locationAddress?.label)
        return locationLabels
    }

    const getAllLocationTypeLabelsFromForm = (): string[] => {
        const providerLocations = getValues('providerLocations')
        const locationLabels = providerLocations.map((locBundle: any) => locBundle?.locationType?.label)
        return locationLabels
    }

    const translateToClientLocation = (serverLocationData: ILocation): ILocationData => {
        const translated: ILocationData = {
            locationId: serverLocationData.locationId,
            address: serverLocationData.address,
            city: serverLocationData.city,
            state: serverLocationData.state,
            zipCode: serverLocationData.zipCode,
            officePhoneNumber: serverLocationData.officePhoneNumber,
            ezCapLocationId: serverLocationData.ezCapLocationId,
            suite: serverLocationData.suite,
            locationType: serverLocationData.locationType,
            error: false,
            errorMessage: '',
            selectedLocationIndex: 0,
            locationErrors: { ...emptyLocationError }
        }

        return translated
    }

    const translateLocationToServer = (location: ILocationDetailData): ILocationData => {
        const translated: ILocationData = {
            locationId: location.locationId,
            address: location.address1,
            suite: location.address2,
            city: location.city,
            state: location.state,
            zipCode: location.zip,
            ezCapLocationId: location.ezCapLocationId,
            officePhoneNumber: location.officePhoneNumber,
            locationType: location.isPrimary ? 1 : 2,
            error: false,
            errorMessage: '',
            selectedLocationIndex: 0,
            locationErrors: { ...emptyLocationError }
        }

        return translated
    }

    // ***************************************

    const handleCloseModal = () => {
        setAddressModalIsVisible(false)
        setEditingAddress({ isEditable: false, address: emptyAddress, id: null, isNewAddress: false })
        // setSelectedEditableLocationID(null)
    }

    // ************* input handlers ***************
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setValue(name, value)
    }

    const handleNPI = (npi: any) => {
        dispatch(getLocationsFromNpi({ npi: npi, userId: userDetails?.id }))
    }

    // ****************************************

    // ************ Custom Validation ************

    const handleOfficePhoneNumberBlur = (value: any, idx: number) => {
        trigger(`providerLocations[${idx}].officePhoneNumber`)
    }

    const handleNPIonBlur = (npi: string) => {
        if (!isReadOnly) dispatch(validateNPI(npi))
        trigger('npi')
    }

    // *************************************

    const editSelectedLocation = (idx: number | null) => {
        const officeLocation = idx == null ? null : getLocationFromForm(idx)?.locationAddress?.value?.location
        if (idx || idx === 0) {
            setEditingAddress({
                isEditable: true,
                address: {
                    ...officeLocation,
                    address1: officeLocation?.address1,
                    address2: officeLocation?.address2,
                    zip: officeLocation?.zip
                },
                id: idx,
                isNewAddress: false
            })

            setAddressModalIsVisible(true)
        }
    }

    const populateLocationsForServer = (locations: any[], locationResults: ILocation[]) => {
        locations.forEach((loc1, idx) => {
            const loc = loc1.locationAddress.value.location as ILocationDetailData
            locationResults.push({
                address: loc.address1,
                suite: loc.address2,
                city: loc.city,
                state: loc.state,
                zipCode: loc.zip,
                locationId: loc.locationId,
                ezCapLocationId: loc.ezCapLocationId,
                officePhoneNumber: loc1.officePhoneNumber,
                locationType: loc1.locationType.value
            })
        })

        return locations
    }

    const populateCommonUserDetail = (data: any): IProviderCommonDetails => {
        const commonUser = {} as IProviderCommonDetails
        commonUser.userId = userDetails?.id
        commonUser.firstName = data.firstName
        commonUser.lastName = data.lastName
        commonUser.email = data.email
        commonUser.role = ERoles.Provider as number
        commonUser.phoneNumber = data.phoneNumber
        commonUser.npi = data.npi
        commonUser.locations = []
        return commonUser
    }

    const populateUpdateUserDetail = (data: any) => {
        const theUser: IProviderCommonDetails = populateCommonUserDetail(data)
        const userToUpdate = theUser as IProviderUpdateDetails
        userToUpdate.updatedBy = currentUser.sid

        populateLocationsForServer(data.providerLocations, userToUpdate.locations)

        return userToUpdate
    }

    const populateCreateUserDetail = (data: any) => {
        const theUser: IProviderCommonDetails = populateCommonUserDetail(data)
        const userToCreate = theUser as IProviderCreateDetails
        userToCreate.createdBy = currentUser.sid

        populateLocationsForServer(data.providerLocations, userToCreate.locations)

        return userToCreate
    }

    const handleOfficeLocationTypeSelectErrors = (index: number, locationType: any) => {
        // primary labels should be unique
        clearAllLocationFieldErrors('locationType')
        if (!validateUniqueLabels(index, getAllLocationTypeLabelsFromForm) && locationType === 1) {
            setError(`providerLocations.${index}.locationType`, {
                type: 'uniqueValues',
                message: 'Multiple Primary Locations'
            })
        } else if (!validateAtLeastOnePrimaryLocation(index, getAllLocationTypeLabelsFromForm)) {
            setError(`providerLocations.${index}.locationType`, {
                type: 'noPrimaryLocation',
                message: 'Primary Location Required'
            })
        }
    }

    // clear all errors for all the providerLocations.locationAddress fields
    const clearAllLocationFieldErrors = (fieldName: string) => {
        const locationCount = getValues('providerLocations').length
        for (let i = 0; i < locationCount; i++) {
            clearErrors(`providerLocations.${i}.${fieldName}`)
        }
    }

    function handleDuplicateAddress(index: number) {
        clearAllLocationFieldErrors('locationAddress')
        if (!validateUniqueLabels(index, getAllLocationLabelsFromForm)) {
            setError(`providerLocations.${index}.locationAddress`, {
                type: 'uniqueValues',
                message: 'Location should be unique'
            })
        }
    }

    const handleOfficeLocationSelect = (index: number, location: any) => {
        if (!location) {
            //   setLocationInForm(index, emptyAddress)
            return
        }

        const locationObject = location

        if (locationObject?.location === null) {
            setAddressModalIsVisible(true)
            setEditingAddress({
                isEditable: true,
                address: {},
                id: index,
                isNewAddress: true
            })
            return
        }

        if (locationObject.idx2 || locationObject.idx2 === 0) {
            setSelectedEditableLocationID(locationObject.idx2)
        }

        const selectedLocation = {
            suite: locationObject.location.address2,
            address: locationObject.location.address1,
            city: locationObject.location.city,
            state: locationObject.location.state,
            zipCode: locationObject.location.zip,
            ezCapLocationId: locationObject.location.ezCapLocationId,
            locationId: locationObject.location.locationId
        }

        const updatedValues = {
            ...getLocationFromForm(index),
            address: selectedLocation.address,
            suite: selectedLocation.suite,
            city: selectedLocation.city,
            state: selectedLocation.state,
            zipCode: selectedLocation.zipCode,
            ezCapLocationId: selectedLocation.ezCapLocationId,
            locationId: selectedLocation.locationId,
            selectedLocationIndex: npiLocationsOptions.findIndex((x) => x.value === location),
            error: false
        }

        // setLocationInForm(index, updatedValues)

        setCurrentLocation(index, updatedValues)

        getAllLocationsFromForm().forEach((loc) => {
            loc.error = false
        })

        handleDuplicateAddress(index)
    }

    const validateAtLeastOnePrimaryLocation = (index: number, retrieveLabels: () => string[]) => {
        const labels = retrieveLabels()
        if (labels == null) return true
        return labels.some((val) => val.indexOf('Primary') >= 0)
    }

    const validateUniqueLabels = (index: number, retrieveLabels: () => any[]) => {
        const values = retrieveLabels()
        const value = values[index]
        const filtered = values.filter((v) => v === value)
        return filtered.length <= 1
    }

    const handleAddLocation = () => {
        const defaultLocationType = 2
        append({
            ...emptyAddress,
            locationType: { label: locationTypeMap.get(defaultLocationType), value: defaultLocationType },
            locationAddress: { value: { location: null, idx2: null }, label: '' }
        })
    }

    const AreAddressesTheSame = (address1: ILocationDetailData, address2: ILocationDetailData) => {
        if (address1 == null || address1 == undefined) return false
        return (
            address1?.address1 === address2.address1 &&
            address1?.address2 === address2.address2 &&
            address1?.city === address2.city &&
            address1?.state === address2.state &&
            address1?.zip === address2.zip
        )
    }

    const handleAddressFromModal = (address: any, id: any) => {
        let replacedAddress: ILocationDetailData = {
            ...emptyAddress,
            address1: '',
            address2: '',
            isPrimary: false,
            zip: '',
            locationId: null,
            ezCapLocationId: null
        }

        let selectedIndex: number = -1

        replacedAddress = {
            address2: address.suite,
            address1: address.address,
            city: address.city,
            state: getUsaStateAbbreviationByName(address.state),
            zip: address.zipCode,
            ezCapLocationId: address.ezCapLocationId,
            locationId: null,
            isPrimary: id === 0,
            officePhoneNumber: ''
        }

        if (isExistingAddress(npiLocationsArray, replacedAddress) === true) return // not a unique address, don't do anything

        setNpiLocationsArray((prevNpiLocationsArray) => [...prevNpiLocationsArray, replacedAddress])

        selectedIndex = npiLocationsArray.length

        setAddressModalIsVisible(false)

        setEditingAddress({ isEditable: false, address: emptyAddress, id: null, isNewAddress: false })

        update(id, {
            ...translateLocationToServer(replacedAddress),
            locationType: getLocationFromForm(id).locationType,
            officePhoneNumber: getLocationFromForm(id).officePhoneNumber,
            selectedLocationIndex: selectedIndex
        })

        const tempOfficeLocations = getAllLocationsFromForm()
        setTrackUserOpeningLocationDialog({
            ...trackUserOpeningLocationDialog,
            user: userDetails?.id,
            address: concatenateLocationInfo(replacedAddress)
        })

        tempOfficeLocations[id] = {
            ...translateLocationToServer(replacedAddress),
            locationType: getLocationFromForm(id).locationType,
            officePhoneNumber: getLocationFromForm(id).officePhoneNumber,
            selectedLocationIndex: selectedIndex
        }

        // do the translation of the replaced address to an npilocationoption
        const newNpiLocationOption = { label: concatenateLocationInfo(replacedAddress), value: replacedAddress }

        setValue(`providerLocations.${id}.locationAddress`, newNpiLocationOption)

        handleDuplicateAddress(id)
    }

    const onSubmit = (data: any) => {
        if (creatingANewUser) {
            const request = populateCreateUserDetail(data)
            setWaitSpinner(true)
            dispatch(createProvider(request)).then(() => {
                setWaitSpinner(false)
                navigate(appPath('/users'))
            })
        } else {
            const request = populateUpdateUserDetail(data)
            setWaitSpinner(true)
            dispatch(updateProvider(request)).then(() => {
                setWaitSpinner(false)
                navigate(appPath('/users'))
            })
        }
    }

    const onError = (errors: any) => {
        console.log(errors)
    }

    if (formSubmitted) {
        formSubmitted = false
        resetHasBeenSubmitted()
        handleSubmit(onSubmit, onError)()
    }

    return waitSpinner ? (
        <Spinner />
    ) : (
        <article>
            {addressModalIsVisible && (
                <AddressModal
                    onCancel={handleCloseModal}
                    onSave={handleAddressFromModal}
                    locationToEdit={editingAddress}
                />
            )}
            <StyledUserDetailsFieldSet disabled={isReadOnly}>
                <StyledInputContainerWrap>
                    <StyledInputContainer>
                        <Controller
                            name="firstName"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder={inputLabels.first_name}
                                    legend={inputLabels.first_name}
                                    inputType={'text'}
                                    reference={focusRef}
                                    onTextChanged={handleInput}
                                    error={errors?.firstName && true}
                                    externalStateValue={commonState?.getValue('firstName', '')}
                                />
                            )}
                            control={control}
                        />
                        <ProviderError>{errors?.firstName && errors.firstName.message}</ProviderError>
                    </StyledInputContainer>
                    <StyledInputContainer>
                        <Controller
                            name="lastName"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder={inputLabels.last_name}
                                    legend={inputLabels.last_name}
                                    inputType={'text'}
                                    onTextChanged={handleInput}
                                    error={errors?.lastName && true}
                                    externalStateValue={commonState?.getValue('lastName', '')}
                                />
                            )}
                            control={control}
                        />
                        <ProviderError>{errors?.lastName && errors.lastName.message}</ProviderError>
                    </StyledInputContainer>
                </StyledInputContainerWrap>
                <StyledInputContainerWrap>
                    <StyledInputContainer>
                        <Controller
                            name="phoneNumber"
                            render={({ field }) => (
                                <MaskedTextField
                                    placeholder={inputLabels.phone_number}
                                    legend={inputLabels.phone_number}
                                    inputType={'tel'}
                                    {...field}
                                    onTextChanged={handleInput}
                                    onBlur={() => trigger('phoneNumber')}
                                    mask={'(999) 999-9999'}
                                    error={errors?.phoneNumber && true}
                                    externalStateValue={commonState?.getValue('phoneNumber', '')}
                                ></MaskedTextField>
                            )}
                            control={control}
                        />
                        <ProviderError>{errors?.phoneNumber && errors.phoneNumber.message}</ProviderError>
                    </StyledInputContainer>

                    <StyledInputContainer>
                        <Controller
                            name="email"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder={inputLabels.email}
                                    legend={inputLabels.email}
                                    disabled={!creatingANewUser}
                                    inputType={'text'}
                                    handleBlur={() => trigger('email')}
                                    onTextChanged={handleInput}
                                    error={errors?.email && true}
                                    externalStateValue={commonState?.getValue('email', '')}
                                />
                            )}
                            control={control}
                        />
                        <ProviderError>{errors?.email && errors.email.message}</ProviderError>
                    </StyledInputContainer>
                </StyledInputContainerWrap>

                <Typography type={TYPOGRAPHY_TYPES.h2}>Provider Information</Typography>

                <StyledInputContainerGroup>
                    <StyledInputContainer>
                        <Controller
                            name="npi"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    placeholder={inputLabels.NPI}
                                    legend={inputLabels.NPI_PlaceHolder}
                                    inputType={'text'}
                                    disabled={!creatingANewUser}
                                    handleBlur={() => handleNPIonBlur(field.value)}
                                    onTextChanged={handleInput}
                                    error={errors?.npi && true}
                                    externalStateValue={commonState?.getValue('npi', '')}
                                />
                            )}
                            control={control}
                        />
                        <ProviderError>{errors?.npi && errors.npi.message}</ProviderError>
                    </StyledInputContainer>
                </StyledInputContainerGroup>

                <Typography type={TYPOGRAPHY_TYPES.h2}>Office Information</Typography>

                {fields.map((locationField: any, idx: number) => {
                    const isHeaderVisible = idx > 0

                    return (
                        <StyledInputContainerWrap secondary key={locationField.id}>
                            <StyledLocationTypeInputContainer>
                                <StyledInputSelectLegend isHeaderVisible={isHeaderVisible}>
                                    Location Type
                                </StyledInputSelectLegend>
                                <StyledMemberSelect>
                                    <Controller
                                        name={`providerLocations.${idx}.locationType`}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                options={locationTypeOptions}
                                                components={{
                                                    DropdownIndicator: createDropdownIndicator(
                                                        locationTypeDropDownListStyleParams
                                                    )
                                                }}
                                                {...field}
                                                placeholder={'Select Location Type'}
                                                styles={createStyles(
                                                    locationTypeDropDownListStyleParams,
                                                    controlsDisabled || isReadOnly,
                                                    getErrorObject(idx)?.locationType != null
                                                )}
                                                isDisabled={controlsDisabled}
                                                isSearchable={true}
                                                value={locationTypeOptions.find(
                                                    (option) => field.value?.value == option.value
                                                )}
                                            />
                                        )}
                                    />
                                    <ProviderError>
                                        {getErrorObject(idx)?.locationType &&
                                            getErrorObject(idx)?.locationType?.message}
                                    </ProviderError>
                                </StyledMemberSelect>
                            </StyledLocationTypeInputContainer>
                            <ProviderLocationTypeHorizontalLine />
                            <StyledAddressModalInputContainer isPhoneField>
                                <StyledInputSelectLegend isHeaderVisible={isHeaderVisible}>
                                    Office Phone
                                </StyledInputSelectLegend>
                                {!isReadOnly && !controlsDisabled ? (
                                    <MaskedTextField
                                        {...register(`providerLocations.${idx}.officePhoneNumber`)}
                                        placeholder={inputLabels.Office_Phone}
                                        inputType={'tel'}
                                        onTextChanged={handleInput}
                                        value={locationField.officePhoneNumber}
                                        onBlur={(e) => handleOfficePhoneNumberBlur(e.target.value, idx)}
                                        mask={'(999) 999-9999'}
                                        disabled={false}
                                        error={getErrorObject(idx)?.officePhoneNumber && true}
                                    />
                                ) : (
                                    <StyledInput
                                        {...register(`providerLocations.${idx}.officePhoneNumber`)}
                                        disabled={true}
                                        placeholder={inputLabels.Office_Phone}
                                    ></StyledInput>
                                )}
                                <ProviderError>
                                    {getErrorObject(idx)?.officePhoneNumber &&
                                        getErrorObject(idx)?.officePhoneNumber?.message}
                                </ProviderError>
                            </StyledAddressModalInputContainer>
                            <ProviderOfficePhoneHorizontalLine />

                            <StyledInputContainer>
                                <StyledInputSelectLegend isHeaderVisible={isHeaderVisible}>
                                    Office Location
                                </StyledInputSelectLegend>
                                <StyledMemberSelect>
                                    <Controller
                                        name={`providerLocations.${idx}.locationAddress`}
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Select
                                                options={npiLocationsOptions}
                                                components={{
                                                    DropdownIndicator: createDropdownIndicator(
                                                        locationDropDownListStyleParams
                                                    )
                                                }}
                                                {...field}
                                                placeholder={'Select Location'}
                                                styles={createStyles(
                                                    locationDropDownListStyleParams,
                                                    controlsDisabled || isReadOnly,
                                                    getErrorObject(idx)?.locationAddress != null
                                                )}
                                                isDisabled={controlsDisabled}
                                                isSearchable={true}
                                                value={npiLocationsOptions.find((option) =>
                                                    AreAddressesTheSame(
                                                        (field.value as INpiLocationsOption)?.value?.location,
                                                        option.value?.location
                                                    )
                                                )}
                                            />
                                        )}
                                    />
                                    <ProviderError>
                                        {getErrorObject(idx)?.locationAddress &&
                                            getErrorObject(idx)?.locationAddress?.message}
                                    </ProviderError>
                                </StyledMemberSelect>
                            </StyledInputContainer>
                            {npiLocations && npiLocations?.length > 0 && !isReadOnly && (
                                <StyledAddLocationButtonWrap>
                                    <StyledEditPCPLocationButton
                                        style={
                                            !(npiLocationsArray && npiLocationsArray?.length > 0) ||
                                            getLocationFromForm(idx)?.selectedLocationIndex === -1
                                                ? { visibility: 'hidden' }
                                                : {}
                                        }
                                        onClick={() => editSelectedLocation(idx)}
                                    >
                                        <CustomSvgIcon size={18} iconSet={{ icon: editPen }} svg></CustomSvgIcon>
                                        <StyledPCPToolTip>Edit</StyledPCPToolTip>
                                    </StyledEditPCPLocationButton>
                                </StyledAddLocationButtonWrap>
                            )}
                            {idx > 0 && !isReadOnly ? (
                                <article>
                                    <StyledDeleteLocationButton size="30" onClick={() => remove(idx)}>
                                        <img src={require('assets/delete-2.svg').default} alt="remove npi" />
                                        <StyledPCPToolTip>Delete</StyledPCPToolTip>
                                    </StyledDeleteLocationButton>
                                </article>
                            ) : (
                                ''
                            )}
                        </StyledInputContainerWrap>
                    )
                })}
                {!isReadOnly && (
                    <StyledAddLocationButton
                        onClick={handleAddLocation}
                        disabled={!(npiLocationsArray && npiLocationsArray?.length > 0)}
                    >
                        + Add location
                    </StyledAddLocationButton>
                )}
            </StyledUserDetailsFieldSet>
        </article>
    )
}

export default NewPCPNP
