import { StyledCancelButton, StyledConfirmButton } from 'components/ConfirmationBox/index.style'
import Modal from 'components/Modal'
import SearchTextField from 'components/SearchTextField'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import useDebounce from 'hooks/useDebounce'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PointsInCareState, clearSpecialists, retrieveSpecialists } from 'redux/slices/pointsInCare'
import { ISpecialist } from 'pages/hcp/users/create/index.models'
import { dispatch } from 'redux/store'

import {
    StyledButtonContainer,
    StyledReferralSearchResult,
    StyledReferralSearchResultsContainer,
    StyledReferralSearchResultsWrap,
    StyledReferralTitleContainer
} from './index.style'

interface IReferralProps {
    onCancel: () => void
    onSelected: (referral: ISpecialist) => void
}

export const ReferralModalDialog: FC<IReferralProps> = ({ onSelected, onCancel }) => {
    const [isLoading, setIsLoading] = useState(false)

    const specialists = useSelector((state: { pointsInCare: PointsInCareState }) => state.pointsInCare.specialists)

    const [searchValue, setSearchValue] = useState('')
    const [selectedReferral, setSelectedReferral] = useState(null)
    const debouncedFilter = useDebounce(searchValue, 500)

    useEffect(() => {
        // only retrieve specialists if the inputValue is at least 3 characters long
        if (debouncedFilter && debouncedFilter.length > 2) {
            dispatch(retrieveSpecialists({ filter: debouncedFilter }))
        }
    }, [debouncedFilter])

    useEffect(() => {
        if (searchValue.length <= 0) {
            clearSearch()
        }
    }, [searchValue, selectedReferral])

    const clearSearch = () => {
        setSearchValue('')
        setSelectedReferral(null)
        dispatch(clearSpecialists())
    }

    const handleSearchValue = (value: any) => {
        setSearchValue(value)
    }

    const handleSelectedSearchValue = (specialist: ISpecialist) => {
        const selectedSpecialist = `${specialist.firstName} ${specialist.lastName}
        ${specialist.specialty} - ${specialist.locations[0]?.city}, ${specialist.locations[0]?.state}`
        setSelectedReferral(specialist)
        setSearchValue(selectedSpecialist)

        dispatch(clearSpecialists())
    }

    return (
        <Modal onClose={onCancel} minWidth={600} minHeight={250}>
            <StyledReferralTitleContainer>
                <Typography type={TYPOGRAPHY_TYPES.h4}>Select a Referral</Typography>
            </StyledReferralTitleContainer>

            <StyledReferralSearchResultsWrap>
                <SearchTextField
                    placeholder={'Search'}
                    value={searchValue}
                    // loading={}
                    onClearSearch={clearSearch}
                    onValueChanged={handleSearchValue}
                />

                {specialists?.length > 0 && selectedReferral === null ? (
                    <StyledReferralSearchResultsContainer>
                        {specialists.map((specialist: ISpecialist, idx: number) => {
                            return (
                                <StyledReferralSearchResult onClick={() => handleSelectedSearchValue(specialist)}>
                                    {`${specialist.firstName} ${specialist.lastName}`}{' '}
                                    <span>{`(${specialist.specialty} - ${specialist.locations[0]?.city}, ${specialist.locations[0]?.state} )`}</span>
                                </StyledReferralSearchResult>
                            )
                        })}
                    </StyledReferralSearchResultsContainer>
                ) : (
                    ''
                )}
            </StyledReferralSearchResultsWrap>

            <StyledButtonContainer>
                <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
                <StyledConfirmButton onClick={() => onSelected(selectedReferral)} color={'#2281C4'}>
                    Select
                </StyledConfirmButton>
            </StyledButtonContainer>
        </Modal>
    )
}
