import { FC, useState } from 'react'
import AwvTemplateUpload from './AwvTemplateUpload'
import { EAdminNavBarItems } from './EAdminNavBarItems'
import AdminNavBar from './AdminNavBar'
import { AdminNavBarConfig } from './AdminNavBarConfig'
import { dispatch } from 'redux/store'
import { postAwvElectronicForm, uploadAwvDocumentForm } from 'redux/slices/memberProfile'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IMembersProps {}

const Admin: FC<IMembersProps> = ({}) => {
    const [selectedTab, setSelectedTab] = useState(EAdminNavBarItems.AwvPdfTemplateUpload)
    const uploadAWVPDFEvent = usePostCustomEvent(pageTitle.AdminUpdateAWVPDFTemplate, customEvents.UploadAWVPDFTemplate)
    const uploadAWVElectronicEvent = usePostCustomEvent(
        pageTitle.AdminUpdateAWVPDFTemplate,
        customEvents.UploadAWVElectronicTemplate
    )

    const handleSelectedTab = (selected: any) => {
        setSelectedTab(selected)
    }

    const handleAwvPdfTemplateUpload = async (file: any) => {
        uploadAWVPDFEvent()
        await dispatch(uploadAwvDocumentForm({ File: file }))
    }

    const handleAwvElectronicTemplateUpload = async (file: any) => {
        uploadAWVElectronicEvent()
        const awvForm = JSON.parse(await file.text())
        await dispatch(postAwvElectronicForm({ awvForm }))
    }

    return (
        <>
            <AdminNavBar navBarConfig={AdminNavBarConfig} selectedTab={handleSelectedTab} />
            {selectedTab === EAdminNavBarItems.AwvPdfTemplateUpload ? (
                <>
                    <AwvTemplateUpload
                        contentType="application/pdf"
                        extension=".pdf"
                        title="Upload your new AWV PDF Template here."
                        uploadAwvTemplate={handleAwvPdfTemplateUpload}
                    />
                </>
            ) : selectedTab === EAdminNavBarItems.AwvElectronicTemplateUpload ? (
                <AwvTemplateUpload
                    contentType="application/json"
                    extension=".json"
                    title="Upload your new electronic AWV Template here."
                    uploadAwvTemplate={handleAwvElectronicTemplateUpload}
                />
            ) : (
                ''
            )}
        </>
    )
}

export default Admin
