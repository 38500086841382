import { FC } from 'react'
import { StyledAWVDigitalInput } from '../index.style'
import { useFormContext } from 'react-hook-form'
import { Label } from '../Label'

interface InputFieldProps {
    type: string
    name: string
    label: string
    subLabel: string
    placeholder?: string
}

export const InputField: FC<InputFieldProps> = ({ type, name, label, subLabel, placeholder }) => {
    const {
        register,
        formState: { errors }
    } = useFormContext()

    return (
        <StyledAWVDigitalInput>
            <Label htmlFor={name} label={label} subLabel={subLabel} />
            <input type={type} id={name} name={name} aria-label={label} placeholder={placeholder} {...register(name)} />
            {errors?.[name] && <small>{errors?.[name].message}</small>}
        </StyledAWVDigitalInput>
    )
}
