import { FC } from 'react'
import {
    StyledAddLink,
    StyledDeleteButton,
    StyledExplanatoryNote,
    StyledFormTextAreaContainer,
    StyledTableStatusListContainer,
    StyledTextFieldContainer,
    StyledTxtFieldRadioTableBody,
    StyledTxtFieldRadioTableBodyItem,
    StyledTxtFieldRadioTableContainer,
    StyledTxtFieldRadioTableHeader,
    StyledTxtFieldRadioTableHeaderItem
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { IOtherConditionsList, IOption, ISectionElement } from '../index.models'

interface IOtherConditionsListProps {
    name: string
    activeChronicDiagnosisFieldName: string
    statusFieldName: string
    statusOptions: IOption[]
    icd10CodeFieldName: string
    planFieldName: string
    activeChronicDiagnosisColumnLabel: string
    statusColumnLabel: string
    planColumnLabel: string
    statusLabel: string
    icd10CodeLabel: string
    explanatoryNote: string
    addConditionLinkLabel: string
    deleteTooltip: string
}

const deleteIcon = require('assets/delete-2.svg').default

const baseHeight = 127

const statusOptionHeight = 36

const OtherConditionsList: FC<IOtherConditionsListProps> = ({
    name,
    activeChronicDiagnosisFieldName,
    statusFieldName,
    statusOptions,
    icd10CodeFieldName,
    planFieldName,
    activeChronicDiagnosisColumnLabel,
    statusColumnLabel,
    planColumnLabel,
    statusLabel,
    icd10CodeLabel,
    explanatoryNote,
    addConditionLinkLabel,
    deleteTooltip
}) => {
    const { register, control } = useFormContext()
    const { fields, append, remove } = useFieldArray({ control, name })

    const textAreaStyle = { height: `${baseHeight + statusOptions.length * statusOptionHeight}px` }

    const handleAdd = (e: any) => {
        e.preventDefault()
        append({})
    }

    const handleRemove = (e: any, index: number) => {
        e.preventDefault()
        remove(index)
    }

    return (
        <>
            {fields?.length > 0 && (
                <>
                    <StyledTxtFieldRadioTableContainer>
                        <StyledTxtFieldRadioTableHeader>
                            {[activeChronicDiagnosisColumnLabel, statusColumnLabel, planColumnLabel].map(
                                (headerLabel: string, index: number) => (
                                    <StyledTxtFieldRadioTableHeaderItem key={`${name}-header-${index}`}>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>{headerLabel}</Typography>
                                    </StyledTxtFieldRadioTableHeaderItem>
                                )
                            )}
                        </StyledTxtFieldRadioTableHeader>
                        {fields.map((field: any, index: number) => (
                            <StyledTxtFieldRadioTableBody key={field.id}>
                                <StyledTxtFieldRadioTableBodyItem>
                                    <StyledFormTextAreaContainer>
                                        <textarea
                                            {...register(`${name}.${index}.${activeChronicDiagnosisFieldName}`)}
                                            style={textAreaStyle}
                                        />
                                    </StyledFormTextAreaContainer>
                                </StyledTxtFieldRadioTableBodyItem>
                                <StyledTxtFieldRadioTableBodyItem>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>{statusLabel}</Typography>
                                    {statusOptions.map((option: IOption, optionIndex: number) => (
                                        <StyledTableStatusListContainer key={`${field.id}-statusOption-${optionIndex}`}>
                                            <label>
                                                <input
                                                    {...register(`${name}.${index}.${statusFieldName}`)}
                                                    type="radio"
                                                    value={option.value}
                                                />
                                                {option.label}
                                            </label>
                                        </StyledTableStatusListContainer>
                                    ))}
                                    <Typography type={TYPOGRAPHY_TYPES.p}>{icd10CodeLabel}</Typography>
                                    <StyledTextFieldContainer>
                                        <input {...register(`${name}.${index}.${icd10CodeFieldName}`)} />
                                    </StyledTextFieldContainer>
                                </StyledTxtFieldRadioTableBodyItem>
                                <StyledTxtFieldRadioTableBodyItem>
                                    <StyledFormTextAreaContainer>
                                        <textarea
                                            {...register(`${name}.${index}.${planFieldName}`)}
                                            style={textAreaStyle}
                                        />
                                    </StyledFormTextAreaContainer>
                                </StyledTxtFieldRadioTableBodyItem>
                                <StyledTxtFieldRadioTableBodyItem>
                                    <StyledDeleteButton onClick={(e) => handleRemove(e, index)}>
                                        <img src={deleteIcon} alt={deleteTooltip ?? 'Delete'} />
                                        <p>{deleteTooltip ?? 'Delete'}</p>
                                    </StyledDeleteButton>
                                </StyledTxtFieldRadioTableBodyItem>
                            </StyledTxtFieldRadioTableBody>
                        ))}
                    </StyledTxtFieldRadioTableContainer>
                    {explanatoryNote && <StyledExplanatoryNote>{explanatoryNote}</StyledExplanatoryNote>}
                </>
            )}
            <br />
            <StyledAddLink onClick={handleAdd}>{addConditionLinkLabel}</StyledAddLink>
        </>
    )
}

export const renderOtherConditionsList = (element: ISectionElement, key: string) => {
    const otherConditionsList = element as IOtherConditionsList
    return <OtherConditionsList key={key} {...otherConditionsList} />
}
