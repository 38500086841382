import React, { FC, useState } from 'react'
import { StyledPICTabControllerContainer, StyledPICTabItem, StyledPICTabs } from './index.style'

interface IPICTabControllerProps {
    selectedTab?: (selectedNavItem: string) => void
}

export enum EPICTabs {
    REVIEW = 'For Review',
//    CONSIDERATION = 'For Consideration',
    QUALITY = 'Quality'
}

const PICTabController: FC<IPICTabControllerProps> = ({ selectedTab }) => {
    const [tabIsActive, setTabIsActive] = useState<string>(EPICTabs.REVIEW)
    const tabs = Object.values(EPICTabs)

    const handleTabSelection = (activeTab: string) => {
        setTabIsActive(activeTab)
        selectedTab(activeTab)
    }

    return (
        <StyledPICTabControllerContainer>
            <StyledPICTabs>
                {tabs.map((label: string, idx: number) => {
                    return (
                        <StyledPICTabItem
                            onClick={() => handleTabSelection(label)}
                            isActive={tabIsActive === label}
                            key={`tabLabel-${idx}`}
                        >
                            {label}
                        </StyledPICTabItem>
                    )
                })}
            </StyledPICTabs>
            <div></div>
        </StyledPICTabControllerContainer>
    )
}

export default PICTabController
