import Modal from 'components/Modal'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useEffect, useState } from 'react'
import {
    StyledCancelButton,
    StyledModalButtonContainer,
    StyledSaveButton,
    StyledTextArea,
    StyledTreatmentModalContainer
} from './index.style'
import { dispatch, useSelector } from 'redux/store'
import { PointsInCareState, retrieveTreatmentPlan } from 'redux/slices/pointsInCare'
import useEventListener from 'hooks/useEventListener'

interface ITreatmentModalProps {
    recaptureId: string
    onSave: (treatment: string) => void
    onCancel: () => void
}

const TreatmentModal: FC<ITreatmentModalProps> = ({ recaptureId, onSave, onCancel }) => {
    
    const [treatmentText, setTreatmentText] = useState<string>("")

    const currentTreatmentPlan = useSelector(
        (state: { pointsInCare: PointsInCareState }) => state.pointsInCare.currentTreatmentPlan
    )


    useEffect(() => {
        if (currentTreatmentPlan) {
            setTreatmentText(currentTreatmentPlan)
        }
    }, [currentTreatmentPlan])

    useEffect(() => {
        // fetch treatment text
        console.log(recaptureId)
        setTreatmentText("")
        dispatch(retrieveTreatmentPlan({recaptureId: recaptureId}))
    }, [recaptureId])

    const handleChange = (event: any) => {
        setTreatmentText(event.target.value); // update 'text' to reflect the current value of the textarea
    }

    const handleSave = () => {
        // clear out treatment text
        onSave(treatmentText)
    }

            

    const handleCancel = () => {
        // clear out treatment text
        onCancel()
    }

    return (
        <Modal minHeight={350} minWidth={650} onClose={onCancel}>
            <StyledTreatmentModalContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>Treatment Plan</Typography>
                <StyledTextArea placeholder="Enter your concerns here..." value={treatmentText} onChange={handleChange}></StyledTextArea>
                <StyledModalButtonContainer>
                    <StyledCancelButton onClick={handleCancel}>Cancel</StyledCancelButton>
                    <StyledSaveButton onClick={handleSave}>Save</StyledSaveButton>
                </StyledModalButtonContainer>
            </StyledTreatmentModalContainer>
        </Modal>
    )
}

export default TreatmentModal
