import DropDownList from "components/DropDownList"
import { ILocationDetailData, INPIDetail, INPILocationsData, IOfficeAdminProviderInfo } from "pages/hcp/users/create/index.models"
import { npiDropDownListStyleParams, StyledNPIandLocationWrap, StyledNPIErrorMessage, StyledNPILink, StyledNPIWrap, StyledTaxIdWrap, NpiVerticalLine, NpiHalfHorizontalLine, TaxIdVerticalLine, TaxIdHalfHorizontalLine, TaxIdAdditionalVerticalLine } from './index.style';
import { LocationList } from "./LocationList"
import { ITaxIdNpiData, FullLocationIndexes } from '../../pages/hcp/users/create/index.models';
import { setCurrentNpi } from 'redux/slices/pulse8';
import { FocusEvent } from "react";
import { handleAddingInitialLocation, handleAddingNewNPI } from "./common";

interface IProviderListProps {
    userId: string,
    providerInfo: ITaxIdNpiData
    taxIdIndex: number,
    dictionaryOfNpis: Map<string, INPIDetail[]>,
    currentTaxIdIndex: number,
    taxId: string,
    currentProviderInfo: IOfficeAdminProviderInfo,
    isReadOnly: boolean,
    dictionaryOfLocations: Map<string, ILocationDetailData[]>,
    setProviderInfo : (providerInfo: IOfficeAdminProviderInfo) => void,
    setNewLocationAdded: React.Dispatch<React.SetStateAction<FullLocationIndexes>>,
    validFormCallback: (isValid: boolean) => void
    handleTaxIdInput: (e: any, index: number) => void
    handleTaxIdBlur: (e: React.FocusEvent<HTMLInputElement, Element>, taxIdIndex: number) => void
}

export  const ProviderList = ({ userId,
                                providerInfo, taxIdIndex, dictionaryOfNpis, currentTaxIdIndex, taxId, currentProviderInfo, isReadOnly, 
                                dictionaryOfLocations, setProviderInfo, setNewLocationAdded,
                                validFormCallback,
                                handleTaxIdInput,
                                handleTaxIdBlur}: IProviderListProps) => 
                {                    
                    const providers: INPILocationsData[] = providerInfo.providers

                    const areThereDuplicateTaxIds = (taxId: string) => {
                        let count = 0
                        currentProviderInfo.taxIdProviderGroupings.forEach((grouping) => {
                            if (grouping.taxId === taxId) {
                                count++
                            }
                        })
                        return count > 1
                    }

                    function handleDuplicateNpis(
                        newData: { taxIdProviderGroupings: ITaxIdNpiData[] },
                        taxIdIndex: number,
                        npi: string,
                        npiIndex: number
                    ) {
                        const otherNpis = newData.taxIdProviderGroupings[taxIdIndex].providers.find(
                            (provider, index) => provider.npi === npi && npiIndex !== index
                        )
                        const isDuplicate = otherNpis !== undefined && npi !== ''
                
                        if (isDuplicate) {
                            // clear the required field errors
                            newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].error = true
                            newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].errorMessage = 'DuplicateNpi'
                        }
                
                        return isDuplicate
                    }

                    function handleNpiSelection(taxIdIndex: number, npiIndex: number, npi: any): void {
                        // Insert the selected npi into the data structure with its matching taxid
                        const newData = { ...currentProviderInfo }
                
                        // reset the validity of the parent form
                        validFormCallback(true)
                
                        // clear the required field errors
                        currentProviderInfo.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].error = false
                        currentProviderInfo.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].errorMessage = 'Required'
                
                        // check if duplicate npi is being selected within the same tax id
                        const isDuplicate = handleDuplicateNpis(newData, taxIdIndex, npi, npiIndex)
                
                        newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].error = isDuplicate
                        if (isDuplicate) {
                            newData.taxIdProviderGroupings[taxIdIndex].providers[npiIndex].errorMessage = 'Duplicate NPI'
                        }
                
                        // need to add npi to provider structure
                        const taxIdData = newData.taxIdProviderGroupings[taxIdIndex]
                        taxIdData.providers[npiIndex].npi = npi
                
                        const npiProviders = newData.taxIdProviderGroupings[taxIdIndex].providers
                
                        handleAddingInitialLocation(npiProviders, npiIndex)
                
                        setProviderInfo(newData)
                        setNewLocationAdded({ taxIdIndex, npiIndex, locationIndex: 0 })
                
                        // add the location now
                    }

                    const checkIfLocationsAreMissingInDropDown = (taxId: string, npidata: INPILocationsData) => {
                        return !dictionaryOfNpis.has(taxId) || dictionaryOfNpis.get(taxId)?.length === 0 || npidata.npi === ''
                    }

                    return (
                        <div key={`taxid_provider_div_${providerInfo.taxId}_${taxIdIndex}`}>
                            {providers.map((npidata: INPILocationsData, npiIndex: number) => {
                                const isLocationDisabled = checkIfLocationsAreMissingInDropDown(taxId, npidata)

                                

                                const isNpiDisabled =
                                    !dictionaryOfNpis.has(taxId) ||
                                    dictionaryOfNpis.get(taxId)?.length === 0 ||
                                    (areThereDuplicateTaxIds(taxId) && taxIdIndex === currentTaxIdIndex)

                                return (
                                    
                                    <StyledNPIandLocationWrap key = {`styled_npi_loc_wrap${taxId}_${npiIndex}_${npidata.npi}`}  
                                        
                                    >
                                        {(npiIndex < providers.length - 1 && npiIndex == 0) ? <TaxIdVerticalLine customHeight={(140 + (npidata.locations.length -1) * 100).toString() + "px" } /> : ''}
                                        {(npiIndex < providers.length - 1 && npiIndex != 0) ? <TaxIdAdditionalVerticalLine customHeight={(145 + (npidata.locations.length -1) * 100).toString() + "px" } /> : ''}
                                        {(npiIndex < providers.length - 1) ? <TaxIdHalfHorizontalLine customHeight={(185 + (npidata.locations.length - 1)  * 100).toString() + "px" } /> : ''}

                                        <article key={`article_npi_loc_${taxId}_${npiIndex}_${npidata.npi}`}>
                                            <StyledNPIWrap key={`styled_npi_taxid_wrap_${taxId}_${npiIndex}_${npidata.npi}`}>
                                                <StyledTaxIdWrap  key={`styled_taxid_wrap2_${taxId}_${npiIndex}_${npidata.npi}`}>
                                                    <DropDownList key={`npi_dropdown_taxid2_${taxId}_${taxIdIndex}_${npiIndex}_${npidata.npi}`}
                                                        options={dictionaryOfNpis
                                                            .get(taxId)
                                                            ?.map((npiDetail: INPIDetail) => ({
                                                                label: `${npiDetail.lastName}, ${npiDetail.firstName} - ${npiDetail.npi}`,
                                                                value: npiDetail.npi
                                                            }))}
                                                        selectedValue={npidata.npi}
                                                        setSelectedValue={(value) =>
                                                            handleNpiSelection(taxIdIndex, npiIndex, value)
                                                        }
                                                        placeholder="National Provider Identifier (NPI)"
                                                        isDisabled={isNpiDisabled}
                                                        isReadOnly={isReadOnly || isNpiDisabled}
                                                        isInError={
                                                            currentProviderInfo.taxIdProviderGroupings[taxIdIndex]
                                                                .providers[npiIndex].error
                                                        }
                                                        styleParams={npiDropDownListStyleParams}
                                                    />
                                                    <StyledNPIErrorMessage
                                                        hidden={
                                                            !currentProviderInfo.taxIdProviderGroupings[taxIdIndex]
                                                                .providers[npiIndex].error
                                                        }
                                                    >
                                                        {
                                                            currentProviderInfo.taxIdProviderGroupings[taxIdIndex]
                                                                .providers[npiIndex].errorMessage
                                                        }
                                                    </StyledNPIErrorMessage>
                                                </StyledTaxIdWrap>
                                            </StyledNPIWrap>
                                        </article>
                                        <article key={`location_list1_article_${taxId}_${npiIndex}_${npidata.npi}`}>
                                            <LocationList npidata={npidata} key={`location_list1_${taxId}_${npiIndex}_${npidata.npi}`}
                                            providerInfo={providerInfo}
                                            checkIfLocationsAreMissingInDropDown={(taxId: string, locationData: INPILocationsData) => checkIfLocationsAreMissingInDropDown(taxId, locationData)}
                                            currentProviderInfo={currentProviderInfo}
                                            taxIdIndex={taxIdIndex}
                                            npiIndex={npiIndex}
                                            isReadOnly={isReadOnly}
                                            isLocationDisabled={isLocationDisabled}
                                            dictionaryOfLocations={dictionaryOfLocations}
                                            dictionaryOfNpis={dictionaryOfNpis}
                                            taxId={taxId}
                                            setCurrentNpi={setCurrentNpi}
                                            setNewLocationAdded={setNewLocationAdded}
                                            setProviderInfo={setProviderInfo}
                                            userId={userId}                                            
                                            validFormCallback={validFormCallback} 
                                            handleTaxIdInput={(e: any, index: number) => handleTaxIdInput(e, index)}
                                            handleTaxIdBlur={(e, taxIdIndex) => handleTaxIdBlur(e, taxIdIndex)}
                                            handleNpiSelection={(taxIdIndex, npiIndex, npi) => handleNpiSelection(taxIdIndex, npiIndex, npi)}
                                            />

                                        </article>
                                    </StyledNPIandLocationWrap>
                                )
                            })}

                            <StyledNPILink key = {`styled_npi_link_${taxId}_${taxIdIndex}`}
                                onClick={() => {
                                    handleAddingNewNPI(taxIdIndex, currentProviderInfo, validFormCallback, setProviderInfo)
                                }}
                                disabled={
                                    !dictionaryOfNpis.has(taxId) ||
                                    dictionaryOfNpis.get(taxId)?.length === 0 ||
                                    (areThereDuplicateTaxIds(taxId) && taxIdIndex == currentTaxIdIndex)
                                }
                            >
                                + Add NPI
                            </StyledNPILink>
                        </div>
                    )
                }