import { createSlice } from '@reduxjs/toolkit'
import axios from '../../utilities/axios'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'
import {
    IAddAwvFeedbackRequest,
    IAddMemberFeedbackRequest,
    IAddProviderDocumentFeedbackRequest,
    IFeedbackItem,
    IGetAwvFeedbackRequest,
    IGetMemberFeedbackRequest,
    IGetProviderDocumentFeedbackRequest,
    IHasNewFeedbackResponse
} from 'pages/hcp/users/create/index.models'
import { dispatch } from 'redux/store'
import { providerDocumentFeedbackRead } from './documents'
import { memberFeedbackRead, reviewableAwvFormFeedbackRead } from './memberProfile'
import { memberOutreachFeedbackRead } from './incentive'

export const getMemberFeedbackKey = (membid: string, npi: string) => `${membid}-${npi}`

export type FeedbackState = {
    providerDocumentFeedback: any
    awvHasNewFeedback: any
    awvFeedback: any
    memberFeedback: any
}

const initialState: FeedbackState = {
    providerDocumentFeedback: {},
    awvHasNewFeedback: {},
    awvFeedback: {},
    memberFeedback: {}
}

export const feedbackSlice = createSlice({
    name: 'feedbackSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProviderDocumentFeedback.fulfilled, (state, action) => {
                state.providerDocumentFeedback = {
                    ...state.providerDocumentFeedback,
                    [action.meta.arg.providerDocumentId]: action.payload
                }
            })
            .addCase(addProviderDocumentFeedback.fulfilled, (state, action) => {
                state.providerDocumentFeedback = {
                    ...state.providerDocumentFeedback,
                    [action.meta.arg.providerDocumentId]: [
                        ...(state.providerDocumentFeedback[action.meta.arg.providerDocumentId] ?? []),
                        action.payload
                    ]
                }
            })
            .addCase(hasNewAwvFeedback.fulfilled, (state, action) => {
                state.awvHasNewFeedback = { ...state.awvHasNewFeedback, [action.meta.arg.awvId]: action.payload }
            })
            .addCase(getAwvFeedback.fulfilled, (state, action) => {
                state.awvFeedback = { ...state.awvFeedback, [action.meta.arg.awvId]: action.payload }
            })
            .addCase(addAwvFeedback.fulfilled, (state, action) => {
                state.awvFeedback = {
                    ...state.awvFeedback,
                    [action.meta.arg.awvId]: [...(state.awvFeedback[action.meta.arg.awvId] ?? []), action.payload]
                }
            })
            .addCase(markProviderDocumentFeedbackRead.fulfilled, (state, action) => {})
            .addCase(markAwvFeedbackRead.fulfilled, (state, action) => {
                state.awvHasNewFeedback = {
                    ...state.awvHasNewFeedback,
                    [action.meta.arg.awvId]: { hasNewFeedback: false, newFeedbackCount: 0 }
                }
            })
            .addCase(getMemberFeedback.fulfilled, (state, action) => {
                state.memberFeedback = {
                    ...state.memberFeedback,
                    [getMemberFeedbackKey(action.meta.arg.membid, action.meta.arg.npi)]: action.payload
                }
            })
            .addCase(addMemberFeedback.fulfilled, (state, action) => {
                const feedbackKey = getMemberFeedbackKey(action.meta.arg.membid, action.meta.arg.npi)
                state.memberFeedback = {
                    ...state.memberFeedback,
                    [feedbackKey]: [...(state.memberFeedback[feedbackKey] ?? []), action.payload]
                }
            })
    }
})

export default feedbackSlice.reducer

export const getAwvFeedback = createExceptionAwareAsyncThunk(
    'Awv/GetAwvFeedback',
    async (args: IGetAwvFeedbackRequest) => {
        const response = await axios.post<IFeedbackItem[]>('api/Awv/GetAwvFeedback', args)
        return response.data
    }
)

export const addAwvFeedback = createExceptionAwareAsyncThunk(
    'Awv/AddAwvFeedback',
    async (args: IAddAwvFeedbackRequest) => {
        const response = await axios.post<IFeedbackItem>('api/Awv/AddAwvFeedback', args)
        return response.data
    }
)

export const markAwvFeedbackRead = createExceptionAwareAsyncThunk(
    'Awv/MarkAwvFeedbackRead',
    async (args: IGetAwvFeedbackRequest) => {
        const response = await axios.post('api/Awv/MarkAwvFeedbackRead', args)

        if (response.status === 200) {
            dispatch(reviewableAwvFormFeedbackRead(args))
        }

        return response.data
    }
)

export const hasNewAwvFeedback = createExceptionAwareAsyncThunk(
    'Awv/HasNewAwvFeedback',
    async (args: IGetAwvFeedbackRequest) => {
        const response = await axios.post<IHasNewFeedbackResponse>('api/Awv/HasNewAwvFeedback', args)
        return response.data
    }
)

export const getMemberFeedback = createExceptionAwareAsyncThunk(
    'Incentives/GetMemberFeedback',
    async (args: IGetMemberFeedbackRequest) => {
        const response = await axios.post<IFeedbackItem[]>('api/Incentives/GetMemberFeedback', args)
        return response.data
    }
)

export const addMemberFeedback = createExceptionAwareAsyncThunk(
    'Incentives/AddMemberFeedback',
    async (args: IAddMemberFeedbackRequest) => {
        const response = await axios.post<IFeedbackItem>('api/Incentives/AddMemberFeedback', args)
        return response.data
    }
)

export const markMemberFeedbackRead = createExceptionAwareAsyncThunk(
    'Incentives/MarkMemberFeedbackRead',
    async (args: IGetMemberFeedbackRequest) => {
        const response = await axios.post('api/Incentives/MarkMemberFeedbackRead', args)

        if (response.status === 200) {
            dispatch(memberFeedbackRead(args))
            dispatch(memberOutreachFeedbackRead(args))
        }

        return response.data
    }
)

export const getProviderDocumentFeedback = createExceptionAwareAsyncThunk(
    'ProviderDocument/GetProviderDocumentFeedback',
    async (args: IGetProviderDocumentFeedbackRequest) => {
        const response = await axios.post<IFeedbackItem[]>('api/ProviderDocument/GetProviderDocumentFeedback', args)
        return response.data
    }
)

export const addProviderDocumentFeedback = createExceptionAwareAsyncThunk(
    'ProviderDocument/AddProviderDocumentFeedback',
    async (args: IAddProviderDocumentFeedbackRequest) => {
        const response = await axios.post<IFeedbackItem>('api/ProviderDocument/AddProviderDocumentFeedback', args)
        return response.data
    }
)

export const markProviderDocumentFeedbackRead = createExceptionAwareAsyncThunk(
    'ProviderDocument/MarkProviderDocumentFeedbackRead',
    async (args: IGetProviderDocumentFeedbackRequest) => {
        const response = await axios.post('api/ProviderDocument/MarkProviderDocumentFeedbackRead', args)

        if (response.status === 200) {
            dispatch(providerDocumentFeedbackRead(args))
        }

        return response.data
    }
)
