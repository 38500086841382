import { SortDirection } from 'models/sortColumn'
import { ListConfig, setFilter, setPageNumber, setSorting, setYear, UiState } from 'redux/slices/ui'
import { useDispatch, useSelector } from 'redux/store'
import { useEffect, useState } from 'react'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

const MINIMUM_EFFECTIVE_FILTER_LENGTH = 3

export interface IListConfig {
    pageNumber: number

    filter: string

    sortColumn: string

    sortDirection: SortDirection

    effectiveFilter: string

    year: number

    setPageNumber: (pageNumber: number) => void

    setFilter: (filter: string) => void

    clearFilter: () => void

    setSorting: (sortColumn: string, sortDirection: SortDirection) => void

    setYear: (year: number) => void
}

const useListConfig = (listName: string, pageTitle: string, eventName: string): IListConfig => {
    const listConfig = useSelector((state: { ui: UiState }) => state.ui.listConfigs[listName]) as ListConfig
    if (!listConfig) {
        throw Error(`${listName} is not a valid configurable list name.`)
    }

    const dispatch = useDispatch()
    const postCustomEvent = usePostCustomEvent(pageTitle, eventName)
    const [filterCount, setFilterCount] = useState<number>(0)

    useEffect(() => {
        if (filterCount === MINIMUM_EFFECTIVE_FILTER_LENGTH) {
            postCustomEvent()
            setFilterCount(filterCount + 1)
        }
    }, [filterCount, postCustomEvent, pageTitle, eventName])

    return {
        pageNumber: listConfig.pageNumber,
        filter: listConfig.filter,
        sortColumn: listConfig.sortColumn,
        sortDirection: listConfig.sortDirection,
        effectiveFilter: listConfig.filter.length >= MINIMUM_EFFECTIVE_FILTER_LENGTH ? listConfig.filter : '',
        year: listConfig.year,
        setPageNumber: (pageNumber: number) => {
            dispatch(setPageNumber({ listName, pageNumber }))
        },
        setFilter: (filter: string) => {
            setFilterCount(filterCount + 1)
            dispatch(setFilter({ listName, filter }))
        },
        clearFilter: () => {
            setFilterCount(0)
            dispatch(setFilter({ listName, filter: '' }))
        },
        setSorting: (sortColumn: string, sortDirection: SortDirection) => {
            dispatch(setSorting({ listName, sortColumn, sortDirection }))
        },
        setYear: (year: number) => {
            dispatch(setYear({ listName, year }))
        }
    }
}

export const useUsersListConfig = () => useListConfig('users', pageTitle.UsersSummary, customEvents.SearchUsers)

export const useMemberOutreachListConfig = () =>
    useListConfig('memberOutreach', pageTitle.PatientOutreach, customEvents.SearchOpportunities)

export const useMemberProfileListConfig = () =>
    useListConfig('memberProfile', pageTitle.PatientsSummary, customEvents.SearchUsers)

export const useReviewableAwvFormsListConfig = () =>
    useListConfig('reviewableAwvForms', pageTitle.SubmittedAWVForms, customEvents.SearchUsers)
