import { FC } from 'react'
import { StyledAWVDigitalInput } from '../index.style'
import { Controller, useFormContext } from 'react-hook-form'
import DropDownListComponent from 'components/DropDownList'
import { dropDownListStyleParams } from './index.style'
import { IDropDownList, ISectionElement } from '../index.models'
import { Label } from '../Label'

interface IOption {
    label: string
    value: any
}

interface IDropDownListProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
    options: IOption[]
}

export const DropDownList: FC<IDropDownListProps> = ({ name, label, subLabel, placeholder, options }) => {
    const {
        formState: { errors },
        control,
        setValue
    } = useFormContext()

    return (
        <StyledAWVDigitalInput>
            <Label htmlFor={name} label={label} subLabel={subLabel} />
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <DropDownListComponent
                        {...field}
                        id={name}
                        placeholder={placeholder}
                        options={options}
                        selectedValue={field.value}
                        setSelectedValue={(value) => {
                            setValue(name, value)
                        }}
                        isInError={errors[name] !== null && errors[name] !== undefined}
                        styleParams={dropDownListStyleParams}
                    />
                )}
            />
            {errors?.[name] && <small>{errors?.[name].message}</small>}
        </StyledAWVDigitalInput>
    )
}

export const renderDropDownList = (element: ISectionElement, key: string) => {
    const dropDownList = element as IDropDownList
    return <DropDownList key={key} {...dropDownList} />
}
