import { FC, useEffect, useState } from 'react'
import { StyledAWVDigitalInput } from '../index.style'
import { IIcdDiagnosisPicker, ISectionElement } from '../index.models'
import { Autocomplete, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { DiagnosisState, getIcdDiagnoses } from 'redux/slices/diagnosis'
import { dispatch } from 'redux/store'
import { Label } from '../Label'
import { StyledIcdDiagnosisPickerContainer } from './index.style'
import useDebounce from 'hooks/useDebounce'
import { Controller, useFormContext } from 'react-hook-form'

interface IIcdDiagnosisPickerProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
    noOptionsText: string
}

const IcdDiagnosisPicker: FC<IIcdDiagnosisPickerProps> = ({ name, label, subLabel, placeholder, noOptionsText }) => {
    const {
        formState: { errors },
        control,
        setValue,
        watch
    } = useFormContext()

    const currentValue = watch(name)
    const [originalValue, setOriginalValue] = useState(currentValue)

    const [inputValue, setInputValue] = useState('')
    const [initialInputValueSet, setInitialInputValueSet] = useState(false)
    const debouncedInputValue = useDebounce(inputValue, 500)

    const [selectedValue, setSelectedValue] = useState(null)
    const [initialSelectedValueSet, setInitialSelectedValueSet] = useState(false)

    const { diagnosesByFilter } = useSelector((state: { diagnosis: DiagnosisState }) => state.diagnosis)

    const options = selectedValue
        ? diagnosesByFilter[inputValue]
            ? diagnosesByFilter[inputValue].find((x: any) => x.code === selectedValue.code)
                ? diagnosesByFilter[inputValue]
                : [selectedValue, ...diagnosesByFilter[inputValue]]
            : [selectedValue]
        : diagnosesByFilter[inputValue] ?? []

    useEffect(() => {
        if (currentValue && !originalValue) {
            setOriginalValue(currentValue)
        }
    }, [currentValue, originalValue])

    useEffect(() => {
        if (originalValue && !inputValue && !initialInputValueSet) {
            setInputValue(originalValue)
            setInitialInputValueSet(true)
        }
    }, [originalValue, inputValue, initialInputValueSet])

    useEffect(() => {
        if (originalValue && !selectedValue && !initialSelectedValueSet && diagnosesByFilter[debouncedInputValue]) {
            const actualValue = diagnosesByFilter[debouncedInputValue]?.find((x: any) => x.code === originalValue)
            if (actualValue) {
                setSelectedValue(actualValue)
                setInitialSelectedValueSet(true)
            }
        }
    }, [originalValue, selectedValue, initialSelectedValueSet, diagnosesByFilter, debouncedInputValue])

    useEffect(() => {
        if (!diagnosesByFilter[debouncedInputValue]) {
            dispatch(getIcdDiagnoses({ filter: debouncedInputValue }))
        }
    }, [diagnosesByFilter, debouncedInputValue, dispatch])

    useEffect(() => {
        if (selectedValue) {
            const actualValue = diagnosesByFilter[inputValue]?.find((x: any) => x.code === selectedValue.code)
            if (actualValue && selectedValue.label !== actualValue.label) {
                setSelectedValue(actualValue)
            }
        }
    }, [diagnosesByFilter, selectedValue])

    useEffect(() => {
        setValue(name, selectedValue ? selectedValue.code : null)
    }, [selectedValue])

    return (
        <StyledIcdDiagnosisPickerContainer isInError={errors[name] !== null && errors[name] !== undefined}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        disablePortal
                        id={name}
                        options={options}
                        noOptionsText={noOptionsText ?? 'No diagnoses'}
                        filterOptions={(x) => x}
                        sx={{ width: '400px' }}
                        value={selectedValue}
                        onInputChange={(_, value) => {
                            setInputValue(value)
                        }}
                        onChange={(_, value) => {
                            setSelectedValue(value)
                        }}
                        isOptionEqualToValue={(option: any, value: any) => option?.code === value?.code}
                        renderInput={(params) => (
                            <StyledAWVDigitalInput>
                                <Label {...params.InputLabelProps} htmlFor={name} label={label} subLabel={subLabel} />
                                <TextField {...params} placeholder={placeholder ?? label} />
                                {errors?.[name] && <small>{errors?.[name].message}</small>}
                            </StyledAWVDigitalInput>
                        )}
                    />
                )}
            />
        </StyledIcdDiagnosisPickerContainer>
    )
}

export const renderIcdDiagnosisPicker = (element: ISectionElement, key: string) => {
    const icdDiagnosisPicker = element as IIcdDiagnosisPicker
    return <IcdDiagnosisPicker key={key} {...icdDiagnosisPicker} />
}
