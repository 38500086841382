import { createSlice, Draft } from '@reduxjs/toolkit'
import { SortDirection } from 'models/sortColumn'

type ListConfigDefault = {
    sortColumn: string
    sortDirection: SortDirection
}

const listConfigDefaults = {
    users: {
        sortColumn: 'LastName',
        sortDirection: SortDirection.ascending
    },
    memberOutreach: {
        sortColumn: 'IncentiveRemaining',
        sortDirection: SortDirection.descending
    },
    memberProfile: {
        sortColumn: 'LastName',
        sortDirection: SortDirection.ascending
    },
    reviewableAwvForms: {
        sortColumn: 'Patient',
        sortDirection: SortDirection.ascending
    }
}

export type ListConfig = {
    pageNumber: number
    filter: string
    sortColumn: string
    sortDirection: SortDirection
    year: number
}

const getLocalStorageKey = (listName: string, valueName: string) => `ui-${listName}-${valueName}`

const getLocalStorageSortColumnKey = (listName: string) => getLocalStorageKey(listName, 'sort-column')

const getLocalStorageSortDirectionKey = (listName: string) => getLocalStorageKey(listName, 'sort-direction')

const getListConfigFromLocalStorageOrDefault = (listName: string): ListConfig => {
    const defaultListConfig = (listConfigDefaults as any)[listName] as ListConfigDefault
    const sortColumn = window.localStorage[getLocalStorageSortColumnKey(listName)]
    const sortDirection = window.localStorage[getLocalStorageSortDirectionKey(listName)]
    return {
        pageNumber: 1,
        filter: '',
        sortColumn: sortColumn || defaultListConfig.sortColumn,
        sortDirection: sortDirection ? Number(sortDirection) : defaultListConfig.sortDirection,
        year: (new Date()).getFullYear()
    }
}

export type UiState = {
    listConfigs: any
}

export const initialState: UiState = {
    listConfigs: {
        users: getListConfigFromLocalStorageOrDefault('users'),
        memberOutreach: getListConfigFromLocalStorageOrDefault('memberOutreach'),
        memberProfile: getListConfigFromLocalStorageOrDefault('memberProfile'),
        reviewableAwvForms: getListConfigFromLocalStorageOrDefault('reviewableAwvForms')
    }
}

const setListConfigValue = (state: Draft<UiState>, listName: string, setValue: (listConfig: ListConfig) => void) => {
    const listConfig = state.listConfigs[listName] as ListConfig
    if (!listConfig) {
        throw Error(`${listName} is not a valid configurable list name.`)
    }
    const updatedListConfig = { ...listConfig }
    setValue(updatedListConfig)
    state.listConfigs = {
        ...state.listConfigs,
        [listName]: updatedListConfig
    }
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setPageNumber: (state, action) => {
            const listName = action.payload.listName as string
            const pageNumber = action.payload.pageNumber as number
            setListConfigValue(state, listName, (listConfig) => {
                listConfig.pageNumber = pageNumber
            })
        },
        setFilter: (state, action) => {
            const listName = action.payload.listName as string
            const filter = action.payload.filter as string
            setListConfigValue(state, listName, (listConfig) => {
                listConfig.pageNumber = 1
                listConfig.filter = filter
            })
        },
        setSorting: (state, action) => {
            const listName = action.payload.listName as string
            const sortColumn = action.payload.sortColumn as string
            const sortDirection = action.payload.sortDirection
            window.localStorage.setItem(getLocalStorageSortColumnKey(listName), sortColumn)
            window.localStorage.setItem(getLocalStorageSortDirectionKey(listName), sortDirection)
            setListConfigValue(state, listName, (listConfig) => {
                listConfig.sortColumn = sortColumn
                listConfig.sortDirection = sortDirection as SortDirection
            })
        },
        setYear: (state, action) => {
            const listName = action.payload.listName as string
            const year = action.payload.year as number
            setListConfigValue(state, listName, (listConfig) => {
                listConfig.pageNumber = 1
                listConfig.year = year
            })
        }
    }
})

export default uiSlice.reducer

export const { setPageNumber, setFilter, setSorting, setYear } = uiSlice.actions
