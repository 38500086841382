export enum EConfidenceStatus {
    VERY_HIGH = 'Very High',
    HIGH = 'High',
    LOW = 'Low'
}

// map integer from server to confidence status
export const confidenceMap = new Map<number, EConfidenceStatus>([
    [0, EConfidenceStatus.LOW],
    [1, EConfidenceStatus.HIGH],
    [2, EConfidenceStatus.VERY_HIGH]
])
