import { FC } from 'react'
import { StyledAWVDigitalInput } from '../index.style'
import { Label } from '../Label'

interface IReadOnlyTextFieldProps {
    name: string
    label: string
    subLabel: string
    description: string
    value: any
}

export const ReadOnlyTextField: FC<IReadOnlyTextFieldProps> = ({ name, label, subLabel, description, value }) => (
    <StyledAWVDigitalInput>
        <Label htmlFor={name} label={label} subLabel={subLabel} />
        <input
            type="text"
            id={name}
            name={name}
            naria-label={label}
            readOnly={true}
            disabled={true}
            value={value ?? ''}
        />
        {description && <article>{description}</article>}
    </StyledAWVDigitalInput>
)
