import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledPICContainer = styled.article`
    padding: ${pxToRem(25)} ${pxToRem(30)};

    h2 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(26)};
        line-height: ${pxToRem(31)};
        letter-spacing: -0.01em;
        color: ${dark_gray};
        margin-top: 10px;
    }

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }

    h4 {
        margin-top: 30px;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
        color: ${dark_gray};
    }

    ${DEVICE.desktop} {
        padding: ${pxToRem(95)} ${pxToRem(30)};
    }
`

export const StyledTabTitle = styled.div`
    height: 30px;
    margin-top: 100px;

    h3 {
        margin-right: 16px;
        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: 40px;
            height: 3px;
            background-color: #293854;
        }
    }
`

export const StyledPICDetailsContainer = styled.article`
    width: 100%;

    ${DEVICE.desktop} {
        width: ${pxToRem(1118)};
    }
`

export const StyledPICDocsContainerPlaceHolder1 = styled.article`
    width: 1145px;
    height: 788px;
    background-color: red;
`

export const StyledPICDocsContainerPlaceHolder2 = styled.article`
    width: 1145px;
    height: 788px;
    background-color: blue;
`

export const StyledPICDocsContainerPlaceHolder3 = styled.article`
    width: 1145px;
    height: 788px;
    background-color: green;
`

export const StyledPICUploadAreaContainer = styled.article`
    display: flex;
    flex-direction: column;

    h3 {
        margin-bottom: 0;
    }
`

export const StyledControllersContainer = styled.article`
    width: 1145px;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
`

export const StyledSaveAsDraftButton = styled.button`
    width: 130px;
    height: 50px;
    margin-right: 30px;

    background: #ffffff;
    border: 1px solid #2281c4;
    border-radius: 4px;

    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #2281c4;

    cursor: pointer;
`

export const StyledSubmitButton = styled.button`
    width: 130px;
    height: 50px;

    background: #2281c4;
    border: 1px solid #2281c4;
    border-radius: 4px;

    font-family: 'Inter', 'sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #ffffff;

    cursor: pointer;
`

export const StyledDropzoneContainer = styled.article`
    width: ${pxToRem(1145)};
`
