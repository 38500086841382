import { FC, useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import {
    StyledRadioBtnTxtFieldFormContainer,
    StyledTableFormBody,
    StyledTableFormBodyItem,
    StyledTableFormHeader,
    StyledTableFormInputContainer,
    StyledTableFormItem
} from './index.style'
import {
    ICommentedMultipleChoiceList,
    ICommentedMultipleChoiceListItem,
    IOption,
    ISectionElement
} from '../index.models'
import { performFieldSynchronizationStep } from '../utils'

interface ICommentedMultipleChoiceListProps {
    name: string
    titleColumnLabel: string
    titleColumnWidth: number
    commentsColumnLabel: string
    commentsColumnWidth: number
    options: IOption[]
    items: ICommentedMultipleChoiceListItem[]
}

const availableWidth = 1060

const CommentedMultipleChoiceList: FC<ICommentedMultipleChoiceListProps> = ({
    name,
    titleColumnLabel,
    titleColumnWidth,
    commentsColumnLabel,
    commentsColumnWidth,
    options,
    items
}) => {
    const { register, control } = useFormContext()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    useEffect(() => {
        performFieldSynchronizationStep(items, fields, append, remove, move)
    }, [items, fields, append, remove, move])

    const optionColumnWidth = (availableWidth - titleColumnWidth - commentsColumnWidth) / options.length

    const labelsByName = items.reduce((acc: any, item: any) => {
        acc[item.name] = item.label
        return acc
    }, {})

    return (
        <StyledRadioBtnTxtFieldFormContainer>
            <StyledTableFormHeader>
                <StyledTableFormItem key={`header-${name}-title`} style={{ width: `${titleColumnWidth}px` }}>
                    {titleColumnLabel}
                </StyledTableFormItem>
                {options.map((option: IOption, idx: number) => (
                    <StyledTableFormItem key={`header-${name}-${idx}`} style={{ width: `${optionColumnWidth}px` }}>
                        {option.label}
                    </StyledTableFormItem>
                ))}
                <StyledTableFormItem key={`header-${name}-comments`} style={{ width: `${commentsColumnWidth}px` }}>
                    {commentsColumnLabel}
                </StyledTableFormItem>
            </StyledTableFormHeader>
            {fields.map((field: any, idx: number) => (
                <StyledTableFormBody key={field.id}>
                    <StyledTableFormBodyItem style={{ width: `${titleColumnWidth}px` }}>
                        {labelsByName[field.name]}
                    </StyledTableFormBodyItem>
                    {options.map((option: IOption) => (
                        <StyledTableFormBodyItem
                            key={`option-${field.id}-${option.value}`}
                            style={{ width: `${optionColumnWidth}px` }}
                        >
                            <input {...register(`${name}.${idx}.value`)} type="radio" value={option.value} />
                        </StyledTableFormBodyItem>
                    ))}
                    <StyledTableFormInputContainer style={{ width: `${commentsColumnWidth}px` }}>
                        <input {...register(`${name}.${idx}.comments`)} />
                    </StyledTableFormInputContainer>
                </StyledTableFormBody>
            ))}
        </StyledRadioBtnTxtFieldFormContainer>
    )
}

export const renderCommentedMultipleChoiceList = (element: ISectionElement, key: string) => {
    const commentedMultipleChoiceList = element as ICommentedMultipleChoiceList
    return <CommentedMultipleChoiceList key={key} {...commentedMultipleChoiceList} />
}
