import { createSlice } from '@reduxjs/toolkit'
import { ISecurityUser } from 'pages/hcp/users/create/index.models'
import { appInsights } from 'services/appInsightService'
import axios from 'utilities/axios'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'

export interface INewProviderReportCount {
    npi: string
    userId: string
    count: number
}
export type SystemState = {
    currentUser: ISecurityUser
    authenticatedUser: ISecurityUser
    error: any
}

const initialState: SystemState = {
    currentUser: {
        sid: '',
        userId: '',
        isImpersonated: false,
        emailAddress: '',
        firstName: '',
        lastName: '',
        fullName: '',
        primaryRole: 0
    },
    authenticatedUser: {
        sid: '',
        userId: '',
        isImpersonated: false,
        emailAddress: '',
        firstName: '',
        lastName: '',
        fullName: '',
        primaryRole: 0
    },
    error: null
}

export const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        resetSystem: (state) => {
            state.currentUser = {
                sid: '',
                userId: '',
                isImpersonated: false,
                emailAddress: '',
                firstName: '',
                lastName: '',
                fullName: '',
                primaryRole: 0
            }
            state.authenticatedUser = {
                sid: '',
                userId: '',
                isImpersonated: false,
                emailAddress: '',
                firstName: '',
                lastName: '',
                fullName: '',
                primaryRole: 0
            }
            state.error = null
        },
        logError: (state, action) => {
            if (action.payload.showModal) {
                state.error = action.payload.modalErrorMessage ?? action.payload.error
            }
            appInsights.trackEvent(
                {
                    name: 'ClientApplicationException'
                },
                {
                    error: action.payload.error,
                    responseData: action.payload.responseData,
                    user: state.currentUser,
                    authenticatedUser: state.authenticatedUser
                }
            )
        },
        clearError: (state) => {
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCurrentUser.fulfilled, (state, action) => {
                state.currentUser = action.payload
            })
            .addCase(getAuthenticatedUser.fulfilled, (state, action) => {
                state.authenticatedUser = action.payload
            })
    }
})

export const { resetSystem, logError, clearError } = systemSlice.actions
export default systemSlice.reducer

export const getCurrentUser = createExceptionAwareAsyncThunk('user/getCurrentUser', async () => {
    const response = await axios.post('api/Security/GetCurrentUser')
    return response.data
})
export const getAuthenticatedUser = createExceptionAwareAsyncThunk('user/getAuthenticatedUser', async () => {
    const response = await axios.post('api/Security/GetAuthenticatedUser')
    return response.data
})
