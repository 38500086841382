import styled from 'styled-components'
import { pxToRem } from 'utilities'

export const StyledFormCheckBoxListContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(30)};

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        color: #293854;
    }
`

export const StyledRadioList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    background: #ffffff;
    border-radius: 4px;
    padding: 30px 20px;
`
export const StyledRadioListItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    :nth-last-child(1) {
        margin-bottom: 0;
    }

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
    }
    input {
        padding: 0;
        margin: 0;
        margin-right: 10px;
    }
`
