import { FC } from 'react'
import {
    StyledContainer,
    StyledCopyRightContainer,
    StyledImgContainer,
    StyledPainAssesmentContainer,
    StyledRadioBtnListContainer,
    StyledRadioBtnListItem
} from './index.style'
import { Controller, useFormContext } from 'react-hook-form'
import { IPainAssessmentSelection, ISectionElement } from '../index.models'
import { Label } from '../Label'

interface IPainAssessmentSelectionProps {
    name: string
    label: string
    subLabel: string
}

const painAssessmentValues = [0, 2, 4, 6, 8, 10]

const PainAssessmentSelection: FC<IPainAssessmentSelectionProps> = ({ name, label, subLabel }) => {
    const { setValue, control } = useFormContext()

    return (
        <StyledContainer>
            <Label htmlFor={name} label={label} subLabel={subLabel} wrapInParagraph={true} />
            <StyledPainAssesmentContainer>
                <StyledImgContainer></StyledImgContainer>
                <StyledRadioBtnListContainer>
                    {painAssessmentValues.map((value: number, idx: number) => {
                        return (
                            <StyledRadioBtnListItem key={`painValue-${idx}`}>
                                <Controller
                                    key={`${name}-${idx}`}
                                    name={name}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            id={name}
                                            type="radio"
                                            value={value}
                                            checked={field.value === value}
                                            onChange={(e) => setValue(name, Number(e.target.value).valueOf())}
                                        />
                                    )}
                                />
                            </StyledRadioBtnListItem>
                        )
                    })}
                </StyledRadioBtnListContainer>
                <StyledCopyRightContainer>
                    <p>Copyright 1983, Wong-Baker FACES Foundation, www.WongBakerFACES.org. Used with permission.</p>
                </StyledCopyRightContainer>
            </StyledPainAssesmentContainer>
        </StyledContainer>
    )
}

export const renderPainAssessmentSelection = (element: ISectionElement, key: string) => {
    const painAssessmentSelection = element as IPainAssessmentSelection
    return <PainAssessmentSelection key={key} {...painAssessmentSelection} />
}
