import DropDownList from 'components/DropDownList'
import { FC } from 'react'
import { IProviderNpi } from 'redux/slices/pulse8'
import { npiDropDownListStyleParams } from './style'
import { INewProviderReportCount } from 'redux/slices/system'

interface INpiSelectProps {
    providerNpis: IProviderNpi[]
    newProviderReportCounts: INewProviderReportCount[]
    selectedNpi: string
    setSelectedNpi: (value: string) => void
}

const ProviderDropdown: FC<INpiSelectProps> = ({
    providerNpis,
    newProviderReportCounts,
    selectedNpi,
    setSelectedNpi
}) => {
    const getLabel = (npi: IProviderNpi): string => {
        const newIndicator: string = (newProviderReportCounts ?? []).some((x) => x.npi === npi.npi && x.count > 0)
            ? '* '
            : ''
        return `${newIndicator}${npi.lastName}, ${npi.firstName} -  ${npi.npi} `
    }

    const changeNpi = (npi: string) => {
        if (selectedNpi !== npi) {
            setSelectedNpi(npi)
        }
    }

    return (
        <div>
            <DropDownList
                options={providerNpis.map((npi) => ({
                    value: npi.npi,
                    label: getLabel(npi)
                }))}
                selectedValue={selectedNpi}
                setSelectedValue={changeNpi}
                styleParams={npiDropDownListStyleParams}
            />
        </div>
    )
}

export default ProviderDropdown
