import { IDropDownListStyleParams } from 'components/DropDownList/index.style'
import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { light_gray2, light_gray3, light_gray4, dark_gray, light_blue2, light_blue3 } = UI_COLORS

const tableReset = css`
    list-style: none;
    padding: 0;
    display: flex;
`

export const StyledAWVFormsListContainer = styled.article`
    width: ${pxToRem(1145)};

    /* height: ${pxToRem(715)}; */
    height: auto;

    background-color: white;
    margin-bottom: ${pxToRem(30)};
`

export const StyledAWVFormsListTableHeader = styled.ul`
    ${tableReset}
    width: 100%;
    background-color: #ebeff7;
    border-bottom: solid #dee5ef;
    height: ${pxToRem(40)};
    flex-direction: row;
    align-items: center;
    padding: 0 ${pxToRem(24)};
    margin: 0;
`

export const StyledAWVFormsListTableHeaderItem = styled.li<{ active?: boolean }>`
    color: ${light_gray3};

    font-family: 'Inter', sans-serif;
    font-style: normal;

    font-size: 12px;
    font-weight: 600;
    line-height: 130%;

    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;

    :nth-child(1) {
        width: ${pxToRem(160)};
        /* background-color: red; */
    }

    :nth-child(2) {
        width: ${pxToRem(50)};
        /* background-color: teal; */
    }

    :nth-child(3) {
        width: ${pxToRem(190)};
        /* background-color: blue; */
    }

    :nth-child(4) {
        width: ${pxToRem(120)};
        /* background-color: green; */
    }

    :nth-child(5) {
        width: ${pxToRem(100)};
        /* background-color: yellow; */
    }

    :nth-child(6) {
        width: ${pxToRem(130)};
        /* background-color: pink; */
    }

    :nth-child(7) {
        width: ${pxToRem(160)};
        /* background-color: purple; */
    }

    :nth-child(8) {
        width: ${pxToRem(116)};
        /* background-color: orange; */
    }
    :nth-child(9) {
        width: ${pxToRem(70)};
        /* background-color: #00ffea; */
    }

    :hover {
        cursor: pointer;
    }

    figure {
        margin: 0;
        ${(props) => {
            if (props.active) {
                return css`
                    transform: rotate(180deg) translateY(10%);
                `
            }
        }}
    }
`

export const StyledAWVFormsListBody = styled.ul`
    ${tableReset}
    width: 100%;
    margin: 0;

    background-color: #ffffff;
    border-bottom: solid #dee5ef;
    height: ${pxToRem(90)};
    flex-direction: row;
    align-items: center;
    padding: 0 ${pxToRem(24)};
`

export const StyledAWVFormsListBodyItem = styled.li<{ isBusy?: boolean }>`
    overflow: hidden;
    color: #293854;
    text-overflow: ellipsis;
    font-family: 'Inter', sans-serif;
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */

    :nth-child(1) {
        /* background-color: red; */
        width: ${pxToRem(160)};
        ${(props) => {
            if (!props.isBusy) {
                return css`
                    color: #2281c4;
                    cursor: pointer;
                `
            } else {
                return css`
                    color: ${light_gray3};
                    cursor: default;
                `
            }
        }}
    }

    :nth-child(2) {
        width: ${pxToRem(50)};
        /* background-color: teal; */
    }

    :nth-child(3) {
        width: ${pxToRem(190)};
        /* background-color: blue; */
    }

    :nth-child(4) {
        width: ${pxToRem(120)};
        /* background-color: green; */
    }

    :nth-child(5) {
        width: ${pxToRem(100)};
        /* background-color: yellow; */
    }

    :nth-child(6) {
        width: ${pxToRem(130)};
        /* background-color: pink; */
    }

    :nth-child(7) {
        width: ${pxToRem(160)};
        /* background-color: purple; */
    }

    :nth-child(8) {
        width: ${pxToRem(116)};
        /* background-color: orange; */
    }
    :nth-child(9) {
        width: ${pxToRem(70)};
        /* background-color: #00ffea; */
    }
`

export const StyledAWVFeedbackButton = styled.button`
    background-color: transparent;
    border: 0;
    cursor: pointer;

    figure {
        margin: 0;
        padding: 0;
        margin-left: 15px;
    }
`

export const StyledAWVStatusDropdownContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        margin-right: 16px;
    }

    div {
        div {
            div {
                outline: none;
            }
        }
    }
`

export const awvStatusDropDownListStyleParams: IDropDownListStyleParams = {
    width: 140,
    color: '#394A64',
    fontSize: '14px',
    singleValueColor: '#394A64',
    singleValueFontWeight: '600',
    paddingTop: '12px',
    paddingLeft: '0px !important',
    marginTop: '0px',
    marginLeft: '0px',
    indicatorMarginTop: '-40px',
    placeholderTop: '0px !important',
    singleValueMarginTop: '14px',
    singleValueMarginLeft: '10px'
}

export const StyledAssignButton = styled.button`
    border: 0;
    border-radius: 4px;
    background: #2281c4;
    width: ${pxToRem(150)};
    height: ${pxToRem(50)};

    color: #ffffff;
    font-family: 'Inter', sans-serif;
    font-size: ${pxToRem(16)};
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 22.4px */

    cursor: pointer;
`

export const StyledCodingActionsContainer = styled.article`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const StyledCodingActionBtn = styled.button<{ hasNewFeedback?: boolean }>`
    margin-left: ${pxToRem(5)};
    width: ${pxToRem(25)};
    height: ${pxToRem(25)};
    padding: 0;
    background-color: transparent;
    border: none;
    position: relative;

    figure {
        margin: 0;
    }

    cursor: pointer;

    div {
        display: none;
    }
    ${(props) => {
        if (props.hasNewFeedback) {
            return css`
                div {
                    display: block;
                    position: absolute;
                    top: 7px;
                    right: 12px;
                }
            `
        }
    }}
`

export const StyledCodingButtonPlaceholder = styled.article`
    margin-left: ${pxToRem(5)};
    width: ${pxToRem(25)};
    height: ${pxToRem(25)};
`
