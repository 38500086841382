import { FC } from 'react'
import { StyledInlineQuestionContainer, StyledInlineQuestionRow, StyledInlineQuestionRowItem } from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { Controller, useFormContext } from 'react-hook-form'
import { IRadioBooleanList, IRadioBooleanListItem, ISectionElement } from '../index.models'
import { Label } from '../Label'
import { evaluateCondition } from '../ConditionEvaluator'

interface IRadioBooleanListProps {
    name: string
    label: string
    trueLabel: string
    falseLabel: string
    items: IRadioBooleanListItem[]
}

const RadioBooleanList: FC<IRadioBooleanListProps> = ({ name, label, trueLabel, falseLabel, items }) => {
    const { control, setValue, watch } = useFormContext()

    return (
        <StyledInlineQuestionContainer>
            {label && <Typography type={TYPOGRAPHY_TYPES.p}>{label}</Typography>}
            <div>
                {items.map(
                    (item, index) =>
                        evaluateCondition(item.condition, watch) && (
                            <StyledInlineQuestionRow key={`${name}-${index}`}>
                                <StyledInlineQuestionRowItem>
                                    <Label label={item.label} subLabel={item.subLabel} wrapInParagraph={true} />
                                </StyledInlineQuestionRowItem>
                                <StyledInlineQuestionRowItem>
                                    <label>
                                        <Controller
                                            name={item.name}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id={item.name}
                                                    {...field}
                                                    type="radio"
                                                    value={'True'}
                                                    checked={field.value === true}
                                                    onChange={() => setValue(item.name, true)}
                                                />
                                            )}
                                        />
                                        {item.trueLabel ?? trueLabel ?? 'Yes'}
                                    </label>
                                </StyledInlineQuestionRowItem>
                                <StyledInlineQuestionRowItem>
                                    <label>
                                        <Controller
                                            name={item.name}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id={item.name}
                                                    {...field}
                                                    type="radio"
                                                    value={'False'}
                                                    checked={field.value === false}
                                                    onChange={() => setValue(item.name, false)}
                                                />
                                            )}
                                        />
                                        {item.falseLabel ?? falseLabel ?? 'No'}
                                    </label>
                                </StyledInlineQuestionRowItem>
                            </StyledInlineQuestionRow>
                        )
                )}
            </div>
        </StyledInlineQuestionContainer>
    )
}

export const renderRadioBooleanList = (element: ISectionElement, key: string) => {
    const radioBooleanList = element as IRadioBooleanList
    return <RadioBooleanList key={key} name={key} {...radioBooleanList} />
}
