import { FC } from 'react'
import { InputField } from '../InputField'
import { ITextField, ISectionElement } from '../index.models'

interface ITextFieldProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
}

const TextField: FC<ITextFieldProps> = ({ name, label, subLabel, placeholder }) => (
    <InputField type="text" name={name} label={label} subLabel={subLabel} placeholder={placeholder ?? label} />
)

export const renderTextField = (element: ISectionElement, key: string) => {
    const textField = element as ITextField
    return <TextField key={key} {...textField} />
}
