import styled from 'styled-components'
import { pxToRem } from 'utilities'

export const StyledTableQuestionnaireContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(30)};
`

export const StyledTableQuestionnaireHeader = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    background-color: #f7f8fa;
    border: 1px solid #dee5ef;

    padding: 26px 30px;

    display: flex;
    flex-direction: row;
`
export const StyledTableQuestionnaireHeaderItem = styled.li`
    display: table;
    flex-direction: row;

    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        margin: 0;

        color: #73839c;

        display: table-cell;
        vertical-align: middle;
    }
`

export const StyledTableQuestionnaireBody = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    background: #ffffff;
    border-bottom: 1px solid #dee5ef;
    border-left: 1px solid #dee5ef;
    border-right: 1px solid #dee5ef;

    padding: 0 30px;
    height: 100px;
`

export const StyledTableQuestionnaireBodyItem = styled.li`
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        color: #293854;
    }
`

export const StyledTableQuestionnaireBodyItemTotals = styled.li`
    input {
        height: 50px;
        outline: none;
        padding-left: 10px;
        border: 1px solid #73839c;
        border-radius: 4px;
    }

    :nth-child(1) {
        width: ${pxToRem(325)};
        margin-right: ${pxToRem(105)};
    }

    :nth-child(2) {
        margin-right: ${pxToRem(40)};

        input {
            width: 80px;
        }
    }

    :nth-child(3) {
        input {
            width: 320px;
        }
    }
`
