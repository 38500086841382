import { FC, useEffect, useRef, useState } from 'react'
import {
    StyledChatList,
    StyledChatListItemLeft,
    StyledChatListItemRight,
    StyledChatListItemContainer,
    StyledFeedbackButtonContainer,
    StyledFeedbackContainer,
    StyledFeedbackTitleContainer,
    StyledFeedbackUserLeft,
    StyledFeedbackUserRight,
    StyledFeedbackTextSeparator,
    StyledFeedbackTextEntry,
    StyledFeedbackDateEntryRight,
    StyledFeedbackDateEntryLeft,
    StyledEmptyStateContainer
} from './index.style'
import Modal from 'components/Modal'
import { PointsInCareState, retrieveQualityFeedback } from 'redux/slices/pointsInCare'
import { dispatch, useSelector } from 'redux/store'
import { IQualityFeedbackRequest } from 'pages/hcp/users/create/index.models'
import { StyledButtonContainer, StyledReferralTitleContainer } from '../ReferralModalDialog/index.style'
import { StyledCancelButton } from '../../../AWV/index.style'
import { StyledConfirmButton } from 'components/ConfirmationBox/index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import AdjustableModal from 'components/AdjustableModal/AdjustableModal'
import { setFormatedDate, setFormattedDateWithSlash } from 'components/UsersTable/index.utils'
import useMemberInfoFromUrl from 'hooks/useMemberInfoFromUrl'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { emptyFeedback } from './icons'
import { useChatScroll } from 'hooks/useChatScroll'

interface IFeedbackProps {
    request: IQualityFeedbackRequest
    onCancel: () => void
    onSave: (feedback: string) => void
}

export const FeedbackModalDialog: FC<IFeedbackProps> = ({ request, onCancel, onSave }) => {
    const addFeedbackControlRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false)
    const [feedbackFromUser, setFeedbackFromUser] = useState<string>('')

    const [memberId, npi] = useMemberInfoFromUrl()

    const currentFeedbackForMember = useSelector(
        (state: { pointsInCare: PointsInCareState }) => state.pointsInCare.currentQualityFeedback
    )

    const ref = useChatScroll(currentFeedbackForMember?.feedbacks)

    useEffect(() => {
        if (addFeedbackControlRef?.current) {
            addFeedbackControlRef.current.focus()
        }
        dispatch(retrieveQualityFeedback(request))
    }, [])

    return (
        <AdjustableModal onClose={onCancel} minHeight={650} minWidth={700}>
            <StyledFeedbackContainer>
                <StyledFeedbackTitleContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h2}>Feedback </Typography>
                    <Typography type={TYPOGRAPHY_TYPES.h2}>
                        {currentFeedbackForMember?.title ? `(${currentFeedbackForMember?.title})` : ''}
                    </Typography>
                </StyledFeedbackTitleContainer>

                {currentFeedbackForMember?.feedbacks.length <= 0 ? (
                    <StyledEmptyStateContainer>
                        <Typography type={TYPOGRAPHY_TYPES.h3}>No Feedback Provided</Typography>
                        <Typography type={TYPOGRAPHY_TYPES.p}>
                            Once the feedback is added, it will show up here.
                        </Typography>
                        <CustomSvgIcon iconSet={{ icon: emptyFeedback }} svg></CustomSvgIcon>
                    </StyledEmptyStateContainer>
                ) : (
                    <StyledChatListItemContainer ref={ref}>
                        <StyledChatList>
                            {currentFeedbackForMember &&
                                currentFeedbackForMember.feedbacks.map((feedback, index) => {
                                    return feedback.isProvider ? (
                                        <div>
                                            <StyledChatListItemLeft key={index}>
                                                {feedback.feedback}
                                            </StyledChatListItemLeft>
                                            <StyledFeedbackUserLeft>
                                                <div>
                                                    {`${feedback.feedbackByFirstName} ${feedback.feedbackByLastName} - ${feedback.feedbackByRole}`}
                                                </div>
                                                <StyledFeedbackDateEntryLeft>
                                                    {`${setFormattedDateWithSlash(feedback.feedbackDate)}`}
                                                </StyledFeedbackDateEntryLeft>
                                            </StyledFeedbackUserLeft>
                                        </div>
                                    ) : (
                                        <div>
                                            <StyledChatListItemRight key={index}>
                                                {feedback.feedback}
                                            </StyledChatListItemRight>
                                            <StyledFeedbackUserRight>
                                                <div>
                                                    {`${feedback.feedbackByFirstName} ${feedback.feedbackByLastName} - ${feedback.feedbackByRole}`}
                                                </div>
                                                <StyledFeedbackDateEntryRight>
                                                    {`${setFormattedDateWithSlash(feedback.feedbackDate)}`}
                                                </StyledFeedbackDateEntryRight>
                                            </StyledFeedbackUserRight>
                                        </div>
                                    )
                                })}
                        </StyledChatList>
                    </StyledChatListItemContainer>
                )}

                <StyledFeedbackTextSeparator />
                <StyledFeedbackTextEntry
                    ref={addFeedbackControlRef}
                    value={feedbackFromUser}
                    onChange={(e) => setFeedbackFromUser(e.target.value)}
                    placeholder="Enter your comments here..."
                >
                    {feedbackFromUser}
                </StyledFeedbackTextEntry>
                <StyledFeedbackButtonContainer>
                    <StyledCancelButton onClick={onCancel}>Cancel</StyledCancelButton>
                    <StyledConfirmButton onClick={() => onSave(feedbackFromUser)} color={'#2281C4'}>
                        Save
                    </StyledConfirmButton>
                </StyledFeedbackButtonContainer>
            </StyledFeedbackContainer>
        </AdjustableModal>
    )
}
