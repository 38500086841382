import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'
import faces from './faces.jpg'

const { white, dark_gray } = UI_COLORS

export const StyledContainer = styled.article`
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${dark_gray};
    }
`

export const StyledPainAssesmentContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(30)};
    padding: 19px 20px;

    background-color: ${white};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const StyledImgContainer = styled.article`
    width: ${pxToRem(659)};
    height: ${pxToRem(243)};
    background-image: url(${faces});
    background-repeat: no-repeat;
    background-size: contain;
`

export const StyledRadioBtnListContainer = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    width: ${pxToRem(659)};
    display: flex;
    flex-direction: row;

    padding: 0 40px;
`

export const StyledRadioBtnListItem = styled.li`
    margin-right: 90px;
`

export const StyledCopyRightContainer = styled.article`
    margin-top: 16px;
    width: 200px;
    p {
        color: #134a80;
        font-size: 8px;
        margin: 0;
        line-height: 10px;
    }
`
