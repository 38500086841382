import { IDropDownListStyleParams } from 'components/DropDownList/index.style'
import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { DEVICE, pxToRem } from 'utilities'

const { light_gray2, light_gray3, light_gray4, dark_gray, light_blue2, light_blue3 } = UI_COLORS

export const StyledSpinnerContainer = styled.article`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
`

export const StyledCodingAWVFormsContainer = styled.article`
    padding-top: ${pxToRem(20)};
    padding-left: ${pxToRem(30)};
    max-width: 1205px;
    margin: 0 auto;
`

export const StyledAWVFormsHeaderContainer = styled.article`
    display: flex;
    flex-direction: row;
    padding-top: ${pxToRem(0)};
    padding-right: ${pxToRem(30)};
    padding-bottom: ${pxToRem(25)};

    p {
        flex-grow: 1;
        margin: ${pxToRem(5)} 0 0 0;

        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(0)};
        letter-spacing: -0.01em;
        color: ${dark_gray};

        display: flex;
        align-items: center;

        ${DEVICE.tablet} {
            font-size: ${pxToRem(26)};
            line-height: ${pxToRem(31)};
        }
    }
`

export const StyledAWVFormsScreenHeader = styled.article`
    position: relative;
    height: ${pxToRem(30)};
    h2 {
        margin: 0;
        margin-right: ${pxToRem(16)};
        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: ${pxToRem(40)};
            height: ${pxToRem(3)};
            background-color: #293854;
        }
    }
`

export const StyledAWVFormsActionsContainer = styled.article`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: ${pxToRem(30)};
    padding-right: ${pxToRem(30)};

    ${DEVICE.tablet} {
        flex-direction: row;
        padding-right: 0;
        height: ${pxToRem(70)};
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(1145)};
    }
`

export const StyledSearchBarContainer = styled.article`
    width: 100%;
    height: ${pxToRem(50)};
    margin-bottom: ${pxToRem(16)};

    ${DEVICE.tablet} {
        width: 50%;
        margin-bottom: ${pxToRem(0)};
    }
`

const dropDownGeneralStyles = {
    color: '#394A64',
    fontSize: '14px',
    singleValueColor: '#394A64',
    singleValueFontWeight: '600',
    paddingTop: '9px',
    paddingLeft: '20px !important',
    marginTop: '0px',
    marginLeft: '16px',
    indicatorMarginTop: '-40px',
    placeholderTop: '0px !important',
    singleValueMarginTop: '10px',
    singleValueMarginLeft: '0px',
    height: 40
}

export const awvFormsDropDownListStyleParams: IDropDownListStyleParams = {
    ...dropDownGeneralStyles,
    width: 160
}

export const awvFormsDropDownListStyleParams2: IDropDownListStyleParams = {
    ...dropDownGeneralStyles,
    width: 175
}

export const awvFormsDropDownListStyleParams3: IDropDownListStyleParams = {
    ...dropDownGeneralStyles,
    width: 120
}

export const StyledDropDownWrap = styled.article`
    cursor: pointer;
    position: relative;
    div {
        div {
            div {
                outline: none;
            }
        }
    }
    figure {
        position: absolute;
        margin: 0;
        padding: 0;
        top: 8px;
        left: 20px;
        z-index: 2;

        svg {
            width: 24px;
            height: 24px;
        }
    }
`

export const StyledEmptyState = styled.article`
    width: 100%;
    height: ${pxToRem(250)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
        margin: 0;
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(22)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: #293854;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};

        color: #73839c;
    }
`
