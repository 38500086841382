import Modal from 'components/Modal'
import { FC, useEffect, useState } from 'react'
import {
    StyledActionsContainer,
    StyledButtonsContainer,
    StyledCalendarContainer,
    StyledCalendarWrapper,
    StyledCancelButton,
    StyledCurrentScheduledDOSContainer,
    StyledSaveButton,
    StyledScheduleModalItemsContainer,
    StyledUnscheduleButton,
    StyledUnscheduleButtonTooltip
} from './index.style'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { IMemberProfileScheduleAwvData, IMemberProfileUnscheduleAwvData } from 'pages/hcp/users/create/index.models'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import * as yup from 'yup'
import { Controller, FieldValues, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import UnscheduleAwvConfirmationBox from 'components/UnscheduleAwvConfirmationBox'

dayjs.extend(utc)

const notInThePastCondition = yup
    .date()
    .nullable()
    .required('Please select or enter a valid date')
    .test('is-not-past', 'Scheduled AWV date of service cannot be in the past', (value) => {
        return !value || value.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
    })

const notInTheCurrentYearCondition = notInThePastCondition.test(
    'is-not-in-current-year',
    'Scheduled AWV date of service cannot be in the current year',
    (value) => {
        return !value || value.getFullYear() !== new Date().getFullYear()
    }
)

const awvScheduleNotInThePastSchema = yup.object().shape({
    scheduledDOS: notInThePastCondition
})

const awvScheduleNotInTheCurrentYearSchema = yup.object().shape({
    scheduledDOS: notInTheCurrentYearCondition
})

interface IScheduleAWVModalProps {
    npi: string
    memberId: number
    scheduledDOS: string | undefined | null
    onCancel: () => void
    onSaveDate: (args: IMemberProfileScheduleAwvData) => void
    onUnschedule: (args: IMemberProfileUnscheduleAwvData) => void
    awvStatus: string | undefined | null
}

const ScheduleAWVModal: FC<IScheduleAWVModalProps> = ({
    npi,
    memberId,
    scheduledDOS,
    onCancel,
    onSaveDate,
    onUnschedule,
    awvStatus
}) => {
    const {
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        reset
    } = useForm({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(
            ['Not Started', 'Scheduled'].includes(awvStatus)
                ? awvScheduleNotInThePastSchema
                : awvScheduleNotInTheCurrentYearSchema
        )
    })

    const [isUnscheduleAwvConfirmationBoxShown, setIsUnscheduleAwvConfirmationBoxShown] = useState(false)
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)

    const getIsSaveButtonDisabled = (selectedDate: Dayjs) =>
        scheduledDOS && dayjs(scheduledDOS).isSame(selectedDate, 'day')

    useEffect(() => {
        const selectedDate = scheduledDOS
            ? dayjs(scheduledDOS)
            : !['Not Started', 'Scheduled'].includes(awvStatus)
            ? dayjs(new Date().getFullYear() + 1 + '-01-01T00:00:00')
            : dayjs()
        setValue('scheduledDOS', selectedDate)
        setIsSaveButtonDisabled(getIsSaveButtonDisabled(selectedDate))
    }, [scheduledDOS])

    const onSubmit = (data: FieldValues) => {
        const scheduleAwvData: IMemberProfileScheduleAwvData = {
            npi: npi,
            memberId: memberId,
            scheduledDOS: dayjs(data.scheduledDOS).utc(true).toJSON()
        }
        onSaveDate(scheduleAwvData)
    }

    return isUnscheduleAwvConfirmationBoxShown ? (
        <UnscheduleAwvConfirmationBox
            onConfirm={() => onUnschedule({ npi: npi, memberId: memberId })}
            onCancel={() => setIsUnscheduleAwvConfirmationBoxShown(false)}
        />
    ) : (
        <Modal minHeight={250} minWidth={500} onClose={onCancel}>
            <StyledScheduleModalItemsContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>Select Date</Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <StyledCalendarContainer>
                        <StyledCalendarWrapper>
                            <Controller
                                name="scheduledDOS"
                                control={control}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            {...field}
                                            onChange={(newValue) => {
                                                setIsSaveButtonDisabled(getIsSaveButtonDisabled(newValue))
                                                setValue('scheduledDOS', newValue && !isNaN(newValue) ? newValue : null)
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} error={errors?.scheduledDOS ? true : false} />
                                            )}
                                            components={{
                                                OpenPickerIcon: CalendarMonthIcon
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                        </StyledCalendarWrapper>
                        {errors?.scheduledDOS && (
                            <Typography type={TYPOGRAPHY_TYPES.p}>{errors.scheduledDOS.message}</Typography>
                        )}
                    </StyledCalendarContainer>
                    {scheduledDOS && (
                        <StyledCurrentScheduledDOSContainer>
                            <Typography type={TYPOGRAPHY_TYPES.p}>
                                Currently scheduled for: {dayjs(scheduledDOS).format('MM/DD/YYYY')}
                            </Typography>
                            <StyledUnscheduleButton
                                type="button"
                                onClick={() => setIsUnscheduleAwvConfirmationBoxShown(true)}
                            >
                                <img src={require('assets/delete-2.svg').default} alt="Unschedule" />
                                <StyledUnscheduleButtonTooltip>Unschedule</StyledUnscheduleButtonTooltip>
                            </StyledUnscheduleButton>
                        </StyledCurrentScheduledDOSContainer>
                    )}
                    <StyledActionsContainer>
                        <StyledButtonsContainer>
                            <StyledCancelButton
                                type="button"
                                onClick={() => {
                                    reset()
                                    onCancel()
                                }}
                            >
                                Cancel
                            </StyledCancelButton>
                            <StyledSaveButton type="submit" disabled={isSaveButtonDisabled}>
                                Save
                            </StyledSaveButton>
                        </StyledButtonsContainer>
                    </StyledActionsContainer>
                </form>
            </StyledScheduleModalItemsContainer>
        </Modal>
    )
}

export default ScheduleAWVModal
