import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities/style'
import { UI_COLORS } from 'theme'

const { light_blue2, red } = UI_COLORS

export const StyledIcdDiagnosisPickerContainer = styled.article<{ isInError?: boolean }>`
    width: 100%;

    div {
        margin: 0 !important;
        padding: 0 !important;
    }

    input {
        padding: 0 ${pxToRem(64)} 0 ${pxToRem(18)} !important;
        margin: 0 !important;
        height: ${pxToRem(48)};

        ${(props) => {
            if (props.isInError) {
                return css`
                    outline: solid 1px ${red} !important;
                `
            } else {
                return css`
                    :focus {
                        border: 2px solid ${light_blue2};
                        outline: none;
                    }
                `
            }
        }}
    }
`
