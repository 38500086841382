import { appPathForRoutes } from 'utilities/appPathForRoutes'

export const PATH_PAGE = {
    root: appPathForRoutes('/'),
    admins: appPathForRoutes('/admins'),
    admin: appPathForRoutes('/admin'),
    dashboard: appPathForRoutes('/dashboard'),
    memberOutreach: appPathForRoutes('/memberOutreach'),
    pulse8SpecificNpi: appPathForRoutes('/pulse8SingleSignOn/:npi'),
    pulse8: appPathForRoutes('/pulse8SingleSignOn'),
    user: {
        create: appPathForRoutes('/users/create'),
        list: appPathForRoutes('/users'),
        view: appPathForRoutes('/users/:userId'),
        edit: appPathForRoutes('/users/edit/:userId'),
        reports: appPathForRoutes('/users/reports/:userId')
    },
    registration: appPathForRoutes('/registration/:secureToken'),
    member: {
        list: appPathForRoutes('/members'),
        memberAwvInformation: appPathForRoutes('/members/memberInformation/awvFile/:memberId'),
        memberInformation: appPathForRoutes('/members/memberInformation/:memberId'),
        callCenterAdmin: appPathForRoutes('/callCenterAdminMembers'),
        electronicAnnualWellnesVisitForm: appPathForRoutes(
            '/members/electronicAnnualWellnessVisitForm/:memberId/:npi/:year'
        ),
        viewElectronicAnnualWellnesVisitForm: appPathForRoutes(
            '/members/viewElectronicAnnualWellnessVisitForm/:memberId/:npi/:year'
        )
    },
    awvForms: appPathForRoutes('/awvforms'),
    providerReports: appPathForRoutes('/providerreports')
}
