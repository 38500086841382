import { setFormattedDateTimeWithSlash, setFormattedDateWithSlash } from 'components/UsersTable/index.utils'

const extractStatusEffectiveDateValue = (awvSummary: any) => setFormattedDateTimeWithSlash(awvSummary.statusEffective)

const extractScheduledEffectiveDateValue = (awvSummary: any) => setFormattedDateWithSlash(awvSummary.scheduledFor, true)

const extractSavedEffectiveDateValue = (awvSummary: any) => setFormattedDateTimeWithSlash(awvSummary.statusEffective)

const statusLabelOverrides: any = {
    Scheduled: {
        effectiveDateLabel: 'Scheduled for',
        extractEffectiveDateValue: extractScheduledEffectiveDateValue
    },
    Saved: {
        effectiveDateLabel: 'Last Saved on',
        extractEffectiveDateValue: extractSavedEffectiveDateValue
    },
    Submitted: {
        effectiveDateLabel: 'Submitted on',
        extractEffectiveDateValue: extractStatusEffectiveDateValue
    },
    InReview: {
        statusLabel: 'In Review',
        effectiveDateLabel: 'In Review on',
        extractEffectiveDateValue: extractStatusEffectiveDateValue
    },
    Approved: {
        effectiveDateLabel: 'Approved on',
        extractEffectiveDateValue: extractStatusEffectiveDateValue
    },
    Pending: {
        effectiveDateLabel: 'Pending on',
        extractEffectiveDateValue: extractStatusEffectiveDateValue
    }
}

const typeLabels: any = {
    scheduled: '',
    document: 'PDF',
    electronic: 'Electronic'
}

export const getAwvSummaryLabels = (awvSummary: any) => {
    const labelOverrides = awvSummary ? statusLabelOverrides[awvSummary.status] : null
    const statusLabel = awvSummary ? labelOverrides?.statusLabel ?? awvSummary?.status : 'Not Started'
    const effectiveDateLabel = labelOverrides?.effectiveDateLabel ?? statusLabel
    const effectiveDateValue = labelOverrides?.extractEffectiveDateValue
        ? labelOverrides.extractEffectiveDateValue(awvSummary)
        : null
    return {
        statusLabel: statusLabel as string,
        effectiveDateLabel: effectiveDateLabel as string,
        effectiveDateValue: effectiveDateValue as string,
        typeLabel: typeLabels[awvSummary?.$type] ?? ''
    }
}
