import CustomMaskedInput from 'components/CustomMaskInput'
import { FC } from 'react'
import { StyledAWVDigitalInput } from '../index.style'
import { Controller, useFormContext } from 'react-hook-form'
import { IPhoneNumberField, ISectionElement } from '../index.models'
import { Label } from '../Label'

interface IPhoneNumberFieldProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
}

const PhoneNumberField: FC<IPhoneNumberFieldProps> = ({ name, label, subLabel, placeholder }) => {
    const {
        formState: { errors },
        control
    } = useFormContext()

    return (
        <StyledAWVDigitalInput>
            <Label htmlFor={name} label={label} subLabel={subLabel} />
            <Controller
                render={({ field }) => (
                    <CustomMaskedInput {...field} placeholder={placeholder ?? label} width="400px" />
                )}
                name={name}
                control={control}
            />
            {errors?.[name] && <small>{errors?.[name].message}</small>}
        </StyledAWVDigitalInput>
    )
}

export const renderPhoneNumberField = (element: ISectionElement, key: string) => {
    const phoneNumberField = element as IPhoneNumberField
    return <PhoneNumberField key={key} {...phoneNumberField} />
}
