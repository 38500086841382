import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'
import {
    ConditionFeedbackStatus,
    IAddReviewFeedbackRequest,
    IDeletePointsInCareDocument,
    IDownloadGapFormRequest,
    IPointsInCareReview,
    IReviewFeedbackRequest,
    ISaveTreatmentPlanRequest
} from 'pages/hcp/users/create/index.models'
import { FC, useEffect, useState } from 'react'
import {
    addReviewFeedback,
    cacheProviderReviewFeedback,
    deleteGapForm,
    downloadGapsForm,
    saveTreatmentPlan,
    updateReviewStatus,
    uploadRecaptureForm
} from 'redux/slices/pointsInCare'
import { dispatch, useSelector } from 'redux/store'
import { toLocalDate } from 'utilities/dateutilities'
import {
    considerationFeedbackOptionsMap,
    IProviderConsiderationFeedbackResponse,
    IProviderReviewFeedbackRequest,
    IUploadRecaptureDocumentResponse,
    IUploadRecaptureFileRequest
} from '../../PointsInCare.models'
import { confidenceMap } from '../../pointsInCareModel'
import {
    StyledFeedbackLabel,
    StyledFeedbackList,
    StyledFeedbackListItem,
    StyledReadOnlyFeedbackStatus,
    StyledTableBodyItem,
    StyledTableBodyRow,
    StyledTableBodyRowContainer,
    StyledTableBodyRowInner
} from '../index.style'
import TreatmentModal from '../TreatmentModal'
import { bool } from 'yup'
import {
    StyledButton,
    StyledDownloadFileButton,
    StyledFeedbackActionContainer,
    StyledUploadButtonContainer,
    StyledUploadedDocsList,
    StyledUploadedDocsListItem
} from '../../Quality/index.style'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { deleteDocIcon, feedbackIcon, trashcanIcon, uploadIcon } from '../../Quality/TableBody/icons'
import { setFormatedDate } from 'components/UsersTable/index.utils'
import SmallSpinner from 'components/SmallSpinner'
import { useDropzone } from 'react-dropzone'
import { areTuplesEqual } from 'utilities/tupleUtilities'
import useMemberInfoFromUrl from 'hooks/useMemberInfoFromUrl'
import { MemberProfileState } from 'redux/slices/memberProfile'
import { isFileOutsideMaxSizeLimit } from 'utilities/uploadUtilities'

interface ITableBodyProps {
    reviewData: IPointsInCareReview[]
    onSaveDraft: (selections: IProviderConsiderationFeedbackResponse[]) => void
    isSubmitted: boolean
}

const TableBody: FC<ITableBodyProps> = ({ reviewData, onSaveDraft, isSubmitted }) => {
    const currentUser = useCurrentUser()
    const [isModalVisible, setIsModalVisible] = useState<[boolean, number]>([false, -1])

    const initialRadioArray: IProviderConsiderationFeedbackResponse[] = reviewData.map(
        (reviewItem: IPointsInCareReview) => {
            return { Status: reviewItem.feedback, Id: reviewItem.targetedConditionUnderReviewId }
        }
    )

    const [memberId, currentNpi] = useMemberInfoFromUrl()

    // ************* useStates *************
    const [selectedRadio, setSelectedRadio] = useState<IProviderConsiderationFeedbackResponse[]>(initialRadioArray)
    const [uploadInProgress, setUploadInProgress] = useState<number>(-1)
    const [currentRowIndex, setCurrentRowIndex] = useState<number>(-1)
    const [file, setFile] = useState<File | null>(null)
    const [reviews, setReviews] = useState<IPointsInCareReview[]>([])
    const [deleteInProgress, setDeleteInProgress] = useState<[number, number]>([-1, -1])
    const [downloadInProgress, setDownloadInProgress] = useState<[number, number]>([-1, -1])
    const [isFeedbackModalShown, setIsFeedbackModalShown] = useState<boolean>(false)

    // ************** useStates *************

    const { getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles) => setFile(acceptedFiles[0]),
        accept: {
            'application/pdf': ['.pdf']
        }
    })

    const mapReviewFeedbackToLabel = (feedback: ConditionFeedbackStatus) => {
        return considerationFeedbackOptionsMap.get(feedback)
    }

    const handleReviewFeedback = (qualityRow: any, idx: number) => {
        setIsFeedbackModalShown(true)
        setCurrentRowIndex(idx)
    }

    const handleFeedbackRadio = (selectedFeedback: ConditionFeedbackStatus, index: number) => {
        if (
            selectedFeedback === ConditionFeedbackStatus.ConditionPresent ||
            selectedFeedback === ConditionFeedbackStatus.NeedsFurtherEvaluation
        ) {
            setIsModalVisible([true, index])
        }

        if (reviewData.length === 0) return
        const tempFeedback = [...selectedRadio]
        tempFeedback[index] = { Status: selectedFeedback, Id: reviews[index].targetedConditionUnderReviewId }
        setSelectedRadio(tempFeedback) // set response and force a rerender
        dispatch(cacheProviderReviewFeedback(tempFeedback[index])) // set response in redux
        // save draft every time a radio is selected
        dispatch(
            updateReviewStatus({
                PointOfCareId: reviews[index].targetedConditionUnderReviewId,
                ReviewValue: mapReviewFeedbackToLabel(selectedFeedback)
            } as IProviderReviewFeedbackRequest)
        )
    }

    const uploadFile = async (file: File) => {
        if (!file) return

        const args: IUploadRecaptureFileRequest = {
            file: file,
            pointOfCareId: reviews[currentRowIndex].targetedConditionUnderReviewId,
            recaptureId: reviews[currentRowIndex].pointOfCareRecaptureId
        }

        const rowIndex = currentRowIndex

        setUploadInProgress(rowIndex)
        dispatch(uploadRecaptureForm(args as IUploadRecaptureFileRequest)).then((res) => {
            setUploadInProgress(-1)
            if (res.meta.requestStatus === 'fulfilled') {
                const response = res.payload as IUploadRecaptureDocumentResponse
                const updatedReviews = [...reviews]
                const updatedReviewDocuments = [...reviews[rowIndex].reviewDocuments]
                const reviewDocumentsWithAppend = [...updatedReviewDocuments, response.documentInfo]
                //  updatedReviews[rowIndex].reviewDocuments = reviewDocumentsWithppendA
                // create a new review object with the updated reviewDocuments
                const updatedReview = {
                    ...updatedReviews[rowIndex],
                    reviewDocuments: reviewDocumentsWithAppend
                }

                // replace the old review with the new one
                updatedReviews[rowIndex] = updatedReview
                setReviews(updatedReviews)
            }
        })
    }

    useEffect(() => {
        if (!reviewData) return
        setReviews(reviewData)
    }, [reviewData])

    useEffect(() => {
        if (file && !isFileOutsideMaxSizeLimit(file)) {
            uploadFile(file)
        }
    }, [file])

    useEffect(() => {
        if (selectedRadio.length === 0) return
        // if selected radio is the  same as the old state, do nothing
        if (JSON.stringify(selectedRadio) === JSON.stringify(initialRadioArray)) return
        //     onSaveDraft(selectedRadio)
    }, [selectedRadio])

    useEffect(() => {
        setSelectedRadio(initialRadioArray)
    }, [reviewData])

    const adjustToClient = (tableData: any) => {
        const clientItems = tableData.map((tableDataItem: any) => {
            const clientItem = {
                ...tableDataItem,
                lastReported: toLocalDate(tableDataItem.lastReported),
                confidence: confidenceMap.get(tableDataItem.confidence)
            }
            return clientItem
        })

        return clientItems
    }

    const getIndexOfProperty = (row: any, property: string) => {
        return Object.keys(row).indexOf(property)
    }

    const saveTreatmentPlanAtRow = (index: number, treatmentText: string) => {
        dispatch(
            saveTreatmentPlan({
                recaptureId: reviews[index].pointOfCareRecaptureId,
                treatmentPlan: treatmentText
            } as ISaveTreatmentPlanRequest)
        )
        setIsModalVisible([false, -1])
    }

    const cancelTreatmentPlan = () => {
        setIsModalVisible([false, -1])
    }

    const handleDeleteFile = (
        pointsInCareDocumentId: string,
        documentId: string,
        docIdx: number,
        reviewIdx: number
    ) => {
        setDeleteInProgress([reviewIdx, docIdx])
        dispatch(
            deleteGapForm({
                pointOfCareDocumentId: pointsInCareDocumentId,
                documentId: documentId
            } as IDeletePointsInCareDocument)
        ).then(() => {
            // delete the gap file here
            setDeleteInProgress([-1, -1])
            const updatedReviewData = [...reviews]
            const updatedReviewDocuments = [...updatedReviewData[reviewIdx].reviewDocuments]
            const updatedDeletedReviewDocuments = updatedReviewDocuments.filter((doc) => doc.documentId !== documentId)

            const updatedReview: IPointsInCareReview = {
                ...updatedReviewData[reviewIdx],
                reviewDocuments: updatedDeletedReviewDocuments
            }

            updatedReviewData[reviewIdx] = updatedReview

            setReviews(updatedReviewData)
        })
    }

    const memberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const isAdmin = () => {
        return currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep
    }

    const handleDocDownload = (documentId: string, docIndex: number, gapIndex: number) => {
        setDownloadInProgress([gapIndex, docIndex])
        dispatch(
            downloadGapsForm({
                documentId: documentId,
                memberId: memberId,
                npi: currentNpi,
                gap: memberProfileDetails?.lastName,
                fileName: reviews[gapIndex].reviewDocuments[docIndex].documentName
            } as IDownloadGapFormRequest)
        ).then(() => {
            setDownloadInProgress([-1, -1])
        })
    }

    const handleAddReviewFeedback = (text: string, idx: number) => {
        // update the current row with the feedback and refresh
        dispatch(
            addReviewFeedback({
                npi: currentNpi,
                memberId: memberId,
                pointOfCareId: reviews[idx].targetedConditionUnderReviewId,
                pointOfCareRecaptureId: reviews[idx].pointOfCareRecaptureId,
                feedbackText: text
            } as IAddReviewFeedbackRequest)
        )
    }

    const handleUpload = (reviewRow: IPointsInCareReview, idx: number) => {
        console.log('handle upload', reviewRow, idx)
        setCurrentRowIndex(idx)
        open()
    }

    return (
        <>
            {/* {isFeedbackModalShown && (
                <ReviewFeedbackModalDialog
                    onCancel={function (): void {
                        setIsFeedbackModalShown(false)
                    }}
                    onSave={function (text: string): void {
                        setIsFeedbackModalShown(false)
                        handleAddReviewFeedback(text, currentRowIndex)
                    }}
                    request={
                        {
                            memberId: memberId,
                            npi: currentNpi,
                            pointOfCareId: reviews[currentRowIndex].targetedConditionUnderReviewId,
                            pointOfCareRecaptureId: reviews[currentRowIndex].pointOfCareRecaptureId
                        } as IReviewFeedbackRequest
                    }
                />
            )} */}
            {isModalVisible[0] ? (
                <TreatmentModal
                    recaptureId={reviews[isModalVisible[1]].pointOfCareRecaptureId}
                    onSave={(treatmentText) => saveTreatmentPlanAtRow(isModalVisible[1], treatmentText)}
                    onCancel={cancelTreatmentPlan}
                />
            ) : null}

            <StyledTableBodyRowContainer>
                {adjustToClient(reviews).map((row: any, idx: number) => {
                    return (
                        <StyledTableBodyRow key={`tableBodyRow-${idx}`}>
                            <StyledTableBodyItem key={`bodyItemOption-${idx}`} status={row.confidence}>
                                <Typography type={TYPOGRAPHY_TYPES.p}>{row?.diagnosisCode}</Typography>

                                <StyledUploadedDocsList>
                                    {row?.reviewDocuments.map((document: any, docIdx: number) => {
                                        return (
                                            <StyledUploadedDocsListItem>
                                                <StyledDownloadFileButton
                                                    onClick={() =>
                                                        handleDocDownload(
                                                            reviews[idx].reviewDocuments[docIdx].documentId,
                                                            docIdx,
                                                            idx
                                                        )
                                                    }
                                                >
                                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                                        {`${document.documentName} ${setFormatedDate(
                                                            document.dateOfSubmission
                                                        )}`}
                                                    </Typography>
                                                </StyledDownloadFileButton>
                                                {areTuplesEqual(deleteInProgress, [idx, docIdx]) ? (
                                                    <SmallSpinner />
                                                ) : (
                                                    !isSubmitted &&
                                                    !isAdmin() && (
                                                        <StyledButton
                                                            onClick={() =>
                                                                handleDeleteFile(
                                                                    document?.reviewDocumentId,
                                                                    document?.documentId,
                                                                    docIdx,
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            <CustomSvgIcon
                                                                iconSet={{ icon: trashcanIcon }}
                                                                svg
                                                            ></CustomSvgIcon>
                                                        </StyledButton>
                                                    )
                                                )}
                                                {areTuplesEqual(downloadInProgress, [idx, docIdx]) && <SmallSpinner />}
                                            </StyledUploadedDocsListItem>
                                        )
                                    })}
                                </StyledUploadedDocsList>
                                {uploadInProgress == idx ? (
                                    <SmallSpinner />
                                ) : (
                                    !isSubmitted &&
                                    !isAdmin() && (
                                        <StyledUploadButtonContainer>
                                            <button onClick={(e) => handleUpload(row, idx)}>
                                                <CustomSvgIcon iconSet={{ icon: uploadIcon }} svg></CustomSvgIcon>
                                            </button>
                                            <a onClick={(e) => handleUpload(row, idx)}>Upload PDF</a>
                                        </StyledUploadButtonContainer>
                                    )
                                )}
                            </StyledTableBodyItem>
                            {Object.values(row)
                                .filter(
                                    (val, index) =>
                                        index != getIndexOfProperty(row, 'feedback') &&
                                        index != getIndexOfProperty(row, 'confidence') &&
                                        index != getIndexOfProperty(row, 'targetedConditionUnderReviewId') &&
                                        index != getIndexOfProperty(row, 'pointOfCareRecaptureId') &&
                                        index != getIndexOfProperty(row, 'reviewDocuments') &&
                                        index != getIndexOfProperty(row, 'diagnosisCode') &&
                                        index != getIndexOfProperty(row, 'treatmentPlan') &&
                                        index != getIndexOfProperty(row, 'recaptureStatus')
                                )
                                .map((rowValues: any, innerIdx: number) => {
                                    return (
                                        <StyledTableBodyItem key={`bodyItemOption-${innerIdx}`} status={row.confidence}>
                                            <Typography type={TYPOGRAPHY_TYPES.p}>{rowValues}</Typography>
                                        </StyledTableBodyItem>
                                    )
                                })}
                            {currentUser.primaryRole === ERoles.CallCenterAdmin ||
                            currentUser.primaryRole === ERoles.ProviderRep ||
                            isSubmitted ? (
                                <StyledReadOnlyFeedbackStatus>
                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                        {considerationFeedbackOptionsMap.get(row.feedback)}
                                    </Typography>
                                </StyledReadOnlyFeedbackStatus>
                            ) : (
                                <StyledTableBodyRowInner key={`styled-table-body-row-${idx}`}>
                                    <StyledFeedbackList key={`styled-feedbacklist-${idx}`}>
                                        {Array.from(considerationFeedbackOptionsMap.keys()).map(
                                            (feedbackOption: ConditionFeedbackStatus, feedbackIdx: number) => {
                                                return (
                                                    // click on StyledFeedbackListItem to select radio button

                                                    <StyledFeedbackListItem key={`feedbackOption-${feedbackIdx}`}>
                                                        <StyledFeedbackLabel
                                                            htmlFor={`radio_review_id_${idx}_${feedbackIdx}`}
                                                        >
                                                            <input
                                                                id={`radio_review_id_${idx}_${feedbackIdx}`}
                                                                type="radio"
                                                                name={`radio_review_group_${idx}`}
                                                                value={feedbackOption}
                                                                checked={feedbackOption === selectedRadio[idx]?.Status}
                                                                onChange={(e) =>
                                                                    handleFeedbackRadio(feedbackOption, idx)
                                                                }
                                                            />
                                                            {considerationFeedbackOptionsMap.get(feedbackOption)}
                                                        </StyledFeedbackLabel>
                                                    </StyledFeedbackListItem>
                                                )
                                            }
                                        )}
                                    </StyledFeedbackList>
                                </StyledTableBodyRowInner>
                            )}
                            <StyledTableBodyItem key={`bodyItemGapInCareFeedback-${idx}`}>
                                <StyledFeedbackActionContainer>
                                    <button onClick={(e) => handleReviewFeedback(row, idx)}>
                                        <CustomSvgIcon iconSet={{ icon: feedbackIcon }} svg></CustomSvgIcon>
                                    </button>
                                </StyledFeedbackActionContainer>
                            </StyledTableBodyItem>
                        </StyledTableBodyRow>
                    )
                })}
            </StyledTableBodyRowContainer>
        </>
    )
}

export default TableBody
