import { FC } from 'react'
import { StyledAWVDigitalInput } from '../index.style'
import { Controller, useFormContext } from 'react-hook-form'
import { INumericField, ISectionElement } from '../index.models'
import { Label } from '../Label'

interface INumericFieldProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
    minValue: number
    maxValue: number
    step: number
}

const NumericField: FC<INumericFieldProps> = ({ name, label, subLabel, placeholder, minValue, maxValue, step }) => {
    const {
        formState: { errors },
        control,
        setValue
    } = useFormContext()

    return (
        <StyledAWVDigitalInput>
            <Label htmlFor={name} label={label} subLabel={subLabel} />
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <input
                        id={name}
                        {...field}
                        type="number"
                        value={field.value ?? ''}
                        min={minValue}
                        max={maxValue}
                        step={step}
                        onChange={(e) => setValue(name, Number(e.target.value).valueOf())}
                        placeholder={placeholder ?? label}
                    />
                )}
            />
            {errors?.[name] && <small>{errors?.[name].message}</small>}
        </StyledAWVDigitalInput>
    )
}

export const renderNumericField = (element: ISectionElement, key: string) => {
    const numericField = element as INumericField
    return <NumericField key={key} {...numericField} />
}
