export const editIcon = `<svg width="20" height="20" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.25 10.9375V13.75H3.0625L11.3575 5.45504L8.545 2.64254L0.25 10.9375ZM13.5325 3.28004C13.825 2.98754 13.825 2.51504 13.5325 2.22254L11.7775 0.467544C11.485 0.175044 11.0125 0.175044 10.72 0.467544L9.3475 1.84004L12.16 4.65254L13.5325 3.28004Z" fill="white"/>
</mask>
<g mask="url(#mask0_11664_5102)">
</g>
</svg>
`

export const downloadIcon = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Regular / download-thick-bottom">
<g id="download-thick-bottom">
<path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M6.24805 8.74923L9.99983 12.5016L13.7522 8.74923H11.6675V4.99688C11.6675 4.76662 11.4809 4.57996 11.2506 4.57996H8.74905C8.51879 4.57996 8.33213 4.76662 8.33213 4.99688V8.74923H6.24805Z" stroke-width="1.5"/>
<path id="Shape_2" d="M16.2539 13.3354V15.0031C16.2539 15.4637 15.8806 15.837 15.4201 15.837H4.57995C4.11942 15.837 3.74609 15.4637 3.74609 15.0031V13.3354" stroke-width="1.5"/>
</g>
</g>
</svg>
`

export const uploadIcon = `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.92717 10.7394C5.48089 10.7391 5.11919 10.3774 5.11888 9.93116L5.11944 5.35997C5.11959 5.32307 5.10499 5.28763 5.0789 5.26154C5.0528 5.23544 5.01737 5.22085 4.98046 5.221H3.36834C3.09489 5.24295 2.83849 5.086 2.73366 4.83249C2.62883 4.57897 2.69951 4.28677 2.90861 4.10919L6.29851 0.718184C6.61835 0.412949 7.1216 0.412949 7.44144 0.718184L10.8313 4.10641C11.0397 4.28431 11.1098 4.57607 11.0051 4.82923C10.9005 5.08239 10.6447 5.2394 10.3716 5.21822H8.75949C8.68274 5.21822 8.62051 5.28044 8.62051 5.35719V9.93116C8.62021 10.3772 8.25885 10.7388 7.81279 10.7394H5.92717ZM2.57229 13.5106C1.33399 13.5094 0.330449 12.5059 0.329224 11.2676V10.2241C0.339752 9.85575 0.641407 9.5626 1.00993 9.5626C1.37845 9.5626 1.6801 9.85575 1.69063 10.2241V11.2659C1.69124 11.7526 2.08562 12.1469 2.57229 12.1475H11.4278C11.9145 12.1469 12.3089 11.7526 12.3095 11.2659V10.2241C12.32 9.85575 12.6217 9.5626 12.9902 9.5626C13.3587 9.5626 13.6604 9.85575 13.6709 10.2241V11.2676C13.6697 12.5059 12.6661 13.5094 11.4278 13.5106H2.57229Z"/>
</svg>
`

export const backArrowIcon = `<svg width="21" height="9" viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.96997 1C12.62 1 15.02 1.99 16.87 3.6L20.47 0V9H11.47L15.09 5.38C13.7 4.22 11.93 3.5 9.96997 3.5C6.42997 3.5 3.41997 5.81 2.36997 9L-2.86102e-05 8.22C1.38997 4.03 5.31997 1 9.96997 1Z" fill="#293854"/>
</svg>
`

export const trashCanIcon = `<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#B11B1B"/>
</svg>
`

export const deleteIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5002 2.5V3.33333H16.6668V5H15.8335V15.8333C15.8335 16.75 15.0835 17.5 14.1668 17.5H5.8335C4.91683 17.5 4.16683 16.75 4.16683 15.8333V5H3.3335V3.33333H7.50016V2.5H12.5002ZM5.8335 15.8333H14.1668V5H5.8335V15.8333ZM7.50016 6.66667H9.16683V14.1667H7.50016V6.66667ZM12.5002 6.66667H10.8335V14.1667H12.5002V6.66667Z" fill="#B11B1B"/>
</svg>`

export const previewIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<g clip-path="url(#clip0_16156_4483)">
  <path d="M10.0002 3.75C5.8335 3.75 2.27516 6.34167 0.833496 10C2.27516 13.6583 5.8335 16.25 10.0002 16.25C14.1668 16.25 17.7252 13.6583 19.1668 10C17.7252 6.34167 14.1668 3.75 10.0002 3.75ZM10.0002 14.1667C7.70016 14.1667 5.8335 12.3 5.8335 10C5.8335 7.7 7.70016 5.83333 10.0002 5.83333C12.3002 5.83333 14.1668 7.7 14.1668 10C14.1668 12.3 12.3002 14.1667 10.0002 14.1667ZM10.0002 7.5C8.61683 7.5 7.50016 8.61667 7.50016 10C7.50016 11.3833 8.61683 12.5 10.0002 12.5C11.3835 12.5 12.5002 11.3833 12.5002 10C12.5002 8.61667 11.3835 7.5 10.0002 7.5Z" fill="#2281C4"/>
</g>
<defs>
  <clipPath id="clip0_16156_4483">
    <rect width="20" height="20" fill="white"/>
  </clipPath>
</defs>
</svg>`

export const checkedIcon = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.0007 1.83331C5.94065 1.83331 1.83398 5.93998 1.83398 11C1.83398 16.06 5.94065 20.1666 11.0007 20.1666C16.0607 20.1666 20.1673 16.06 20.1673 11C20.1673 5.93998 16.0607 1.83331 11.0007 1.83331ZM9.16732 15.5833L4.58398 11L5.87648 9.70748L9.16732 12.9891L16.1248 6.03165L17.4173 7.33331L9.16732 15.5833Z" fill="#0BC196"/>
</svg>`

export const openInNew = `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
<mask id="mask0_16241_77717" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="20">
  <rect x="0.666016" width="20" height="20" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_16241_77717)">
  <path d="M4.83268 17.5C4.37435 17.5 3.98199 17.3368 3.6556 17.0104C3.32921 16.684 3.16602 16.2917 3.16602 15.8333V4.16667C3.16602 3.70833 3.32921 3.31597 3.6556 2.98958C3.98199 2.66319 4.37435 2.5 4.83268 2.5H10.666V4.16667H4.83268V15.8333H16.4993V10H18.166V15.8333C18.166 16.2917 18.0028 16.684 17.6764 17.0104C17.35 17.3368 16.9577 17.5 16.4993 17.5H4.83268ZM8.74935 13.0833L7.58268 11.9167L15.3327 4.16667H12.3327V2.5H18.166V8.33333H16.4993V5.33333L8.74935 13.0833Z"/>
</g>
</svg>`
