import { FC, useEffect } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import {
    StyledCommentsContainer,
    StyledFunctionalAssessmentTableBody,
    StyledFunctionalAssessmentTableBodyItem,
    StyledFunctionalAssessmentTableBodyItemActivity,
    StyledFunctionalAssessmentTableContainer,
    StyledFunctionalAssessmentTableHeader,
    StyledFunctionalAssessmentTableHeaderItem,
    StyledResponsesContainer,
    StyledScoreContainer
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import {
    ICommentedScoredMultipleChoiceList,
    ICommentedScoredMultipleChoiceListItem,
    ICommentedScoredMultipleChoiceListOption,
    ISectionElement
} from '../index.models'
import { performFieldSynchronizationStep } from '../utils'

interface ICommentedScoredMultipleChoiceListProps {
    name: string
    titleColumnLabel: string
    scoreColumnLabel: string
    commentsColumnLabel: string
    totalScoreLabel: string
    totalScoreCommentsFieldName: string
    items: ICommentedScoredMultipleChoiceListItem[]
}

const CommentedScoredMultipleChoiceList: FC<ICommentedScoredMultipleChoiceListProps> = ({
    name,
    titleColumnLabel,
    scoreColumnLabel,
    commentsColumnLabel,
    totalScoreLabel,
    totalScoreCommentsFieldName,
    items
}) => {
    const { register, control, watch, setValue } = useFormContext()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    useEffect(() => {
        performFieldSynchronizationStep(items, fields, append, remove, move)
    }, [items, fields, append, remove, move])

    const itemsByName = items.reduce((acc: any, item: any) => {
        acc[item.name] = item
        return acc
    }, {})

    const totalScore = watch(name)?.reduce((acc: number, item: any) => acc + (item?.value ?? 0), 0)

    return (
        <StyledFunctionalAssessmentTableContainer>
            <StyledFunctionalAssessmentTableHeader>
                <StyledFunctionalAssessmentTableHeaderItem>
                    {titleColumnLabel}
                </StyledFunctionalAssessmentTableHeaderItem>
                <StyledFunctionalAssessmentTableHeaderItem>
                    {scoreColumnLabel}
                </StyledFunctionalAssessmentTableHeaderItem>
                <StyledFunctionalAssessmentTableHeaderItem>
                    {commentsColumnLabel}
                </StyledFunctionalAssessmentTableHeaderItem>
            </StyledFunctionalAssessmentTableHeader>
            {fields.map((field: any, idx: number) => (
                <StyledFunctionalAssessmentTableBody key={field.id}>
                    <StyledFunctionalAssessmentTableBodyItem>
                        <StyledFunctionalAssessmentTableBodyItemActivity>
                            <Typography type={TYPOGRAPHY_TYPES.h3}>{itemsByName[field.name].label}</Typography>
                            <StyledResponsesContainer>
                                {itemsByName[field.name].options.map(
                                    (option: ICommentedScoredMultipleChoiceListOption) => (
                                        <label key={`option-${field.id}-${option.value}`}>
                                            <Controller
                                                name={`${name}.${idx}.value`}
                                                control={control}
                                                render={({ field }) => (
                                                    <input
                                                        id={`${name}.${idx}.value`}
                                                        {...field}
                                                        type="radio"
                                                        value={option.value}
                                                        checked={field.value === option.value}
                                                        onChange={() => setValue(`${name}.${idx}.value`, option.value)}
                                                    />
                                                )}
                                            />
                                            {option.label}
                                        </label>
                                    )
                                )}
                            </StyledResponsesContainer>
                        </StyledFunctionalAssessmentTableBodyItemActivity>
                    </StyledFunctionalAssessmentTableBodyItem>
                    <StyledFunctionalAssessmentTableBodyItem>
                        <StyledScoreContainer>
                            <input value={watch(`${name}.${idx}.value`) ?? ''} readOnly={true} />
                        </StyledScoreContainer>
                    </StyledFunctionalAssessmentTableBodyItem>
                    <StyledFunctionalAssessmentTableBodyItem>
                        <StyledCommentsContainer>
                            <input {...register(`${name}.${idx}.comments`)} />
                        </StyledCommentsContainer>
                    </StyledFunctionalAssessmentTableBodyItem>
                </StyledFunctionalAssessmentTableBody>
            ))}
            <StyledFunctionalAssessmentTableBody key={`${name}`}>
                <StyledFunctionalAssessmentTableBodyItem>
                    <StyledFunctionalAssessmentTableBodyItemActivity>
                        <Typography type={TYPOGRAPHY_TYPES.h3}>{totalScoreLabel}</Typography>
                    </StyledFunctionalAssessmentTableBodyItemActivity>
                </StyledFunctionalAssessmentTableBodyItem>
                <StyledFunctionalAssessmentTableBodyItem>
                    <StyledScoreContainer>
                        <input value={totalScore} readOnly={true} />
                    </StyledScoreContainer>
                </StyledFunctionalAssessmentTableBodyItem>
                <StyledFunctionalAssessmentTableBodyItem>
                    <StyledCommentsContainer>
                        <input {...register(totalScoreCommentsFieldName)} />
                    </StyledCommentsContainer>
                </StyledFunctionalAssessmentTableBodyItem>
            </StyledFunctionalAssessmentTableBody>
        </StyledFunctionalAssessmentTableContainer>
    )
}

export const renderCommentedScoredMultipleChoiceList = (element: ISectionElement, key: string) => {
    const commentedScoredMultipleChoiceList = element as ICommentedScoredMultipleChoiceList
    return <CommentedScoredMultipleChoiceList key={key} {...commentedScoredMultipleChoiceList} />
}
