import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, grey } = UI_COLORS

export const StyledPICTabControllerContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(23)};
    margin-top: ${pxToRem(50)};
    position: relative;
    z-index: 0;

    div {
        position: absolute;
        height: 2px;
        width: 100%;
        background-color: #dee5ef;
        bottom: 10px;
        z-index: 0;
    }
`

export const StyledPICTabs = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: ${pxToRem(15)};
    display: flex;
    flex-direction: row;
`

export const StyledPICTabItem = styled.li<{ isActive?: boolean }>`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(23)};
    color: #73839c;
    margin-right: ${pxToRem(50)};
    z-index: 1;
    margin-bottom: 10px;
    height: 50px;

    cursor: pointer;

    ${(props) => {
        if (props.isActive) {
            return css`
                color: #2281c4;
                border-bottom: 1px solid #2281c4;
            `
        }
    }}
`
