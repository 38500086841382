import { FC, useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { StyledFormCheckBoxListContainer, StyledRadioList, StyledRadioListItem } from './index.style'
import { ICheckBoxList, ICheckBoxListItem, ISectionElement } from '../index.models'
import { Label } from '../Label'
import { performFieldSynchronizationStep } from '../utils'

interface ICheckBoxListProps {
    name: string
    label: string
    subLabel: string
    items: ICheckBoxListItem[]
}

const CheckBoxList: FC<ICheckBoxListProps> = ({ name, label, subLabel, items }) => {
    const { register, control } = useFormContext()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    useEffect(() => {
        performFieldSynchronizationStep(items, fields, append, remove, move)
    }, [items, fields, append, remove, move])

    const labelsByName = items.reduce((acc: any, item: any) => {
        acc[item.name] = item.label
        return acc
    }, {})

    return (
        <StyledFormCheckBoxListContainer>
            <Label label={label} subLabel={subLabel} wrapInParagraph={true} />
            <StyledRadioList>
                {fields.map((field: any, index: number) => (
                    <StyledRadioListItem key={field.id}>
                        <label>
                            <input {...register(`${name}.${index}.value`)} type="checkbox" />
                            {labelsByName[field.name]}
                        </label>
                    </StyledRadioListItem>
                ))}
            </StyledRadioList>
        </StyledFormCheckBoxListContainer>
    )
}

export const renderCheckBoxList = (element: ISectionElement, key: string) => {
    const checkBoxList = element as ICheckBoxList
    return <CheckBoxList key={key} {...checkBoxList} />
}
