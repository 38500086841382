import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { dark_gray, white } = UI_COLORS

export const StyledInlineQuestionContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: ${pxToRem(30)};

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${dark_gray};
    }
`

export const StyledInlineQuestionRow = styled.ul`
    list-style: none;
    padding: 26px 30px;
    margin: 0;

    background: ${white};

    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #dee5ef;
`

export const StyledInlineQuestionRowItem = styled.li`
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #293854;
        display: flex;
        flex-direction: row;
        align-items: center;

        cursor: pointer;
    }

    input {
        margin: 0;
        padding: 0;
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }

    :nth-child(1) {
        width: ${pxToRem(850)};
    }

    :nth-child(2) {
        width: ${pxToRem(150)};
    }

    :nth-child(3) {
        width: ${pxToRem(150)};
    }
`
