export const role = {
    HCP: 'HCP',
    Provider: 'Provider'
}

export const dlm = ' > '

export const pageTitle = {
    Root: 'Root',
    UsersAdmins: 'Users > Admins',
    AdminUpdateAWVPDFTemplate: 'Admin > Update AWV PDF Template',
    AdminUpdateAWVElectronicTemplate: 'Admin > Update AWV Electronic Template',
    UsersProvider: 'Users > Provider',
    UsersOfficeAdmin: 'Users > Office Admin',
    UserDetails: 'Users > Details',
    UserReports: 'Users > Reports',
    UsersNewUser: 'Users > New User',
    UsersSummary: 'Users Summary',
    Incentives: 'Incentives',
    PatientOutreach: 'Patient Outreach',
    Pulse8SpecificNPI: 'Pulse 8 > Specific NPI',
    Pulse8: 'Pulse 8',
    Registration: 'Registration',
    PatientsPersonal: 'Patients > Personal',
    PatientsAWV: 'Patients > AWV',
    PatientsSummary: 'Patients Summary',
    PatientsAWVEditWebForm: 'Patients > AWV > Edit Web Form',
    PatientsAWVViewWebForm: 'Patients > AWV > View Web Form',
    SubmittedAWVForms: 'Submitted AWV Forms',
    // will be added
    PatientsDocuments: 'Patients > Documents',
    PatientsAddresses: 'Patients > Addresses',
    PatientsBenefitInfo: 'Patients > Benefit Info',
    ProviderReportsPage: 'Provider Reports'
}

export const customEvents = {
    ExcelExport: 'Excel Export',
    UpdateInfo: 'Update Info',
    DisableUser: 'Disable User',
    EnableUser: 'Enable User',
    MirrorUser: 'Mirror User',
    ResendInvitation: 'Resend Invitation',
    ViewFeedback: 'View Feedback',
    SendFeedback: 'Send Feedback',
    ChangeYear: 'Change Year',
    ChangeProvider: 'Change Provider',
    TopOpportunitiesByIncentive: 'Top Opportunities > By Incentive',
    TopOpportunitiesByPatients: 'Top Opportunities > By Patients',
    TopOpportunitiesViewMore: 'Top Opportunities > View More',
    ScheduleAWV: 'Schedule AWV',
    DownloadAWVPDF: 'Download AWV PDF',
    DownloadSavedAWVPDF: 'Download Saved AWV PDF',
    UploadAWVPDF: 'Upload AWV PDF',
    UploadAWVSupportingDocument: 'Upload AWV Supporting Document',
    DeleteAWVSupportingDocument: 'Delete AWV Supporting Document',
    DownloadAWVSupportingDocument: 'Download AWV Supporting Document',
    SubmitAWVForm: 'Submit AWV Form',
    DeleteAWVForm: 'Delete AWV Form',
    UploadMedicalChart: 'Upload Medical Chart',
    UploadDocument: 'Upload Document',
    ViewMedicalChart: 'View Medical Chart',
    ViewDocument: 'View Document',
    ViewEncounterDocuments: 'View Encounter / Documents',
    ProviderReportUpload: 'Upload Provider Report',
    ProviderReportDownload: 'Download Provider Report',
    ProviderReportDelete: 'Delete Provider Report',
    ChangeStatusApprove: 'Change Status > Approve',
    ChangeStatusInReview: 'Change Status > In Review',
    ChangeStatusPending: 'Change Status > Pending',
    ReviewAndViewForm: 'Review & View Form',
    ViewForm: 'View Form',
    SearchOpportunities: 'Search Opportunities',
    SearchUsers: 'Search Users',
    DeleteMedicalChart: 'Delete Medical Chart ',
    DeleteDocument: 'Delete Document',
    NewOfficeAdmin: 'New Office Admin ',
    NewPCPNP: 'New PCP NP',
    UploadAWVPDFTemplate: 'Upload AWV PDF Template',
    UploadAWVElectronicTemplate: 'Upload AWV Electronic Template'
}
