import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { IAwvSummary, IPointsInCareAwv, IPointsInCareQuality } from 'pages/hcp/users/create/index.models'
import { FC } from 'react'
import { StyledQualityTableContainerWrap } from './index.style'
import TableBody from './TableBody'
import TableHeader from './TableHeader'
import { StyledEmptyStateContainer } from '../ReviewTable/index.style'

const headerItems = ['Gap In Care', 'Last DOS', 'Status', 'Referral', 'Feedback']

interface IQualityTableProps {
    pointsInCareQuality: IPointsInCareQuality[]
    pointsInCareAwv: IAwvSummary[]
    isSubmitted?: boolean
}

const QualityTable: FC<IQualityTableProps> = ({ pointsInCareQuality, pointsInCareAwv, isSubmitted }) => {
    console.log('pointsInCareQuality', pointsInCareQuality.length <= 0)

    const tableIsEmpty: boolean = pointsInCareQuality.length <= 0
    return (
        <StyledQualityTableContainerWrap>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                Fusce posuere, enim eget semper sagittis, urna orci semper turpis, nec congue nisl purus quis metus.
                Phasellus mollis orci ipsum, sit amet tempus turpis eleifend quis. Fusce mauris felis, dapibus at
                fringilla id.
            </Typography>
            <TableHeader tableHeaders={headerItems} />
            {tableIsEmpty ? (
                <StyledEmptyStateContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h2}>Data not available at the moment</Typography>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                </StyledEmptyStateContainer>
            ) : (
                <TableBody qualityData={pointsInCareQuality} isSubmitted={isSubmitted} />
            )}
        </StyledQualityTableContainerWrap>
    )
}

export default QualityTable
