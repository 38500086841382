import { createSlice } from '@reduxjs/toolkit'
import { createExceptionAwareAsyncThunk } from 'utilities/createExceptionAwareAsyncThunk'
import axios from '../../utilities/axios'
import { dynamicStoreData } from 'redux/store'

export interface IProviderNpi {
    firstName: string
    lastName: string
    npi: string
    userState: string
}

export interface IGetNpiInformationRequest {
    npi: string
}

export type Pulse8State = {
    currentNpi: string
    currentNpiYears: any
    providerNpis: IProviderNpi[]
    providerNpisLoaded: boolean
    officeAdminProviderNpis: dynamicStoreData<IProviderNpi[]>
}

const initialState: Pulse8State = {
    currentNpi: '',
    currentNpiYears: {},
    providerNpis: [],
    providerNpisLoaded: false,
    officeAdminProviderNpis: {}
}

export const pulse8Slice = createSlice({
    name: 'pulse8',
    initialState,
    reducers: {
        setCurrentNpi: (state, action) => {
            state.currentNpi = action.payload
        },
        setCurrentNpiYear: (state, action) => {
            state.currentNpiYears = { ...state.currentNpiYears, [action.payload.npi]: action.payload.year }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProviderNpis.fulfilled, (state, action) => {
                state.providerNpis = action.payload
                state.providerNpisLoaded = true
                if (state.providerNpis.length > 0 && !state.providerNpis.find((x) => x.npi === state.currentNpi)) {
                    state.currentNpi = state.providerNpis[0].npi
                }
            })
            .addCase(fetchOfficeAdminProviderNpis.fulfilled, (state, action) => {
                state.officeAdminProviderNpis = {
                    ...state.officeAdminProviderNpis,
                    [action.meta.arg.userId]: action.payload
                }
            })
    }
})

export default pulse8Slice.reducer

export const { setCurrentNpi, setCurrentNpiYear } = pulse8Slice.actions

export const fetchProviderNpis = createExceptionAwareAsyncThunk('pulse8/getProviderNpis', async () => {
    const response = await axios.get('api/OfficeAdmin/GetProviderNpis')
    return response.data
})

interface IFetchOfficeAdminProviderNpisArgs {
    userId: string
}

export const fetchOfficeAdminProviderNpis = createExceptionAwareAsyncThunk(
    'pulse8/getOfficeAdminProviderNpis',
    async (args: IFetchOfficeAdminProviderNpisArgs) => {
        const response = await axios.post('api/CallCenterAdmin/GetProviderNpis', args)
        return response.data
    }
)
