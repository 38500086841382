import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import useCurrentUser from 'hooks/useCurrentUser'

import {
    IAddQualityFeedbackRequest,
    IDeletePointsInCareDocument,
    IAwvSummary,
    IMemberProfileDetails,
    IPointsInCareQuality,
    IQualityDocument,
    ISpecialist,
    IUpdateGapFormResponse,
    QualityStatus,
    IAwvServiceStatus,
    IMemberProfileScheduleAwvData,
    IMemberProfileUnscheduleAwvData,
    IAddAwvFeedbackRequest,
    IAwvFeedbackRequest,
    IAwvFormDocumentResponseSummary
} from 'pages/hcp/users/create/index.models'
import * as React from 'react'
import { FC, useState } from 'react'
import {
    PointsInCareState,
    addQualityFeedback,
    deleteGapForm,
    downloadGapsForm,
    uploadGapForm
} from 'redux/slices/pointsInCare'
import { dispatch, useSelector } from 'redux/store'
import { toLocalDate } from 'utilities/dateutilities'
import { IGapUploadArgs, IProviderQualityFeedbackResponse } from '../../PointsInCare.models'
import {
    StyledButton,
    StyledCalendarButton,
    StyledCalendarContainer,
    StyledDateContainer,
    StyledFeedbackActionContainer,
    StyledIncentiveTitle,
    StyledReferralActionContainer,
    StyledReferralContainer,
    StyledTableBodyItem,
    StyledTableBodyRow,
    StyledTableBodyRowContainer,
    StyledUploadButtonContainer,
    StyledUploadedDocsList,
    StyledUploadedDocsListItem,
    StyledUploadedQualityDocsListItem
} from '../index.style'
import { calendarIcon, feedbackIcon, referralIcon, trashcanIcon, uploadIcon } from './icons'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { ReferralModalDialog } from '../ReferralModalDialog'
import { FeedbackModalDialog } from '../FeedbackModalDialog'
import useMemberInfoFromUrl from 'hooks/useMemberInfoFromUrl'
import { useDropzone } from 'react-dropzone'
import { setFormatedDate } from 'components/UsersTable/index.utils'
import { updateReferral } from '../../../../../../../redux/slices/pointsInCare'
import { IUpdateReferralRequest, IQualityFeedbackRequest } from '../../../../../users/create/index.models'
import SmallSpinner from 'components/SmallSpinner'
import { areTuplesEqual } from 'utilities/tupleUtilities'
import {
    MemberProfileState,
    deleteAwvFormResponse,
    downloadMemberAwvFile,
    getAwvStatus,
    getMemberNpiAwvSummaries,
    scheduleAwv,
    unscheduleAwv,
    saveAwvFormDocumentResponse
} from '../../../../../../../redux/slices/memberProfile'
import moment from 'moment'
import ScheduleAWVModal from 'pages/hcp/members/callCenterAdminList/scheduleAWVModal'
import { ERoles } from 'models/enums/role'
import { AwvFeedbackModalDialog } from '../FeedbackModalDialog/awvFeedbackModalDialog'
import { isFileOutsideMaxSizeLimit } from 'utilities/uploadUtilities'
import { trashCanIcon } from '../../../AWV/icons'

interface IQualityTableBodyProps {
    qualityData: IPointsInCareQuality[]
    isSubmitted: boolean
    //IAwvFormResponseSummary[]
}

const TableBody: FC<IQualityTableBodyProps> = ({ qualityData, isSubmitted }) => {
    const currentUser = useCurrentUser()

    const isAllowedToSchedule =
        currentUser.primaryRole === ERoles.Provider || currentUser.primaryRole === ERoles.OfficeAdmin

    const [memberId, currentNpi] = useMemberInfoFromUrl()
    const { awvSummaries: awvSummaries, userHasFiles } = useSelector(
        (state: { memberProfile: any }) => state.memberProfile
    )

    const awvServiceStatus: IAwvServiceStatus = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.awvServiceStatus
    )

    const memberProfileDetails: IMemberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const [awvForms, setAwvForms] = useState<IAwvSummary[]>(awvSummaries.awvSummaries)

    React.useEffect(() => {
        setAwvForms(awvSummaries.awvSummaries)
        dispatch(getAwvStatus({ memberId: memberId, npi: memberProfileDetails?.npi })) // Year???: moment().year() })
    }, [awvSummaries])

    const [isReferralModalShown, setIsReferralModalShown] = useState<boolean>(false)
    const [isFeedbackModalShown, setIsFeedbackModalShown] = useState<boolean>(false)
    const [isAwvFeedbackModalShown, setIsAwvFeedbackModalShown] = useState<boolean>(false)
    const [currentRowIndex, setCurrentRowIndex] = useState<number>(-1)
    const [currentSpeciality, setCurrentSpeciality] = useState<string>('')

    const [qualities, setQualities] = useState<IPointsInCareQuality[]>(qualityData)

    const [deleteInProgress, setDeleteInProgress] = useState<[number, number]>([-1, -1])
    const [uploadInProgress, setUploadInProgress] = useState<number>(-1)
    const [downloadInProgress, setDownloadInProgress] = useState<[number, number]>([-1, -1])

    const adjustToClient = (tableData: any) => {
        const clientItems = qualityData.map((tableDataItem: any) => {
            const clientItem = {
                ...tableDataItem,
                dateOfService: toLocalDate(tableDataItem.dateOfService)
            }
            return clientItem
        })

        return clientItems
    }

    const [file, setFile] = useState<File | null>(null)
    const { getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: (acceptedFiles) => setFile(acceptedFiles[0]),
        accept: {
            'application/pdf': ['.pdf']
        }
    })

    const uploadFile = async (file: File) => {
        // handle file upload for AWV as a special case
        if (!file) return

        if (isFileOutsideMaxSizeLimit(file)) {
            return
        }

        if (uploadingAwvFile) {
            dispatch(
                saveAwvFormDocumentResponse({
                    memberId: memberId,
                    npi: currentNpi,
                    year: moment().year(),
                    file: file
                })
            ).then(() => {
                setUploadingAwvFile(false)
                dispatch(getMemberNpiAwvSummaries({ memberId: memberId, npi: memberProfileDetails?.npi }))
            })
            return
        }
        // *****************************

        // handle gap upload
        const args: IGapUploadArgs = {
            file: file,
            pointOfCareId: qualities[currentRowIndex].targetedConditionQualityId,
            gapInCareId: qualities[currentRowIndex].gapInCareId
        }

        const rowIndex = currentRowIndex

        setUploadInProgress(rowIndex)
        dispatch(uploadGapForm(args)).then((res) => {
            setUploadInProgress(-1)
            if (res.meta.requestStatus === 'fulfilled') {
                const response = res.payload as IUpdateGapFormResponse
                const updatedQualities = [...qualities]
                const qualityDocuments = [...qualities[rowIndex].qualityDocuments]
                const qualityDocumentsWithAppend = [...qualityDocuments, response.documentInfo]
                updatedQualities[rowIndex].qualityDocuments = qualityDocumentsWithAppend
                setQualities(updatedQualities)
            }
        })
    }

    React.useEffect(() => {
        if (file && !isFileOutsideMaxSizeLimit(file)) {
            uploadFile(file)
        }
    }, [file])

    React.useEffect(() => {
        setQualities(adjustToClient(qualityData))
        dispatch(getMemberNpiAwvSummaries({ memberId: memberId, npi: memberProfileDetails?.npi }))
    }, [qualityData])

    const getIndexOfProperty = (row: any, property: string) => {
        return Object.keys(row).indexOf(property)
    }

    const handleReferral = (qualityRow: any, idx: number) => {
        setIsReferralModalShown(true)
        setCurrentRowIndex(idx)

        return
        // TODO: referral dialog
        const referralIndex = getIndexOfProperty(qualityRow, 'referral')
        const referral = qualityRow[referralIndex]
        const referralStatus = qualityRow[referralIndex + 1]
    }

    const handleFeedback = (qualityRow: any, idx: number) => {
        setIsFeedbackModalShown(true)
        setCurrentRowIndex(idx)
        setCurrentSpeciality(qualityRow.diagnosisCode)
        return
        // TODO: feedback dialog
        const referralIndex = getIndexOfProperty(qualityRow, 'feedback')
        const referral = qualityRow[referralIndex]
        const referralStatus = qualityRow[referralIndex + 1]
    }

    const handleAwvFeedback = (statusId: string) => {
        setIsAwvFeedbackModalShown(true)
    }

    const handleUpload = (qualityRow: IPointsInCareQuality, idx: number) => {
        setCurrentRowIndex(idx)
        setCurrentSpeciality(qualityRow.diagnosisCode)
        open()
    }

    const handleUpdateReferral = (referral: ISpecialist, idx: number) => {
        // update the current row with the referral and refresh
        const updatedQualityData = [...qualities]
        updatedQualityData[idx].referral = referral
        setQualities(updatedQualityData)
        const updateReferralArgs: IUpdateReferralRequest = {
            memberId: memberId,
            npi: currentNpi,
            pointsOfCareId: qualities[idx].targetedConditionQualityId,
            gapInCareId: qualities[idx].gapInCareId,
            pointsInCareGapId: qualities[idx].pointOfCareGapId,
            referralId: referral.id
        }

        dispatch(updateReferral(updateReferralArgs))
    }

    const handleAddQualityFeedback = (text: string, idx: number) => {
        // update the current row with the feedback and refresh
        dispatch(
            addQualityFeedback({
                npi: currentNpi,
                memberId: memberId,
                pointOfCareId: qualities[idx].targetedConditionQualityId,
                gapInCareId: qualities[idx].gapInCareId,
                pointOfCareGapId: qualities[idx].pointOfCareGapId,
                feedbackText: text
            } as IAddQualityFeedbackRequest)
        )
    }

    const handleAddAwvFeedback = (text: string, awvStatusId: string) => {
        // update the current row with the feedback and refresh
    }

    const handleDeleteFile = (
        qualityDocumentFormId: string,
        documentId: string,
        docIdx: number,
        qualityIdx: number
    ) => {
        setDeleteInProgress([qualityIdx, docIdx])
        dispatch(
            deleteGapForm({
                pointOfCareDocumentId: qualityDocumentFormId,
                documentId: documentId
            } as IDeletePointsInCareDocument)
        ).then(() => {
            // delete the gap file here
            setDeleteInProgress([-1, -1])
            const updatedQualityData = [...qualities]
            const qualityDocuments = [...updatedQualityData[qualityIdx].qualityDocuments]
            const updatedQualityDocuments = qualityDocuments.filter((doc) => doc.documentId !== documentId)
            updatedQualityData[qualityIdx].qualityDocuments = updatedQualityDocuments
            setQualities(updatedQualityData)
        })
    }

    const handleDocDownload = (
        documentId: string,
        diagnosisCode: string,
        fileName: string,
        docIndex: number,
        gapIndex: number
    ) => {
        setDownloadInProgress([gapIndex, docIndex])
        dispatch(
            downloadGapsForm({
                documentId: documentId,
                memberId: memberId,
                npi: currentNpi,
                gap: diagnosisCode,
                fileName: fileName
            })
        ).then(() => {
            setDownloadInProgress([-1, -1])
        })
    }

    const [downloadingAwvFile, setDownloadingAwvFile] = React.useState(false)
    const [deleteAwvInProgress, setDeleteAwvInProgress] = React.useState(false)
    const [isScheduleModalVisible, setIsScheduleModalVisible] = useState<boolean>(false)

    const handleAwvDocDownload = () => {
        setDownloadingAwvFile(true)
        if (memberId) {
            dispatch(downloadMemberAwvFile({ npi: currentNpi, memberId })).then(() => {
                setDownloadingAwvFile(false)
            })
        }
    }

    const [uploadingFile, setUploadingFile] = React.useState(false)
    const [uploadingAwvFile, setUploadingAwvFile] = React.useState(false)
    const handleAwvDeleteFile = (year: number) => {
        setDeleteAwvInProgress(true)
        dispatch(
            deleteAwvFormResponse({
                memberId: memberId,
                npi: currentNpi,
                year: year
            })
        ).then(() => {
            const temp = awvForms.filter((item: any) => item.year !== year)
            setAwvForms(temp)
            setDeleteAwvInProgress(false)
        })
    }

    const handleAwvUpload = () => {
        setUploadingAwvFile(true)
        open()
    }

    const handleScheduler = (idx?: number) => {
        setIsScheduleModalVisible(true)
    }

    const handleCloseScheduler = () => {
        setIsScheduleModalVisible(false)
    }

    const handleScheduleAwv = (data: IMemberProfileScheduleAwvData) => {
        dispatch(scheduleAwv(data))
        setIsScheduleModalVisible(false)
    }

    const handleUnscheduleAwv = (data: IMemberProfileUnscheduleAwvData) => {
        dispatch(unscheduleAwv(data))
        setIsScheduleModalVisible(false)
    }

    const isAdmin = () => {
        return currentUser?.primaryRole === ERoles.CallCenterAdmin || currentUser?.primaryRole === ERoles.ProviderRep
    }

    return (
        <>
            <StyledTableBodyRowContainer>
                {isScheduleModalVisible ? (
                    <ScheduleAWVModal
                        npi={currentNpi}
                        memberId={memberId}
                        scheduledDOS={awvServiceStatus.awvScheduleStatus.scheduledDOS}
                        onCancel={handleCloseScheduler}
                        onSaveDate={handleScheduleAwv}
                        onUnschedule={handleUnscheduleAwv}
                        awvStatus={awvServiceStatus.awvScheduleStatus.awvStatus}
                    />
                ) : null}
                {isReferralModalShown && (
                    <ReferralModalDialog
                        onCancel={function (): void {
                            setIsReferralModalShown(false)
                        }}
                        onSelected={function (referral: ISpecialist): void {
                            setIsReferralModalShown(false)
                            handleUpdateReferral(referral, currentRowIndex)
                        }}
                    />
                )}
                {isFeedbackModalShown && (
                    <FeedbackModalDialog
                        onCancel={function (): void {
                            setIsFeedbackModalShown(false)
                        }}
                        onSave={function (text: string): void {
                            setIsFeedbackModalShown(false)
                            handleAddQualityFeedback(text, currentRowIndex)
                        }}
                        request={
                            {
                                memberId: memberId,
                                npi: currentNpi,
                                pointOfCareId: qualities[currentRowIndex].targetedConditionQualityId,
                                pointOfCareGapId: qualities[currentRowIndex].pointOfCareGapId,
                                gapInCareId: qualities[currentRowIndex].gapInCareId
                            } as IQualityFeedbackRequest
                        }
                    />
                )}
                {isAwvFeedbackModalShown && (
                    <AwvFeedbackModalDialog
                        onCancel={function (): void {
                            setIsAwvFeedbackModalShown(false)
                        }}
                        onSave={function (text: string): void {
                            setIsAwvFeedbackModalShown(false)
                            handleAddAwvFeedback(text, awvServiceStatus?.awvScheduleStatus?.awvStatusId)
                        }}
                        request={
                            {
                                memberId: memberId,
                                npi: currentNpi,
                                awvStatusId: awvServiceStatus?.awvScheduleStatus?.awvStatusId
                            } as IAwvFeedbackRequest
                        }
                    />
                )}
                <StyledTableBodyRow>
                    <StyledTableBodyItem key={`bodyItemGapInCareDiagnosis-AWV`}>
                        <StyledIncentiveTitle>Annual Wellness Visit</StyledIncentiveTitle>
                        <StyledUploadedDocsList>
                            {awvForms?.map((form: IAwvSummary, formIdx: number) => {
                                return (
                                    <StyledUploadedDocsListItem key={`formId-${formIdx}`}>
                                        <StyledButton onClick={() => handleAwvDocDownload()}>
                                            <Typography type={TYPOGRAPHY_TYPES.p}>
                                                {`${
                                                    form.$type === 'document'
                                                        ? (form as IAwvFormDocumentResponseSummary).fileName
                                                        : ''
                                                } ${setFormatedDate(form.statusEffective)}`}
                                            </Typography>
                                        </StyledButton>
                                        {deleteAwvInProgress ? (
                                            <SmallSpinner />
                                        ) : (
                                            awvServiceStatus?.awvScheduleStatus?.awvStatus === 'Not Started' ||
                                            awvServiceStatus?.awvScheduleStatus?.awvStatus === 'Scheduled' ||
                                            (awvServiceStatus?.awvScheduleStatus?.awvStatus === 'Pending' && (
                                                <StyledButton onClick={() => handleAwvDeleteFile(form.year)}>
                                                    <CustomSvgIcon iconSet={{ icon: trashCanIcon }} svg></CustomSvgIcon>
                                                </StyledButton>
                                            ))
                                        )}
                                    </StyledUploadedDocsListItem>
                                )
                            })}
                        </StyledUploadedDocsList>
                        {uploadingAwvFile ? (
                            <SmallSpinner />
                        ) : (
                            (awvServiceStatus?.awvScheduleStatus?.awvStatus === 'Not Started' ||
                                awvServiceStatus?.awvScheduleStatus?.awvStatus === 'Scheduled' ||
                                awvServiceStatus?.awvScheduleStatus?.awvStatus === 'Pending') && (
                                <StyledUploadButtonContainer>
                                    <button onClick={(e) => handleAwvUpload()}>
                                        <CustomSvgIcon iconSet={{ icon: uploadIcon }} svg></CustomSvgIcon>
                                    </button>
                                    <a onClick={(e) => handleAwvUpload()}>Upload PDF</a>
                                </StyledUploadButtonContainer>
                            )
                        )}
                        {/* <StyledApproveRejectButtonsContainer>
                            <StyledRejectButton>
                                <CustomSvgIcon iconSet={{ icon: rejectIcon }} svg></CustomSvgIcon>
                                Pending Feedback
                            </StyledRejectButton>
                            <StyledApproveButton>
                                <CustomSvgIcon iconSet={{ icon: approveIcon }} svg></CustomSvgIcon>
                                Approve
                            </StyledApproveButton>
                        </StyledApproveRejectButtonsContainer> */}
                    </StyledTableBodyItem>

                    <StyledTableBodyItem key={`bodyItemGapInCareDateOfService-awv`}>
                        <Typography type={TYPOGRAPHY_TYPES.p}>
                            {awvServiceStatus?.lastDateOfService &&
                                moment(awvServiceStatus?.lastDateOfService).format('MM/DD/YYYY')}
                        </Typography>
                    </StyledTableBodyItem>
                    <StyledTableBodyItem key={`bodyItemGapInCareStatus-awv`}>
                        <StyledCalendarContainer>
                            {(awvServiceStatus?.awvScheduleStatus?.awvStatus === 'Not Started' ||
                                awvServiceStatus?.awvScheduleStatus?.awvStatus === 'Scheduled') &&
                                isAllowedToSchedule &&
                                memberProfileDetails?.isEligible && (
                                    <StyledCalendarButton onClick={() => handleScheduler()}>
                                        <CustomSvgIcon iconSet={{ icon: calendarIcon }} svg></CustomSvgIcon>
                                    </StyledCalendarButton>
                                )}
                            <Typography type={TYPOGRAPHY_TYPES.p}>
                                {awvServiceStatus?.awvScheduleStatus?.awvStatus}
                            </Typography>
                        </StyledCalendarContainer>
                        <StyledDateContainer>
                            <Typography type={TYPOGRAPHY_TYPES.p}>
                                {awvServiceStatus?.awvScheduleStatus?.scheduledDOS &&
                                    moment(awvServiceStatus?.awvScheduleStatus?.scheduledDOS).format('MM/DD/YYYY')}
                            </Typography>
                        </StyledDateContainer>
                    </StyledTableBodyItem>
                    <StyledTableBodyItem>-</StyledTableBodyItem>
                    <StyledTableBodyItem key={`feedback-awv`}>
                        {awvServiceStatus?.awvScheduleStatus && (
                            <StyledFeedbackActionContainer>
                                <button
                                    onClick={(e) => handleAwvFeedback(awvServiceStatus?.awvScheduleStatus?.awvStatusId)}
                                >
                                    <CustomSvgIcon iconSet={{ icon: feedbackIcon }} svg></CustomSvgIcon>
                                </button>
                            </StyledFeedbackActionContainer>
                        )}
                    </StyledTableBodyItem>
                    <input {...getInputProps()} style={{ display: 'none' }} />
                </StyledTableBodyRow>

                {qualities.map((row: IPointsInCareQuality, idx: number) => {
                    return (
                        <StyledTableBodyRow key={`tableBodyRow-${idx}`}>
                            <StyledTableBodyItem key={`bodyItemGapInCareDiagnosis-${idx}`}>
                                <StyledIncentiveTitle>{row.diagnosisCode}</StyledIncentiveTitle>

                                {/* Create docs map here */}

                                <StyledUploadedDocsList>
                                    {row?.qualityDocuments?.map((document: IQualityDocument, docIdx: number) => {
                                        return (
                                            <StyledUploadedQualityDocsListItem>
                                                <StyledButton
                                                    onClick={() =>
                                                        handleDocDownload(
                                                            document.documentId,
                                                            row.diagnosisCode,
                                                            document.documentName,
                                                            docIdx,
                                                            idx
                                                        )
                                                    }
                                                >
                                                    <Typography type={TYPOGRAPHY_TYPES.p}>
                                                        {`${document.documentName} ${setFormatedDate(
                                                            document.dateOfSubmission
                                                        )}`}
                                                    </Typography>
                                                </StyledButton>

                                                {areTuplesEqual(deleteInProgress, [idx, docIdx]) ? (
                                                    <SmallSpinner />
                                                ) : (
                                                    !isSubmitted &&
                                                    !isAdmin() && (
                                                        <StyledButton
                                                            onClick={() =>
                                                                handleDeleteFile(
                                                                    document?.qualityDocumentId,
                                                                    document?.documentId,
                                                                    docIdx,
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            <CustomSvgIcon
                                                                iconSet={{ icon: trashcanIcon }}
                                                                svg
                                                            ></CustomSvgIcon>
                                                        </StyledButton>
                                                    )
                                                )}
                                                {areTuplesEqual(downloadInProgress, [idx, docIdx]) && <SmallSpinner />}
                                            </StyledUploadedQualityDocsListItem>
                                        )
                                    })}
                                </StyledUploadedDocsList>

                                <input {...getInputProps()} style={{ display: 'none' }} />
                                {uploadInProgress == idx ? (
                                    <SmallSpinner />
                                ) : (
                                    !isSubmitted &&
                                    !isAdmin() && (
                                        <StyledUploadButtonContainer>
                                            <button onClick={(e) => handleUpload(row, idx)}>
                                                <CustomSvgIcon iconSet={{ icon: uploadIcon }} svg></CustomSvgIcon>
                                            </button>
                                            <a onClick={(e) => handleUpload(row, idx)}>Upload PDF</a>
                                        </StyledUploadButtonContainer>
                                    )
                                )}
                            </StyledTableBodyItem>

                            <StyledTableBodyItem key={`bodyItemGapInCareDateOfService-${idx}`}>
                                <Typography type={TYPOGRAPHY_TYPES.p}>{row.dateOfService}</Typography>
                            </StyledTableBodyItem>
                            <StyledTableBodyItem key={`bodyItemGapInCareStatus-${idx}`}>
                                <Typography type={TYPOGRAPHY_TYPES.p}>{QualityStatus[row.status]}</Typography>
                            </StyledTableBodyItem>
                            <StyledTableBodyItem key={`bodyItemGapInCareReferral-${idx}`}>
                                <StyledReferralActionContainer>
                                    <StyledReferralContainer>
                                        <button
                                            disabled={isSubmitted || isAdmin()}
                                            onClick={(e) => handleReferral(row, idx)}
                                        >{`${row.referral.lastName}, ${row.referral.firstName}`}</button>
                                        {!isSubmitted && !isAdmin() && (
                                            <button onClick={(e) => handleReferral(row, idx)}>
                                                <CustomSvgIcon iconSet={{ icon: referralIcon }} svg></CustomSvgIcon>
                                            </button>
                                        )}
                                    </StyledReferralContainer>
                                    <button
                                        disabled={isSubmitted || isAdmin()}
                                        onClick={(e) => handleReferral(row, idx)}
                                    >{`${row.referral.specialty}`}</button>
                                </StyledReferralActionContainer>
                            </StyledTableBodyItem>
                            <StyledTableBodyItem key={`bodyItemGapInCareFeedback-${idx}`}>
                                <StyledFeedbackActionContainer>
                                    <button onClick={(e) => handleFeedback(row, idx)}>
                                        <CustomSvgIcon iconSet={{ icon: feedbackIcon }} svg></CustomSvgIcon>
                                    </button>
                                </StyledFeedbackActionContainer>
                            </StyledTableBodyItem>
                            {/* {Object.values(row)                           
                            .map((rowValues: any, innerIdx: number) => {
                                return (
                                    <StyledTableBodyItem key={`bodyItemOption-${innerIdx}`} status={rowValues}>
                                        <Typography type={TYPOGRAPHY_TYPES.p}>{rowValues}</Typography>
                                    </StyledTableBodyItem>
                                )
                            })}  */}
                        </StyledTableBodyRow>
                    )
                })}
            </StyledTableBodyRowContainer>
        </>
    )
}

export default TableBody
