import {
    StyledAWVDigitalAside,
    StyledAWVDigitalAsideContainer,
    StyledAWVDigitalContainer,
    StyledAWVDigitalContainerWrap,
    StyledAWVDigitalLogoImage,
    StyledAWVDigitalNavBarContainer,
    StyledAWVDigitalToC,
    StyledAWVDigitalUserInfoHeader,
    StyledCloseButton,
    StyledSubmitButton,
    StyledSubmitButtonContainer
} from './index.style'
import { FC, useEffect, useLayoutEffect, useState } from 'react'
import SectionTitle from './SectionTitle'
import SectionDescription from './SectionDescription'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AWVDigitalValidationSchema } from './validationSchema'
import { renderRadioSelection, renderVerticalRadioSelection } from './RadioSelection'
import { renderTextArea } from './TextArea'
import { renderCommentedMultipleChoiceList } from './CommentedMultipleChoiceList'
import { useSelector } from 'react-redux'
import {
    MemberProfileState,
    getAwvElectronicForm,
    saveAwvFormElectronicResponse,
    submitAwvFormElectronicResponse
} from 'redux/slices/memberProfile'
import { dispatch, useAppSelector } from 'redux/store'
import { renderDropDownList } from './DropDownList'
import { renderDropDownListBoolean } from './DropDownListBoolean'
import { renderLanguagePicker } from './LanguagePicker'
import { renderDatePicker } from './DatePicker'
import { renderPhoneNumberField } from './PhoneNumberField'
import { renderAgeField } from './AgeField'
import { renderHeightField } from './HeightField'
import { renderNumericField } from './NumericField'
import { renderBodyMassIndexField } from './BodyMassIndexField'
import { renderTextField } from './TextField'
import { ISectionElement } from './index.models'
import { renderTwoColumnPanel } from './TwoColumnPanel'
import { renderPainAssessmentSelection } from './PainAssessmentSelection'
import { renderCommentedScoredMultipleChoiceList } from './CommentedScoredMultipleChoiceList'
import { renderNullableRadioBoolean, renderRadioBoolean } from './RadioBoolean'
import { renderStandaloneLabel } from './StandaloneLabel'
import { renderRadioBooleanList } from './RadioBooleanList'
import { renderScoredMultipleChoiceList } from './ScoredMultipleChoiceList'
import { evaluateCondition } from './ConditionEvaluator'
import { renderDatedBooleanList } from './DatedBooleanList'
import { renderObjectList } from './ObjectList'
import { renderCheckBoxList } from './CheckBoxList'
import { renderOtherConditionsList } from './OtherConditionsList'
import { renderRepeater } from './Repeater'
import Spinner from 'components/Spinner'
import useDebounce from 'hooks/useDebounce'
import { useParams } from 'react-router-dom'
import MessageBox from 'components/MessageBox'
import { UI_COLORS } from 'theme/constants'
import useHeadsObserver from 'hooks/useHeadsObserver'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { IMemberProfileDetails } from 'pages/hcp/users/create/index.models'
import { isElegibleBadge, notElegibleBadge } from '../information/Personal/icons'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { StyledBadgeContainer, StyledEligibilityBadgeContainer } from '../information/Personal/index.style'
import { renderPreventiveScreeningsList } from './PreventiveScreeningsList'
import { renderIcdDiagnosisPicker } from './IcdDiagnosisPicker'
import { renderHccCodeDescriptionPicker } from './HccCodeDescriptionPicker'
import { renderDiagnosisHeading } from './DiagnosisHeading'
import ConfirmationBox from 'components/ConfirmationBox'
import AWVFeedback from '../information/AWV/Feedback'
import { FeedbackModalDialog } from '../information/PointsInCare/ReviewTable/FeedbackDialog'
import { hideMainWindowScrollbars } from 'utilities/style'
import {
    FeedbackState,
    addAwvFeedback,
    getAwvFeedback,
    hasNewAwvFeedback,
    markAwvFeedbackRead
} from 'redux/slices/feedback'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import useCurrentUser from 'hooks/useCurrentUser'
import { Pulse8State, fetchProviderNpis } from 'redux/slices/pulse8'
import { ERoles } from 'models/enums/role'
import NonPCPSubmissionWarning from '../information/AWV/NonPCPSubmissionWarning'

interface IElectronicAnnualWellnessVisitFormProps {
    readOnly: boolean
}

export const ElectronicAnnualWellnessVisitForm: FC<IElectronicAnnualWellnessVisitFormProps> = ({ readOnly }) => {
    const { memberId, npi, year } = useParams()

    const { electronicAwvForm } = useSelector((state: { memberProfile: MemberProfileState }) => state.memberProfile)

    const memberProfileDetails: IMemberProfileDetails = useSelector(
        (state: { memberProfile: MemberProfileState }) => state.memberProfile.memberProfileDetails
    )

    const [awvFormLoaded, setAwvFormLoaded] = useState(false)
    const [populated, setPopulated] = useState(false)

    const [statusId, setStatusId] = useState(null)

    const [confirmSubmitShown, setConfirmSubmitShown] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const methods = useForm({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(AWVDigitalValidationSchema)
    })

    useLayoutEffect(() => {
        if (!awvFormLoaded) {
            dispatch(getAwvElectronicForm({ memberId, npi, year })).then(() => setAwvFormLoaded(true))
        }
    }, [awvFormLoaded])

    useEffect(() => {
        if (awvFormLoaded && electronicAwvForm) {
            methods.reset({ ...methods.watch(), ...electronicAwvForm.response })
            setPopulated(true)
        }
    }, [awvFormLoaded, electronicAwvForm, methods])

    const saveAwvFormElectronicResponseArgs = useDebounce(
        populated
            ? JSON.stringify({
                  memberId,
                  npi,
                  year,
                  body: methods.watch(),
                  statusId
              })
            : null,
        1000
    )

    useEffect(() => {
        if (!readOnly && !submitting && !submitted && saveAwvFormElectronicResponseArgs) {
            dispatch(saveAwvFormElectronicResponse(JSON.parse(saveAwvFormElectronicResponseArgs))).then((x) => {
                if (x.type === saveAwvFormElectronicResponse.fulfilled.type) {
                    if (x.payload.awvSummary.awvId !== awvId) {
                        setAwvId(x.payload.awvSummary.awvId)
                    }
                    setStatusId(x.payload.awvSummary.statusId)
                }
            })
        }
    }, [readOnly, saveAwvFormElectronicResponseArgs])

    const elementRenderers: any = {
        ageField: renderAgeField,
        bodyMassIndexField: renderBodyMassIndexField,
        checkBoxList: renderCheckBoxList,
        commentedMultipleChoiceList: renderCommentedMultipleChoiceList,
        commentedScoredMultipleChoiceList: renderCommentedScoredMultipleChoiceList,
        datedBooleanList: renderDatedBooleanList,
        datePicker: renderDatePicker,
        diagnosisHeading: renderDiagnosisHeading,
        dropDownList: renderDropDownList,
        dropDownListBoolean: renderDropDownListBoolean,
        hccCodeDescriptionPicker: renderHccCodeDescriptionPicker,
        heightField: renderHeightField,
        icdDiagnosisPicker: renderIcdDiagnosisPicker,
        languagePicker: renderLanguagePicker,
        nullableRadioBoolean: renderNullableRadioBoolean,
        numericField: renderNumericField,
        objectList: renderObjectList,
        otherConditionsList: renderOtherConditionsList,
        painAssessmentSelection: renderPainAssessmentSelection,
        phoneNumberField: renderPhoneNumberField,
        preventiveScreeningsList: renderPreventiveScreeningsList,
        radioBoolean: renderRadioBoolean,
        radioBooleanList: renderRadioBooleanList,
        radioSelection: renderRadioSelection,
        repeater: renderRepeater,
        scoredMultipleChoiceList: renderScoredMultipleChoiceList,
        standaloneLabel: renderStandaloneLabel,
        textArea: renderTextArea,
        textField: renderTextField,
        twoColumnPanel: renderTwoColumnPanel,
        verticalRadioSelection: renderVerticalRadioSelection
    }

    const renderSectionElement = (element: ISectionElement, key: string, parentName?: string) => {
        const renderer = elementRenderers[element.$type] as (
            element: ISectionElement,
            key: string,
            renderSectionElement: (element: ISectionElement, key: string, parentName?: string) => JSX.Element,
            parentName?: string
        ) => JSX.Element
        return (
            evaluateCondition(element.condition, methods.watch, parentName) &&
            renderer &&
            renderer(element, key, renderSectionElement, parentName)
        )
    }

    const currentUser = useCurrentUser()
    const provider = useAppSelector((state: { pulse8: Pulse8State }) =>
        state.pulse8.providerNpis.find((x) => x.npi === npi)
    )
    useEffect(() => {
        if (!provider) {
            dispatch(fetchProviderNpis())
        }
    }, [provider])
    const [isSubmitByNonPCP, setSubmitByNonPCP] = useState(false)

    const handleSubmit = () => {
        const isPCP = currentUser?.primaryRole === ERoles.Provider
        isPCP ? setConfirmSubmitShown(true) : setSubmitByNonPCP(true)
    }

    const submit = () => {
        setConfirmSubmitShown(false)
        setSubmitting(true)
        dispatch(
            submitAwvFormElectronicResponse({
                memberId,
                npi,
                year,
                body: methods.watch(),
                statusId
            })
        ).then((x) => {
            setSubmitting(false)
            if (x.type === submitAwvFormElectronicResponse.fulfilled.type) {
                setSubmitted(true)
            }
        })
    }

    const close = () => {
        window.close()
    }
    /** Progress Bar Code */

    const [progressBarValue, setProgressBarValue] = useState(0)

    const handleNavigation = (e: any) => {
        const element = document.getElementById(e)
        // const element = document.querySelector(e)
        const progressBar = (100 / dropDownListData.length) * e
        setProgressBarValue(progressBar)

        if (element) {
            // element.scrollIntoView({ block: 'start', behavior: 'smooth', inline: 'start' })
            scrollToTargetAdjusted(element)
        }
    }

    const scrollToTargetAdjusted = (element: any) => {
        let headerOffset = 100
        let elementPosition = element.getBoundingClientRect().top
        let offsetPosition = elementPosition + window.scrollY - headerOffset

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        })
    }

    const dropDownListData = electronicAwvForm?.form?.sections

    /** End Progress Bar Code */

    /**
     * Navigation Code
     */

    const [headings, setHeadings] = useState([])
    const { activeId } = useHeadsObserver()

    const getHeadings = () => {
        const sections = electronicAwvForm?.form?.sections.filter((x) => x.hideInNavigationSidebar === false)

        const elements = Array.from(sections).map((section) => ({
            text: section.title
        }))

        return elements
    }

    useEffect(() => {
        if (awvFormLoaded && electronicAwvForm) {
            setTimeout(() => {
                const saveHeads = getHeadings()
                setHeadings(saveHeads)
            }, 2000)
        }
    }, [awvFormLoaded, electronicAwvForm])

    /**
     * End Navigation Code
     */

    useEffect(() => {
        const index = headings?.findIndex((head: any, idx: number) => {
            return activeId === head?.text
        })

        if (index === 15) {
            let syncItem = document.getElementById(String(headings.length - 1))
            syncItem.scrollIntoView(true)
        }

        if (index === 12 || index === 14) {
            let syncItem = document.getElementById('2')
            syncItem.scrollIntoView(false)
        }
    }, [activeId])

    const elegibilityBadge = () => {
        return (
            <StyledEligibilityBadgeContainer>
                <Typography type={TYPOGRAPHY_TYPES.p}>
                    {memberProfileDetails?.benefits[0]?.isEligible ? 'Eligible' : 'Ineligible'}
                </Typography>
                <StyledBadgeContainer>
                    <CustomSvgIcon
                        iconSet={{
                            icon: memberProfileDetails?.benefits[0]?.isEligible ? isElegibleBadge : notElegibleBadge
                        }}
                        svg
                    ></CustomSvgIcon>
                </StyledBadgeContainer>
            </StyledEligibilityBadgeContainer>
        )
    }

    /**
     * Feedback
     */

    const [awvId, setAwvId] = useState<string>(null)

    useEffect(() => {
        if (electronicAwvForm?.awvId) {
            setAwvId(electronicAwvForm.awvId)
        }
    }, [electronicAwvForm])

    useEffect(() => {
        if (awvId) {
            dispatch(hasNewAwvFeedback({ awvId }))
        }
    }, [awvId])

    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false)
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [feedbackSending, setFeedbackSending] = useState(false)

    const { awvFeedback, awvHasNewFeedback } = useSelector((state: { feedback: FeedbackState }) => state.feedback)

    const viewFeedbackEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.ViewFeedback)
    const handleFeedback = () => {
        viewFeedbackEvent()

        setFeedbackLoading(true)
        setShowFeedbackDialog(true)
        dispatch(markAwvFeedbackRead({ awvId }))
        dispatch(getAwvFeedback({ awvId })).then(() => {
            setFeedbackLoading(false)
        })
    }
    const sentFeedbackEvent = usePostCustomEvent(pageTitle.PatientsAWV, customEvents.SendFeedback)
    const handleSendFeedback = (feedbackToSend: string) => {
        sentFeedbackEvent()
        setFeedbackSending(true)
        dispatch(
            addAwvFeedback({
                awvId,
                parentId: null,
                feedbackText: feedbackToSend
            })
        ).then(() => {
            setFeedbackSending(false)
        })
    }

    /**
     * End Feedback
     */

    useEffect(() => {
        hideMainWindowScrollbars(confirmSubmitShown || submitted || showFeedbackDialog || isSubmitByNonPCP)
    }, [confirmSubmitShown, submitted, showFeedbackDialog, isSubmitByNonPCP])

    return awvFormLoaded && electronicAwvForm ? (
        <>
            {isSubmitByNonPCP && (
                <NonPCPSubmissionWarning providerInfo={provider} onClose={() => setSubmitByNonPCP(false)} />
            )}

            {confirmSubmitShown && (
                <ConfirmationBox
                    title="Annual Wellness Visit"
                    body={`Would you like to submit the (${year}) Annual Wellness Visit form for review?`}
                    confirmButtonLabel="Submit"
                    confirmButtonColor={UI_COLORS.light_green}
                    onConfirm={submit}
                    onCancel={() => setConfirmSubmitShown(false)}
                />
            )}

            {submitted && (
                <MessageBox
                    title="Annual Wellness Visit"
                    body="Your electronic Annual Wellness Visit form has been submitted for review."
                    closeButtonLabel="OK"
                    closeButtonColor={UI_COLORS.light_blue2}
                    onClose={close}
                />
            )}

            {showFeedbackDialog ? (
                <FeedbackModalDialog
                    feedback={awvFeedback[awvId] ?? []}
                    isLoading={feedbackLoading}
                    isSending={feedbackSending}
                    onSend={handleSendFeedback}
                    onClose={() => setShowFeedbackDialog(false)}
                />
            ) : null}

            <StyledAWVDigitalContainerWrap>
                {/* Table of Contents */}
                <StyledAWVDigitalAside>
                    <StyledAWVDigitalLogoImage />
                    <StyledAWVDigitalAsideContainer>
                        <StyledAWVDigitalToC>
                            {headings.map((item: any, idx: number) => {
                                return (
                                    <li id={`${idx}`} key={`${item.text}-toc`}>
                                        <button
                                            style={{
                                                fontWeight: activeId === item.text ? 'bold' : 'normal',
                                                color: activeId === item.text ? '#2281C4' : '#73839C'
                                            }}
                                            onClick={() => handleNavigation(item.text)}
                                        >
                                            {item.text}
                                        </button>
                                    </li>
                                )
                            })}
                        </StyledAWVDigitalToC>
                    </StyledAWVDigitalAsideContainer>
                </StyledAWVDigitalAside>
                {/* End Table of Contents */}

                {/* Top Navigation */}
                <StyledAWVDigitalNavBarContainer>
                    <StyledAWVDigitalUserInfoHeader
                        style={{ height: activeId !== 'Annual Wellness Visit' ? '64px' : '' }}
                    >
                        <Typography type={TYPOGRAPHY_TYPES.h1}>{electronicAwvForm.response.patientName}</Typography>
                    </StyledAWVDigitalUserInfoHeader>
                </StyledAWVDigitalNavBarContainer>
                {/* End Top Navigation */}

                <StyledAWVDigitalContainer>
                    <fieldset disabled={readOnly || submitting || submitted}>
                        <FormProvider {...methods}>
                            <form onSubmit={submit}>
                                {electronicAwvForm &&
                                    electronicAwvForm.form.sections.map((section, sectionIndex) => (
                                        <div
                                            key={`section-${sectionIndex}`}
                                            id={section.title}
                                            style={{ marginBottom: '48px' }}
                                        >
                                            <SectionTitle title={section.title} />
                                            {section.description && (
                                                <SectionDescription description={section.description} />
                                            )}
                                            {section.elements &&
                                                section.elements.map((x, elementIndex) =>
                                                    renderSectionElement(x, `awvForm-${sectionIndex}-${elementIndex}`)
                                                )}
                                        </div>
                                    ))}
                            </form>
                        </FormProvider>
                    </fieldset>
                    <StyledSubmitButtonContainer>
                        <StyledCloseButton onClick={close}>Close</StyledCloseButton>
                        {!readOnly && (
                            <>
                                <StyledSubmitButton onClick={handleSubmit} disabled={submitting || submitted}>
                                    Submit
                                </StyledSubmitButton>
                                {submitting && !submitted && <Spinner />}
                            </>
                        )}
                    </StyledSubmitButtonContainer>
                    {awvId && (
                        <AWVFeedback
                            openFeedback={handleFeedback}
                            hasNewFeedback={awvHasNewFeedback[awvId]?.hasNewFeedback ?? false}
                            feedbackCount={awvHasNewFeedback[awvId]?.newFeedbackCount ?? 0}
                        />
                    )}
                </StyledAWVDigitalContainer>
            </StyledAWVDigitalContainerWrap>
            {/* <ProgressBar value={progressBarValue} max={100} />
            <NavigationBar setSelection={(e) => handleNavigation(e)} dropdownList={dropDownListData} /> */}
        </>
    ) : (
        <Spinner />
    )
}
