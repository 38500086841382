import styled, { css } from 'styled-components'
import { UI_COLORS } from 'theme'
import { DEVICE, pxToRem } from 'utilities'
import logo from 'assets/logo.png'
import hcplogo from 'assets/hcp_logo.svg'

const { dark_gray, light_gray3, light_gray4, white, light_green } = UI_COLORS

export const StyledAWVDigitalContainer = styled.article`
    width: ${pxToRem(1145)};
    padding-top: ${pxToRem(100)};
    padding-bottom: ${pxToRem(704)};
    padding-left: ${pxToRem(30)};

    fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }
`

export const StyledAWVDigitalTitleContainer = styled.article`
    position: relative;

    h2 {
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};

        letter-spacing: -0.01em;
        color: ${dark_gray};
        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: ${pxToRem(40)};
            height: ${pxToRem(2)};
            background-color: ${dark_gray};
        }
    }
`

export const StyledAWVDigitalDescriptionContainer = styled.article`
    width: ${pxToRem(1102)};

    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(22)};
        color: ${dark_gray};
    }
`

export const StyledAWVDigitalInput = styled.article`
    width: 100%;
    margin-bottom: ${pxToRem(24)};

    display: flex;
    flex-direction: column;

    label {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: ${pxToRem(14)};
        line-height: ${pxToRem(18)};
        color: #293854;

        margin-bottom: ${pxToRem(15)};
    }

    input {
        width: ${pxToRem(400)};
        height: ${pxToRem(50)};
        background: #ffffff;
        border: 1px solid #dee5ef;
        border-radius: ${pxToRem(4)};
        border: 1px solid #73839c;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(16)};
        line-height: ${pxToRem(22)};
        color: #293854;

        padding-left: ${pxToRem(18)};
        padding-right: ${pxToRem(18)};

        :disabled {
            background-color: #f5f5f5;
        }
    }

    small {
        margin: 0;
        color: #b11b1b;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .MuiInputBase-input {
        padding: 0;
        padding-left: ${pxToRem(18)};
    }

    div {
        :before {
            border-bottom: none;
            :hover {
                border-bottom: none;
            }
        }
    }

    article {
        margin-top: ${pxToRem(7)};

        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;

        display: flex;
        align-items: center;

        color: ${light_gray3};
    }

    div {
        div {
            div {
                margin: 0;
            }
        }
    }
`

export const StyledAWVDigitalInputWrap = styled.div`
    display: flex;
    flex-direction: column;

    input {
        width: 100%;

        :nth-child(2) {
            margin-bottom: ${pxToRem(24)};
        }
    }

    ${DEVICE.tablet} {
        input {
            width: ${pxToRem(400)};
        }
    }

    ${DEVICE.desktop} {
        flex-direction: row;
        width: ${pxToRem(880)};

        input {
            :nth-child(2) {
                margin-bottom: ${pxToRem(0)};
            }
        }
    }
`

export const StyledSubmitButtonContainer = styled.article`
    width: ${pxToRem(1145)};
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 20px;

    article {
        height: auto;
        svg {
            height: ${pxToRem(30)};
        }
    }
`

export const StyledSubmitButton = styled.button`
    width: 140px;
    height: 45px;
    margin: 10px 0 30px 20px !important;
    border: none;
    background: ${light_green};
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    cursor: pointer;

    :disabled {
        cursor: default;
        background: ${light_gray4};
    }

    :enabled {
        :active {
            background-color: transparent;
            color: ${light_green};
            border: ${pxToRem(1)} solid ${light_green};
        }
    }
`
export const StyledCloseButton = styled.button`
    width: 140px;
    height: 45px;
    margin: 10px 0 30px 20px !important;
    border: none;
    background-color: ${light_gray3};
    border-radius: 4px;
    color: #ffffff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
`

export const StyledAWVDigitalContainerWrap = styled.article`
    background-color: #f6f9fd;
    padding-left: 235px;
`

export const StyledAWVDigitalNavBarContainer = styled.article`
    background-color: #f7f8fa;
    width: 100%;

    :after {
        z-index: -1;
        position: absolute;
        bottom: 0px;
        content: '';
        display: inline-block;
        width: 100%;
        border-bottom: 2px solid #dee5ef;
    }

    position: relative;
    z-index: 2;

    ${DEVICE.desktop} {
        position: fixed;
    }
`

export const StyledAWVDigitalUserInfoHeader = styled.article`
    padding-left: 30px;
    height: 91px;

    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
        margin: 0;
        font-family: 'Work Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        letter-spacing: -0.01em;
        color: #293854;
    }
`

export const StyledAWVDigitalAside = styled.aside<{ open?: boolean; isMirroring?: boolean }>`
    background-color: ${white};
    position: fixed;
    z-index: 10;
    inset: 0px auto 0px 0px;
    width: 100%;
    height: 100%;
    transition: all 500ms ease-in-out 0s;
    top: ${pxToRem(72)};
    left: ${({ open }) => (open ? '0' : '-100%')};
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

    ${(props) => {
        if (props.isMirroring) {
            return css`
                top: ${pxToRem(65)};
            `
        }
    }}

    ${DEVICE.tablet} {
        /* width: ${pxToRem(74)}; */
        width: ${pxToRem(235)};

        left: 0;
        top: 0;

        ${(props) => {
            if (props.isMirroring) {
                return css`
                    top: ${pxToRem(65)};
                `
            }
        }}
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(235)};
    }

    h4 {
        padding-left: ${pxToRem(15)};
        color: #293854;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
`

export const StyledAWVDigitalAsideContainer = styled.nav`
    padding-left: ${pxToRem(20)};
    padding-right: ${pxToRem(20)};
    padding-bottom: ${pxToRem(20)};
`

export const StyledAWVDigitalToC = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    height: ${pxToRem(680)};
    overflow-y: scroll;
    width: 214px;

    ::-webkit-scrollbar {
        display: none;
    }

    :hover {
        ::-webkit-scrollbar {
            display: block;
            width: 4px;
        }

        ::-webkit-scrollbar-track {
            background-color: #f6f9fd;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #73839c;
        }
    }

    li {
        margin-bottom: 16px;
    }

    button {
        border: none;
        background-color: transparent;
        color: #73839c;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        text-align: start;
        cursor: pointer;
    }

    ${DEVICE.desktop_tall} {
        height: auto;
    }
`

export const StyledAWVDigitalLogoImage = styled.article`
    display: none;
    width: ${pxToRem(40)};
    height: ${pxToRem(40)};

    background-repeat: no-repeat;
    background-size: contain;

    ${DEVICE.tablet} {
        display: block;
        margin-left: ${pxToRem(15)};
        margin-top: ${pxToRem(15)};
        margin-bottom: ${pxToRem(50)};
        background-image: url(${logo});
    }

    ${DEVICE.desktop} {
        width: ${pxToRem(240)};
        height: ${pxToRem(40)};
        margin-left: ${pxToRem(20)};
        margin-top: ${pxToRem(30)};
        background-image: url(${hcplogo});
    }
`
