import { FC, useEffect, useState } from 'react'
import {
    StyledAWVFormsListBody,
    StyledAWVFormsListBodyItem,
    StyledAWVFormsListContainer,
    StyledAWVFormsListTableHeader,
    StyledAWVFormsListTableHeaderItem,
    StyledAWVStatusDropdownContainer,
    StyledCodingActionBtn,
    StyledCodingActionsContainer,
    StyledCodingButtonPlaceholder,
    awvStatusDropDownListStyleParams
} from './index.style'
import DropDownList, { IOption } from 'components/DropDownList'
import { setFormattedDateWithSlash } from 'components/UsersTable/index.utils'
import { IReviewableAwvForm } from 'pages/hcp/members/information/PointsInCare/PointsInCare.models'
import { SortDirection } from 'models/sortColumn'
import { IHeaderInfo } from 'models/headerInfo'
import CustomSvgIcon from 'components/CustomSvgIcon'
import { arrow } from 'components/MemberOutreachTable/icons'
import { docsIcon, feedbackIcon, feedbackIndicatorIcon } from './icons'
import SupportingDocsModal, { SupportingDocsRequest } from './SupportingDocsModal'
import { hideMainWindowScrollbars } from 'utilities/style'
import { FeedbackModalDialog } from 'pages/hcp/members/information/PointsInCare/ReviewTable/FeedbackDialog'
import { useSelector } from 'react-redux'
import { dispatch } from 'redux/store'
import { FeedbackState, addAwvFeedback, getAwvFeedback, markAwvFeedbackRead } from 'redux/slices/feedback'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'

interface IReviewableAwvFormsListProps {
    reviewableAwvForms: IReviewableAwvForm[]
    sortColumn: string
    sortDirection: SortDirection
    sortCallback(column: string, direction: SortDirection): void
    openReviewableAwvForm(form: IReviewableAwvForm): void
    setStatus(form: IReviewableAwvForm, status: string): void
    isBusy: boolean
    isReadOnly: boolean
}

const AWVFormListHeaderItems: IHeaderInfo[] = [
    { displayName: 'Patient', fieldName: 'Patient' },
    { displayName: 'Year', fieldName: 'Year' },
    { displayName: 'Provider', fieldName: 'Provider' },
    { displayName: 'Provider NPI', fieldName: 'ProviderNpi' },
    { displayName: 'Type', fieldName: 'Type' },
    { displayName: 'Submission Date', fieldName: 'SubmissionDate' },
    { displayName: 'Status', fieldName: 'Status' },
    { displayName: 'Status Effective', fieldName: 'StatusEffective' },
    { displayName: 'Actions', fieldName: 'actions' }
]

const submittedOptions = [
    { label: 'Submitted', value: 'Submitted' },
    { label: 'In Review', value: 'InReview' }
]

const inReviewOptions = [
    { label: 'In Review', value: 'InReview' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Pending', value: 'Pending' }
]

const pendingOptions = [
    { label: 'Pending', value: 'Pending' },
    { label: 'In Review', value: 'InReview' }
]

const approvedOptions = [{ label: 'Approved', value: 'Approved' }]

const optionsByStatus: any = {
    Submitted: submittedOptions,
    InReview: inReviewOptions,
    Pending: pendingOptions,
    Approved: approvedOptions
}

const ReviewableAwvFormsList: FC<IReviewableAwvFormsListProps> = ({
    reviewableAwvForms,
    sortColumn,
    sortDirection,
    sortCallback,
    openReviewableAwvForm,
    setStatus,
    isBusy,
    isReadOnly
}) => {
    const [supportingDocsIsOpen, setSupportingDocsIsOpen] = useState<boolean>(false)
    const [request, setRequest] = useState<SupportingDocsRequest>({ memberId: null, npi: '', year: null })
    const viewFeedbackEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ViewFeedback)
    const sentFeedbackEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.SendFeedback)

    useEffect(() => {
        hideMainWindowScrollbars(supportingDocsIsOpen)
    }, [supportingDocsIsOpen])

    const handleColumnClick = (column: string) => {
        sortCallback(
            column,
            column === sortColumn
                ? sortDirection === SortDirection.ascending
                    ? SortDirection.descending
                    : SortDirection.ascending
                : SortDirection.ascending
        )
    }

    const handleSetStatus = (form: IReviewableAwvForm, status: string) => {
        if (form.status !== status) {
            setStatus(form, status)
        }
    }
    const viewEncounterDocEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ViewEncounterDocuments)
    const handleSupportingDocs = (memberId: number, npi: string, year: number) => {
        if (!memberId || !npi || !year) return

        setRequest({
            memberId: memberId,
            npi: npi,
            year: year
        })
        setSupportingDocsIsOpen(true)
        viewEncounterDocEvent()
    }

    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState<boolean>(false)
    const [feedbackAwvId, setFeedbackAwvId] = useState('')
    const [feedbackLoading, setFeedbackLoading] = useState(false)
    const [feedbackSending, setFeedbackSending] = useState(false)
    const { awvFeedback } = useSelector((state: { feedback: FeedbackState }) => state.feedback)

    const handleFeedback = (id: string) => {
        viewFeedbackEvent()
        setFeedbackAwvId(id)
        setFeedbackLoading(true)
        setIsFeedbackModalOpen(true)
        dispatch(
            markAwvFeedbackRead({
                awvId: id
            })
        )
        dispatch(
            getAwvFeedback({
                awvId: id
            })
        ).then(() => {
            setFeedbackLoading(false)
        })
    }

    const handleSendFeedback = (feedbackToSend: string) => {
        sentFeedbackEvent()

        setFeedbackSending(true)
        dispatch(
            addAwvFeedback({
                awvId: feedbackAwvId,
                parentId: null,
                feedbackText: feedbackToSend
            })
        ).then(() => {
            setFeedbackSending(false)
        })
    }

    return (
        <>
            {supportingDocsIsOpen ? (
                <SupportingDocsModal onCancel={() => setSupportingDocsIsOpen(false)} request={request} />
            ) : (
                ''
            )}

            {isFeedbackModalOpen ? (
                <FeedbackModalDialog
                    feedback={awvFeedback[feedbackAwvId] ?? []}
                    isLoading={feedbackLoading}
                    isSending={feedbackSending}
                    onSend={handleSendFeedback}
                    onClose={() => setIsFeedbackModalOpen(false)}
                />
            ) : null}

            <StyledAWVFormsListContainer>
                <StyledAWVFormsListTableHeader>
                    {AWVFormListHeaderItems.map((header: IHeaderInfo, idx: number) => {
                        return (
                            <StyledAWVFormsListTableHeaderItem
                                key={`awvFormHeaderItem-${header}-${idx}`}
                                onClick={() => handleColumnClick(header.fieldName)}
                                active={sortDirection === SortDirection.ascending}
                            >
                                {header.displayName}
                                {sortColumn === header.fieldName && (
                                    <CustomSvgIcon iconSet={{ icon: arrow }} svg></CustomSvgIcon>
                                )}
                            </StyledAWVFormsListTableHeaderItem>
                        )
                    })}
                </StyledAWVFormsListTableHeader>
                {reviewableAwvForms.map((item: IReviewableAwvForm, idx: number) => {
                    return (
                        <StyledAWVFormsListBody key={`AWVFormRow-${item.memberId}-${idx}`}>
                            <StyledAWVFormsListBodyItem isBusy={isBusy} onClick={() => openReviewableAwvForm(item)}>
                                {item.patient}
                            </StyledAWVFormsListBodyItem>
                            <StyledAWVFormsListBodyItem>{item.year}</StyledAWVFormsListBodyItem>
                            <StyledAWVFormsListBodyItem>{item.provider}</StyledAWVFormsListBodyItem>
                            <StyledAWVFormsListBodyItem>{item.providerNpi}</StyledAWVFormsListBodyItem>
                            <StyledAWVFormsListBodyItem>{item.type}</StyledAWVFormsListBodyItem>
                            <StyledAWVFormsListBodyItem>
                                {setFormattedDateWithSlash(item.submissionDate.toString())}
                            </StyledAWVFormsListBodyItem>
                            <StyledAWVFormsListBodyItem>
                                <StyledAWVStatusDropdownContainer>
                                    {isReadOnly ? (
                                        item.status
                                    ) : (
                                        <DropDownList
                                            key={`statusFor-${idx}`}
                                            options={(optionsByStatus[item.status] ?? []) as IOption[]}
                                            selectedValue={item.status}
                                            setSelectedValue={(value) => handleSetStatus(item, value)}
                                            styleParams={awvStatusDropDownListStyleParams}
                                            isDisabled={isBusy}
                                            isReadOnly={isBusy}
                                        />
                                    )}
                                </StyledAWVStatusDropdownContainer>
                            </StyledAWVFormsListBodyItem>
                            <StyledAWVFormsListBodyItem>
                                {setFormattedDateWithSlash(item.statusEffective.toString())}
                            </StyledAWVFormsListBodyItem>
                            <StyledAWVFormsListBodyItem>
                                <StyledCodingActionsContainer>
                                    {item.hasSupportingDocuments ? (
                                        <StyledCodingActionBtn
                                            onClick={() =>
                                                handleSupportingDocs(item.memberId, item.providerNpi, item.year)
                                            }
                                        >
                                            <CustomSvgIcon iconSet={{ icon: docsIcon }} svg></CustomSvgIcon>
                                        </StyledCodingActionBtn>
                                    ) : (
                                        <StyledCodingButtonPlaceholder></StyledCodingButtonPlaceholder>
                                    )}
                                    <StyledCodingActionBtn
                                        onClick={() => handleFeedback(item.awvId)}
                                        hasNewFeedback={item.hasNewFeedback}
                                    >
                                        <div>
                                            <CustomSvgIcon
                                                iconSet={{ icon: feedbackIndicatorIcon }}
                                                svg
                                            ></CustomSvgIcon>
                                        </div>
                                        <CustomSvgIcon iconSet={{ icon: feedbackIcon }} svg></CustomSvgIcon>
                                    </StyledCodingActionBtn>
                                </StyledCodingActionsContainer>
                            </StyledAWVFormsListBodyItem>
                        </StyledAWVFormsListBody>
                    )
                })}
            </StyledAWVFormsListContainer>
        </>
    )
}

export default ReviewableAwvFormsList
