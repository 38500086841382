import { FC } from 'react'
import { IProviderNpi } from 'redux/slices/pulse8'
import MessageBox from 'components/MessageBox'
import { UI_COLORS } from 'theme/constants'

interface IProps {
    providerInfo: IProviderNpi
    onClose: () => void
}

const NonPCPSubmissionWarning: FC<IProps> = ({ providerInfo, onClose }) => {
    const providerName = `Dr. ${providerInfo.firstName} ${providerInfo.lastName}`
    const titleText = 'Submit Annual Wellness Visit'
    const bodyText = (() => {
        let msg: string
        switch (providerInfo.userState) {
            case 'Active':
                msg = `Please have ${providerName} sign in and submit the form accordingly.`
                break
            case 'Pending':
                msg = `Please have ${providerName} accept the invitation to sign up and submit the form accordingly. (If their invitation has expired, please contact HCP to resend it.)`
                break
            case 'Disabled':
                msg = `Please contact HCP to have the account for ${providerName} activated.`
                break
            default: {
                msg = `Please contact HCP to have an account created for and invitation sent to ${providerName}.`
                break
            }
        }
        return (
            <>
                <p>Electronic Annual Wellness Visit forms can only be submitted by the PCP.</p>
                <p>{msg}</p>
            </>
        )
    })()

    return (
        <MessageBox
            title={titleText}
            body={bodyText}
            closeButtonLabel="OK"
            closeButtonColor={UI_COLORS.light_blue2}
            onClose={onClose}
        />
    )
}

export default NonPCPSubmissionWarning
