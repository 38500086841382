import styled from 'styled-components'
import { UI_COLORS } from 'theme'
import { pxToRem } from 'utilities'

const { dark_gray, white, grey } = UI_COLORS

export const StyledRadioBtnTxtFieldFormContainer = styled.article`
    width: ${pxToRem(1145)};
    margin-bottom: 30px;
`

export const StyledTableFormHeader = styled.ul`
    background-color: #f7f8fa;
    list-style: none;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    padding: 0 30px;
    border: 1px solid #dee5ef;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`

export const StyledTableFormItem = styled.li`
    padding: 11px 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #73839c;
`

export const StyledTableFormBody = styled.ul`
    background-color: #ffffff;
    list-style: none;
    height: 86px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
    padding: 0 30px;
    border-right: 1px solid #dee5ef;
    border-left: 1px solid #dee5ef;
    border-bottom: 1px solid #dee5ef;
`

export const StyledTableFormBodyItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #293854;
`

export const StyledTableFormInputContainer = styled.article`
    input {
        width: 400px;
        height: 50px;
        border: 1px solid #73839c;
        border-radius: 4px;

        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;

        color: #394a64;

        outline: none;
        padding: 0 10px;
    }
`
