import useMemberProfileLookupData from 'hooks/useMemberProfileLookupData'
import { FC } from 'react'
import { DropDownList } from '../DropDownList'
import { ILanguagePicker, ISectionElement } from '../index.models'

interface ILanguagePickerProps {
    name: string
    label: string
    subLabel: string
    placeholder: string
}

const LanguagePicker: FC<ILanguagePickerProps> = ({ name, label, subLabel, placeholder }) => {
    const memberProfileLookupData = useMemberProfileLookupData()

    return (
        memberProfileLookupData?.languages && (
            <DropDownList
                name={name}
                options={memberProfileLookupData.languages.map((x) => ({ label: x.name, value: x.name }))}
                label={label}
                subLabel={subLabel}
                placeholder={placeholder}
            />
        )
    )
}

export const renderLanguagePicker = (element: ISectionElement, key: string) => {
    const languagePicker = element as ILanguagePicker
    return <LanguagePicker key={key} {...languagePicker} />
}
