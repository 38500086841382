import { FC } from 'react'
import {
    StyledDocumentsBodyTable,
    StyledDocumentsBodyTableItem,
    StyledDocumentsTableButton,
    StyledDocumentsTableHeader,
    StyledDocumentsTableHeaderItem,
    StyledInfoPanel,
    StyledSpinnerContainer,
    StyledSupportingDocumentsContainer,
    StyledTitleWrapper,
    StyledUploadButton
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import Spinner from 'components/Spinner'
import { disabledDeleteIcon, disabledUploadIcon, uploadIcon } from './icons'
import { IAwvSupportingDocument } from 'pages/hcp/users/create/index.models'
import moment from 'moment'
import { deleteIcon } from '../icons'

interface ISupportingDocumentsTableProps {
    awvSupportingDocuments: IAwvSupportingDocument[]
    isLoading: boolean
    isBusy: boolean
    canUploadAndDelete: boolean
    onUpload: () => void
    onDownload: (awvSupportingDocument: IAwvSupportingDocument) => void
    onDelete: (awvSupportingDocument: IAwvSupportingDocument) => void
}

const SupportingDocumentsTable: FC<ISupportingDocumentsTableProps> = ({
    awvSupportingDocuments,
    isLoading,
    isBusy,
    canUploadAndDelete,
    onUpload,
    onDownload,
    onDelete
}) => {
    const uploadDisabled = isLoading || !canUploadAndDelete || isBusy
    const deletionDisabled = !canUploadAndDelete || isBusy

    return (
        <StyledSupportingDocumentsContainer>
            <StyledTitleWrapper>
                <Typography type={TYPOGRAPHY_TYPES.h3}>Encounter/Supporting Documents</Typography>
                <StyledUploadButton disabled={uploadDisabled} onClick={onUpload}>
                    <CustomSvgIcon
                        iconSet={{ icon: uploadDisabled ? disabledUploadIcon : uploadIcon }}
                        svg
                    ></CustomSvgIcon>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Upload</Typography>
                </StyledUploadButton>
            </StyledTitleWrapper>
            {isLoading ? (
                <StyledSpinnerContainer>
                    <Spinner />
                </StyledSpinnerContainer>
            ) : (
                <>
                    {awvSupportingDocuments?.length > 0 ? (
                        <>
                            <StyledDocumentsTableHeader>
                                <StyledDocumentsTableHeaderItem>File Name</StyledDocumentsTableHeaderItem>
                                <StyledDocumentsTableHeaderItem>Uploaded On</StyledDocumentsTableHeaderItem>
                                <StyledDocumentsTableHeaderItem>Uploaded By</StyledDocumentsTableHeaderItem>
                                <StyledDocumentsTableHeaderItem>Delete</StyledDocumentsTableHeaderItem>
                            </StyledDocumentsTableHeader>
                            {awvSupportingDocuments.map((x) => (
                                <StyledDocumentsBodyTable key={`supportingDocument-${x.id}`}>
                                    <StyledDocumentsBodyTableItem>
                                        <button disabled={isBusy} onClick={() => onDownload(x)}>
                                            {x.fileName}
                                        </button>
                                    </StyledDocumentsBodyTableItem>
                                    <StyledDocumentsBodyTableItem>
                                        {moment(x.uploadedOn).format('MM/DD/YYYY')}
                                    </StyledDocumentsBodyTableItem>
                                    <StyledDocumentsBodyTableItem>{x.uploadedBy}</StyledDocumentsBodyTableItem>
                                    <StyledDocumentsBodyTableItem>
                                        <StyledDocumentsBodyTableItem>
                                            <StyledDocumentsTableButton
                                                onClick={() => onDelete(x)}
                                                disabled={deletionDisabled}
                                            >
                                                <CustomSvgIcon
                                                    iconSet={{
                                                        icon: !deletionDisabled ? deleteIcon : disabledDeleteIcon
                                                    }}
                                                    svg
                                                ></CustomSvgIcon>
                                            </StyledDocumentsTableButton>
                                        </StyledDocumentsBodyTableItem>
                                    </StyledDocumentsBodyTableItem>
                                </StyledDocumentsBodyTable>
                            ))}
                        </>
                    ) : (
                        <StyledInfoPanel>
                            <Typography type={TYPOGRAPHY_TYPES.p}>
                                No encounter/supporting documents have been uploaded. Please ensure that the file for the associated encounter is attached.
                            </Typography>
                        </StyledInfoPanel>
                    )}
                </>
            )}
        </StyledSupportingDocumentsContainer>
    )
}

export default SupportingDocumentsTable
