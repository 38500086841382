import ReviewableAwvFormsList from 'components/ReviewableAwvFormsList'
import { FC, useEffect, useState } from 'react'
import {
    StyledAWVFormsActionsContainer,
    StyledAWVFormsHeaderContainer,
    StyledAWVFormsScreenHeader,
    StyledCodingAWVFormsContainer,
    StyledEmptyState,
    StyledSearchBarContainer,
    StyledSpinnerContainer
} from './index.style'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import CustomSvgIcon from 'components/CustomSvgIcon'
import PaginationController from 'components/PaginationController'
import { useReviewableAwvFormsListConfig } from 'hooks/useListConfig'
import { useDispatch, useSelector } from 'redux/store'
import useDebounce from 'hooks/useDebounce'
import {
    MemberProfileState,
    approveAwvFormResponse,
    downloadAwvFormDocumentResponse,
    getReviewableAwvForms,
    makeAwvFormResponsePending,
    reviewAwvFormResponse
} from 'redux/slices/memberProfile'
import SearchTextField from 'components/SearchTextField'
import { SortDirection } from 'models/sortColumn'
import { noData } from 'pages/provider/memberOutreach/icons'
import { StyledSpinner } from 'components/Spinner/index.style'
import {
    IReviewableAwvForm,
    ISetAwvFormResponseStatusRequest
} from 'pages/hcp/members/information/PointsInCare/PointsInCare.models'
import { appPath } from 'utilities/appPath'
import ReviewableAwvFormActionConfirmationBox, {
    ReviewableAwvFormAction
} from 'components/ReviewableAwvFormActionConfirmationBox'
import { AsyncThunk } from '@reduxjs/toolkit'
import { hideMainWindowScrollbars } from 'utilities/index'
import { customEvents, pageTitle } from 'appInsights/appInsights'
import usePostCustomEvent from 'hooks/usePostCustomEvent'
import useCurrentUser from 'hooks/useCurrentUser'
import { ERoles } from 'models/enums/role'

interface IReviwableAwvFormsProps {}

const ReviewableAwvForms: FC<IReviwableAwvFormsProps> = ({}) => {
    const dispatch = useDispatch()

    const listConfig = useReviewableAwvFormsListConfig()
    const debouncedFilter = useDebounce(listConfig.effectiveFilter, 500)

    const [initialContentLoaded, setInitialContentLoaded] = useState(false)
    const [isBusy, setIsBusy] = useState(false)

    const [selectedForm, setSelectedForm] = useState<IReviewableAwvForm>(null)

    const [isReviewBeforeOpenBoxOpen, setIsReviewBeforeOpenBoxOpen] = useState(false)
    const [statusAction, setStatusAction] = useState<ReviewableAwvFormAction>(null)
    const [isConfirmStatusActionBoxOpen, setIsConfirmStatusActionBoxOpen] = useState(false)

    const {
        reviewableAwvForms,
        reviewableAwvFormsLoading,
        totalNumberOfReviewableAwvForms,
        filteredNumberOfReviewableAwvForms
    } = useSelector((state: { memberProfile: MemberProfileState }) => state.memberProfile)

    useEffect(() => {
        dispatch(
            getReviewableAwvForms({
                pageNumber: listConfig.pageNumber,
                size: 10,
                sortColumn: listConfig.sortColumn,
                isDescending: listConfig.sortDirection === SortDirection.descending ? true : false,
                filter: debouncedFilter
            })
        ).then(() => {
            setInitialContentLoaded(true)
        })
    }, [dispatch, listConfig.pageNumber, listConfig.sortColumn, listConfig.sortDirection, debouncedFilter])

    useEffect(() => {
        hideMainWindowScrollbars(isReviewBeforeOpenBoxOpen || isConfirmStatusActionBoxOpen)
    }, [isReviewBeforeOpenBoxOpen, isConfirmStatusActionBoxOpen])

    const reviewAndViewFormEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ReviewAndViewForm)
    const viewFormEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ViewForm)

    const openReviewableAwvForm = (form: IReviewableAwvForm) => {
        if (form.type === 'PDF') {
            setIsBusy(true)
            dispatch(
                downloadAwvFormDocumentResponse({
                    memberId: form.memberId,
                    npi: form.providerNpi,
                    year: form.year
                })
            ).then(() => {
                setIsBusy(false)
            })
        } else {
            window.open(
                appPath(
                    `/members/viewElectronicAnnualWellnessVisitForm/${form.memberId}/${form.providerNpi}/${form.year}`
                ),
                '_blank',
                'noreferrer'
            )
        }
    }

    const dispatchSetStatusAction = (
        setStatus: AsyncThunk<any, ISetAwvFormResponseStatusRequest, {}>,
        successCallback: () => void = null
    ) => {
        setIsBusy(true)
        dispatch(
            setStatus({
                memberId: selectedForm.memberId,
                npi: selectedForm.providerNpi,
                year: selectedForm.year
            })
        ).then((action) => {
            setIsBusy(false)
            if (action.type === setStatus.fulfilled.type && successCallback) {
                successCallback()
            }
        })
    }

    const reviewAndOpenCurrentReviewableAwvForm = () => {
        setIsReviewBeforeOpenBoxOpen(false)
        reviewAndViewFormEvent()
        dispatchSetStatusAction(reviewAwvFormResponse, () => openReviewableAwvForm(selectedForm))
    }

    const openCurrentReviewableAwvForm = () => {
        setIsReviewBeforeOpenBoxOpen(false)
        viewFormEvent()
        openReviewableAwvForm(selectedForm)
    }

    const handleOpenReviewableAwvForm = (form: IReviewableAwvForm) => {
        if (form.status === 'Submitted' || form.status === 'Pending') {
            if (isReadOnly) {
                openReviewableAwvForm(form)
            } else {
                setSelectedForm(form)
                setIsReviewBeforeOpenBoxOpen(true)
            }
        } else {
            viewFormEvent()
            openReviewableAwvForm(form)
        }
    }

    const changeStatusApproveEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ChangeStatusApprove)
    const changeStatusInReviewEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ChangeStatusInReview)
    const changeStatusPendingEvent = usePostCustomEvent(pageTitle.SubmittedAWVForms, customEvents.ChangeStatusPending)

    const setReviewableEventName = (action: ReviewableAwvFormAction) => {
        switch (action) {
            case ReviewableAwvFormAction.approve: {
                changeStatusApproveEvent()
                break
            }
            case ReviewableAwvFormAction.review: {
                changeStatusInReviewEvent()
                break
            }
            case ReviewableAwvFormAction.makePending: {
                changeStatusPendingEvent()
                break
            }
            default: {
                break
            }
        }
    }

    const handleSetStatus = (form: IReviewableAwvForm, status: string) => {
        setSelectedForm(form)
        setIsConfirmStatusActionBoxOpen(true)
        if (status === 'InReview') {
            setStatusAction(ReviewableAwvFormAction.review)
        } else if (status === 'Approved') {
            setStatusAction(ReviewableAwvFormAction.approve)
        } else if (status === 'Pending') {
            setStatusAction(ReviewableAwvFormAction.makePending)
        } else {
            setIsConfirmStatusActionBoxOpen(false)
        }
    }

    const setStatus = () => {
        setIsConfirmStatusActionBoxOpen(false)
        if (statusAction === ReviewableAwvFormAction.review) {
            setReviewableEventName(ReviewableAwvFormAction.review)
            dispatchSetStatusAction(reviewAwvFormResponse)
        } else if (statusAction === ReviewableAwvFormAction.approve) {
            setReviewableEventName(ReviewableAwvFormAction.approve)
            dispatchSetStatusAction(approveAwvFormResponse)
        } else if (statusAction === ReviewableAwvFormAction.makePending) {
            setReviewableEventName(ReviewableAwvFormAction.makePending)
            dispatchSetStatusAction(makeAwvFormResponsePending)
        }
    }

    const currentUser = useCurrentUser()
    const isReadOnly = currentUser?.primaryRole !== ERoles.Coding

    return (
        <>
            {isReviewBeforeOpenBoxOpen && (
                <ReviewableAwvFormActionConfirmationBox
                    form={selectedForm}
                    action={ReviewableAwvFormAction.openOrDownload}
                    onConfirm={reviewAndOpenCurrentReviewableAwvForm}
                    onCancel={openCurrentReviewableAwvForm}
                    onCancelAll={() => setIsReviewBeforeOpenBoxOpen(false)}
                />
            )}

            {isConfirmStatusActionBoxOpen && (
                <ReviewableAwvFormActionConfirmationBox
                    form={selectedForm}
                    action={statusAction}
                    onConfirm={setStatus}
                    onCancel={() => setIsConfirmStatusActionBoxOpen(false)}
                />
            )}

            <StyledCodingAWVFormsContainer>
                <StyledAWVFormsHeaderContainer>
                    <StyledAWVFormsScreenHeader>
                        <Typography type={TYPOGRAPHY_TYPES.p}>AWV Forms</Typography>
                    </StyledAWVFormsScreenHeader>
                </StyledAWVFormsHeaderContainer>

                {initialContentLoaded && (
                    <>
                        <StyledAWVFormsActionsContainer>
                            <StyledSearchBarContainer>
                                {totalNumberOfReviewableAwvForms > 0 && (
                                    <SearchTextField
                                        placeholder={'Search by patient, year, provider, provider NPI and more...'}
                                        value={listConfig.filter}
                                        loading={reviewableAwvFormsLoading}
                                        onClearSearch={listConfig.clearFilter}
                                        onValueChanged={listConfig.setFilter}
                                    />
                                )}
                            </StyledSearchBarContainer>
                        </StyledAWVFormsActionsContainer>

                        {reviewableAwvForms && reviewableAwvForms.length > 0 && (
                            <ReviewableAwvFormsList
                                reviewableAwvForms={reviewableAwvForms}
                                sortColumn={listConfig.sortColumn}
                                sortDirection={listConfig.sortDirection}
                                sortCallback={listConfig.setSorting}
                                openReviewableAwvForm={handleOpenReviewableAwvForm}
                                setStatus={handleSetStatus}
                                isBusy={isBusy}
                                isReadOnly={isReadOnly}
                            />
                        )}

                        {filteredNumberOfReviewableAwvForms > 0 && (
                            <PaginationController
                                totalItems={filteredNumberOfReviewableAwvForms}
                                selectedPage={listConfig.pageNumber}
                                selectedPageChanged={listConfig.setPageNumber}
                                singularRecordName="AWV Form"
                                pluralRecordName="AWV Forms"
                            />
                        )}

                        {totalNumberOfReviewableAwvForms === 0 && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}>Data not available at the moment</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                            </StyledEmptyState>
                        )}

                        {totalNumberOfReviewableAwvForms > 0 && filteredNumberOfReviewableAwvForms === 0 && (
                            <StyledEmptyState>
                                <CustomSvgIcon iconSet={{ icon: noData }} svg></CustomSvgIcon>
                                <Typography type={TYPOGRAPHY_TYPES.h4}>Your search didn’t match any results</Typography>
                                <Typography type={TYPOGRAPHY_TYPES.p}>
                                    Please modify your search and try again
                                </Typography>
                            </StyledEmptyState>
                        )}
                    </>
                )}

                {!initialContentLoaded && (
                    <StyledSpinnerContainer>
                        <StyledSpinner viewBox="0 0 50 50">
                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2.5" />
                        </StyledSpinner>
                    </StyledSpinnerContainer>
                )}
            </StyledCodingAWVFormsContainer>
        </>
    )
}

export default ReviewableAwvForms
