import { FC } from 'react'
import { StyledReviewTableHeader, StyledReviewTableHeaderItem } from '../index.style'

interface ITableHeaderProps {
    tableHeaders: string[]
}

const TableHeader: FC<ITableHeaderProps> = ({ tableHeaders }) => {
    return (
        <StyledReviewTableHeader>
            {tableHeaders.map((item: string, idx: number) => {
                return <StyledReviewTableHeaderItem key={`headerItem-${idx}`}>{item}</StyledReviewTableHeaderItem>
            })}
        </StyledReviewTableHeader>
    )
}

export default TableHeader
