import styled from 'styled-components'

export const StyledAwvHeightContainer = styled.article`
    display: flex;
    flex-direction: row;
    width: 400px;
    margin: 0 !important;
    padding: 0;

    :nth-child(1) {
        width: 50px;
    }
`

export const StyledAwvHeightInput = styled.input`
    width: 190px !important;
    text-align: right;
`

export const StyledAwvHeightUnit = styled.p`
    font-size: 90%;
    text-align: right;
    margin: 0;
    padding-right: 5px;
`
