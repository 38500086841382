import { FC, useEffect } from 'react'
import { IDatedBooleanList, IDatedBooleanListItem, ISectionElement } from '../index.models'
import {
    StyledRadioBtnTxtFieldFormContainer,
    StyledTableFormBody,
    StyledTableFormBodyItem,
    StyledTableFormHeader,
    StyledTableFormInputContainer,
    StyledTableFormItem
} from '../CommentedMultipleChoiceList/index.style'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { performFieldSynchronizationStep } from '../utils'

interface IDatedBooleanListProps {
    name: string
    titleColumnLabel: string
    trueColumnLabel: string
    falseColumnLabel: string
    dateColumnLabel: string
    items: IDatedBooleanListItem[]
}

const titleColumnWidth = 380

const trueColumnWidth = 140

const falseColumnWidth = 140

const dateColumnWidth = 400

const DatedBooleanList: FC<IDatedBooleanListProps> = ({
    name,
    titleColumnLabel,
    trueColumnLabel,
    falseColumnLabel,
    dateColumnLabel,
    items
}) => {
    const { register, control, setValue, watch } = useFormContext()
    const { fields, append, remove, move } = useFieldArray({ control, name })

    useEffect(() => {
        performFieldSynchronizationStep(items, fields, append, remove, move)
    }, [items, fields, append, remove, move])

    useEffect(() => {
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i] as any
            if (field.value !== true && field.date) {
                setValue(`${name}.${i}.date`, null)
            }
        }
    }, [fields])

    const labelsByName = items.reduce((acc: any, item: any) => {
        acc[item.name] = item.label
        return acc
    }, {})

    return (
        <StyledRadioBtnTxtFieldFormContainer>
            <StyledTableFormHeader>
                <StyledTableFormItem style={{ width: `${titleColumnWidth}px` }}>{titleColumnLabel}</StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${trueColumnWidth}px` }}>
                    {trueColumnLabel ?? 'Yes'}
                </StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${falseColumnWidth}px` }}>
                    {falseColumnLabel ?? 'No'}
                </StyledTableFormItem>
                <StyledTableFormItem style={{ width: `${dateColumnWidth}px` }}>
                    {dateColumnLabel ?? 'Date'}
                </StyledTableFormItem>
            </StyledTableFormHeader>
            {fields.map((field: any, idx: number) => (
                <StyledTableFormBody key={field.id}>
                    <StyledTableFormBodyItem style={{ width: `${titleColumnWidth}px` }}>
                        {labelsByName[field.name]}
                    </StyledTableFormBodyItem>
                    <StyledTableFormBodyItem style={{ width: `${trueColumnWidth}px` }}>
                        <Controller
                            name={`${name}.${idx}.value`}
                            control={control}
                            render={({ field }) => (
                                <input
                                    id={`${name}.${idx}.value`}
                                    {...field}
                                    type="radio"
                                    value="true"
                                    checked={field.value === true}
                                    onChange={() => setValue(`${name}.${idx}.value`, true)}
                                />
                            )}
                        />
                    </StyledTableFormBodyItem>
                    <StyledTableFormBodyItem style={{ width: `${falseColumnWidth}px` }}>
                        <Controller
                            name={`${name}.${idx}.value`}
                            control={control}
                            render={({ field }) => (
                                <input
                                    id={`${name}.${idx}.value`}
                                    {...field}
                                    type="radio"
                                    value="false"
                                    checked={field.value === false}
                                    onChange={() => {
                                        setValue(`${name}.${idx}.value`, false)
                                        setValue(`${name}.${idx}.date`, null)
                                    }}
                                />
                            )}
                        />
                    </StyledTableFormBodyItem>
                    <StyledTableFormInputContainer style={{ width: `${dateColumnWidth}px` }}>
                        <input
                            type="date"
                            readOnly={watch(`${name}.${idx}.value`) !== true}
                            disabled={watch(`${name}.${idx}.value`) !== true}
                            {...register(`${name}.${idx}.date`)}
                        />
                    </StyledTableFormInputContainer>
                </StyledTableFormBody>
            ))}
        </StyledRadioBtnTxtFieldFormContainer>
    )
}

export const renderDatedBooleanList = (element: ISectionElement, key: string) => {
    const datedBooleanList = element as IDatedBooleanList
    return <DatedBooleanList key={key} {...datedBooleanList} />
}
