import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { IPointsInCare, IPointsInCareReview } from 'pages/hcp/users/create/index.models'
import React, { FC } from 'react'
import {
    StyledEmptyStateContainer,
    StyledReviewTableContainerWrap,
    StyledReviewTableHeader,
    StyledReviewTableHeaderItem
} from './index.style'
import TableBody from './TableBody'
import TableHeader from './TableHeader'
import { IProviderConsiderationFeedbackResponse } from '../PointsInCare.models'

const headerItems = [
    // 'Confidence',
    'Diagnosis Code',
    'HCC',
    'Condition Category Description',
    'Last Reported',
    'Reporting Provider',
    'Condition',
    'Feedback'
]

interface IReviewTableProps {
    pointsInCareReview: IPointsInCareReview[]
    saveDraft: (selections: IProviderConsiderationFeedbackResponse[]) => void
    isSubmitted?: boolean
}

const ReviewTable: FC<IReviewTableProps> = ({ pointsInCareReview, saveDraft, isSubmitted }) => {
    const tableIsEmpty: boolean = pointsInCareReview.length <= 0
    return (
        <StyledReviewTableContainerWrap>
            <Typography type={TYPOGRAPHY_TYPES.p}>
                Fusce posuere, enim eget semper sagittis, urna orci semper turpis, nec congue nisl purus quis metus.
                Phasellus mollis orci ipsum, sit amet tempus turpis eleifend quis. Fusce mauris felis, dapibus at
                fringilla id.
            </Typography>
            <TableHeader tableHeaders={headerItems} />
            {tableIsEmpty ? (
                <StyledEmptyStateContainer>
                    <Typography type={TYPOGRAPHY_TYPES.h2}>Data not available at the moment</Typography>
                    <Typography type={TYPOGRAPHY_TYPES.p}>Please check back later</Typography>
                </StyledEmptyStateContainer>
            ) : (
                <TableBody reviewData={pointsInCareReview} onSaveDraft={saveDraft} isSubmitted={isSubmitted} />
            )}
        </StyledReviewTableContainerWrap>
    )
}

export default ReviewTable
