import styled, { css } from 'styled-components'
import { pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'

const { dark_gray, white, light_blue3, light_gray3, grey } = UI_COLORS

const tableReset = css`
    list-style: none;
    padding: 0;
    display: flex;
`

export const StyledDocumentsTableWrapper = styled.article`
    padding-bottom: ${pxToRem(42)};
`

export const StyledTitleWrapper = styled.article`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: ${pxToRem(18)};
        line-height: ${pxToRem(26)};
        letter-spacing: -0.01em;
        color: ${dark_gray};
        margin-block-start: 1em;
        margin-block-end: 1em;

        position: relative;

        :after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: inline-block;
            width: ${pxToRem(40)};
            height: ${pxToRem(3)};
            background-color: ${dark_gray};
        }
    }
`

export const StyledUploadBtn = styled.button`
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;

    display: flex;
    flex-direction: row;
    align-items: center;

    color: #2281c4;
    text-align: center;
    font-family: 'Inter', 'sans-serif';
    font-size: ${pxToRem(14)};
    font-style: normal;
    font-weight: 600;
    line-height: 130%;

    cursor: pointer;

    figure {
        margin: 0;
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin-right: 6px;
    }

    :disabled {
        cursor: default;

        p {
            color: ${grey};
        }
    }
`

export const StyledDocumentsHeaderTable = styled.ul`
    ${tableReset}
    width: ${pxToRem(1145)};
    height: ${pxToRem(40)};
    padding-left: ${pxToRem(40)};
    align-items: center;

    background-color: ${light_blue3};
    margin-bottom: 0;
    padding-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
`

export const StyledDocumentsHeaderTableItem = styled.li`
    font-family: 'Inter', 'san-serif';
    font-size: ${pxToRem(12)};
    font-weight: 600;
    line-height: ${pxToRem(16)};
    letter-spacing: 0em;
    color: ${light_gray3};

    :nth-child(1) {
        width: 365px;
    }

    :nth-child(2) {
        width: 210px;
    }

    :nth-child(3) {
        width: 100px;
        /* background-color: blue; */
    }

    :nth-child(4) {
        width: 210px;
        /* background-color: orange; */
    }

    :nth-child(5) {
        width: 60px;
        /* background-color: yellow; */
    }
    :nth-child(6) {
        width: 80px;
        /* background-color: green; */
    }
    :nth-child(7) {
        width: 80px;
        /* background-color: purple; */
    }
`

export const StyledDocumentsBodyTable = styled.ul`
    ${tableReset}
    width: ${pxToRem(1145)};
    height: ${pxToRem(76)};
    padding-left: ${pxToRem(40)};
    align-items: center;

    background-color: ${white};
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 1px solid #dee5ef;
`

export const StyledDocumentsBodyTableItem = styled.li`
    font-family: 'Inter', 'san-serif';
    font-size: ${pxToRem(12)};
    font-weight: 600;
    font-style: normal;
    line-height: ${pxToRem(16)};
    letter-spacing: 0em;
    font-weight: 600;
    color: ${dark_gray};

    button {
        padding: 0;
        background-color: transparent;
        border: none;
        color: #2281c4;
        font-family: 'Inter', 'san-serif';
        font-size: ${pxToRem(12)};
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        cursor: pointer;

        :disabled {
            cursor: default;
            color: ${grey};
        }
    }

    :nth-child(1) {
        width: 365px;
        /* background-color: red; */
    }

    :nth-child(2) {
        width: 210px;
    }

    :nth-child(3) {
        width: 100px;
        /* background-color: blue; */
    }

    :nth-child(4) {
        width: 210px;
        /* background-color: orange; */
    }

    :nth-child(5) {
        width: 60px;
        /* background-color: yellow; */
    }
    :nth-child(6) {
        width: 80px;
        /* background-color: green; */
    }
    :nth-child(7) {
        width: 80px;
        /* background-color: purple; */
    }

    figure {
        margin: 0;
    }
`

export const StyledDocumentsTableButton = styled.button<{ hasFeedback?: boolean }>`
    width: ${pxToRem(50)};
    height: ${pxToRem(50)};
    background-color: transparent;
    border: none;
    cursor: pointer;

    position: relative;

    figure {
        margin: 0;
        padding: 0;
    }

    :disabled {
        cursor: default;

        span {
            cursor: default;
        }
    }

    div {
        display: none;
    }
    ${(props) => {
        if (props.hasFeedback) {
            return css`
                div {
                    display: block;
                    position: absolute;
                    top: 7px;
                    right: 12px;
                }
            `
        }
    }}
`

export const StyledInfoPanel = styled.article`
    p {
        width: ${pxToRem(750)};
        font-family: 'Inter', 'sans-serif';
        font-style: normal;
        font-weight: 500;
        font-size: ${pxToRem(15)};
        line-height: ${pxToRem(22)};
        color: ${dark_gray};
        margin: 0;
    }
`
