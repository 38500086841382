import CustomSvgIcon from 'components/CustomSvgIcon'
import Typography from 'components/Typography'
import { TYPOGRAPHY_TYPES } from 'components/Typography/index.models'
import { FC, useState } from 'react'
import {
    StyledUploadButton,
    StyledUploadButtonWrap,
    StyledUploadDetailsContainer
} from '../members/information/AWV/index.style'
import DropZone from 'components/DropZone'
import { uploadIcon } from '../members/information/AWV/icons'
import { isFileOutsideMaxSizeLimit } from 'utilities/uploadUtilities'
import { StyledUploadTemplateDetailsContainer } from './index.style'

interface IFileUploaderProps {
    contentType: string
    extension: string
    title: string
    uploadAwvTemplate: (file: any) => Promise<void>
}

const FileUploader: FC<IFileUploaderProps> = ({ contentType, extension, title, uploadAwvTemplate }) => {
    const [file, setFile] = useState(null)
    const [validFile, setValidFile] = useState()

    const handleUpload = () => {
        if (!file || isFileOutsideMaxSizeLimit(file[0])) {
            return
        }

        uploadAwvTemplate(file[0]).then(() => {
            setFile(null)
            setValidFile(undefined)
        })
    }

    return (
        <StyledUploadTemplateDetailsContainer>
            <StyledUploadDetailsContainer>
                <Typography type={TYPOGRAPHY_TYPES.h2}>{title}</Typography>
                <Typography type={TYPOGRAPHY_TYPES.p}>
                    The following are acceptable file extensions: {extension}
                </Typography>

                <DropZone
                    contentType={contentType}
                    extension={extension}
                    selectedFile={setFile}
                    acceptableFile={setValidFile}
                />

                <StyledUploadButtonWrap>
                    <StyledUploadButton onClick={handleUpload} disabled={!validFile}>
                        Upload<CustomSvgIcon iconSet={{ icon: uploadIcon }} svg></CustomSvgIcon>
                    </StyledUploadButton>
                </StyledUploadButtonWrap>
            </StyledUploadDetailsContainer>
        </StyledUploadTemplateDetailsContainer>
    )
}

export default FileUploader
