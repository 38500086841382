import styled, { css } from 'styled-components'
import { DEVICE, pxToRem } from 'utilities'
import { UI_COLORS } from 'theme'
import { EConfidenceStatus } from '../pointsInCareModel'

const { dark_gray, white, grey } = UI_COLORS

const tableReset = css`
    list-style: none;
    padding: 0;
    margin: 0;
`

export const StyledReviewTableContainerWrap = styled.article`
    ${DEVICE.desktop} {
        width: ${pxToRem(1118)};
    }
`

export const StyledReviewTableHeader = styled.ul`
    width: 1145px;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ebeff7;

    display: flex;
    flex-direction: row;
`

export const StyledReviewTableHeaderItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #73839c;

    padding: 12px 0;

    /* :nth-child(1) {
        margin-left: 26px;
        width: 120px;
    } */
    /* background-color: red; */
    :nth-child(1) {
        margin-left: 26px;
        width: 290px;
        /* background-color: blue; */
    }
    :nth-child(2) {
        width: 70px;
        /* background-color: orange; */
    }
    :nth-child(3) {
        width: 220px;
        /* background-color: green; */
    }
    :nth-child(4) {
        width: 120px;
        /* background-color: yellow; */
    }
    :nth-child(5) {
        width: 130px;
        /* background-color: brown; */
    }
    :nth-child(6) {
        width: 200px;
        /* background-color: gold; */
    }
    :nth-child(7) {
        width: 50px;
        /* background-color: gold; */
    }
`

export const StyledTableBodyRowContainer = styled.article`
    width: 1145px;
`

export const StyledTableBodyRow = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ffffff;
    border-bottom: 1px solid #dee5ef;

    display: flex;
    flex-direction: row;
`

export const StyledTableBodyRowInner = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    background: #ffffff;

    display: flex;
    flex-direction: row;
`

export const StyledTableBodyItem = styled.li<{ status?: string }>`
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #293854;
    }

    padding: 30px 0;

    :nth-child(1) {
        /* margin-left: 26px;
        width: 220px; */

        /* p {
            width: 90px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 800;
            font-size: 10px;
            line-height: 12px;
            color: #ffffff;
            text-transform: uppercase;
            border-radius: 10px;

            ${(props) => {
            if (props.status === EConfidenceStatus.VERY_HIGH) {
                return css`
                    background-color: #df3030;
                `
            }
        }}

            ${(props) => {
            if (props.status === EConfidenceStatus.HIGH) {
                return css`
                    background-color: #b11b1b;
                `
            }
        }}

            ${(props) => {
            if (props.status === EConfidenceStatus.LOW) {
                return css`
                    background-color: #f2a818;
                `
            }
        }}
        } */
    }

    /* :nth-child(1) {
        margin-left: 26px;
        width: 220px;
    } */
    /* background-color: red; */
    :nth-child(1) {
        margin-left: 26px;
        width: 290px;
        p {
            width: 260px;
        }
        /* background-color: blue; */
    }
    :nth-child(2) {
        width: 70px;
        /* background-color: orange; */
    }
    :nth-child(3) {
        width: 220px;
        /* background-color: green; */
    }
    :nth-child(4) {
        width: 120px;
        /* background-color: yellow; */
    }
    :nth-child(5) {
        width: 130px;
        /* background-color: brown; */
    }
    :nth-child(6) {
        width: 200px;
        /* background-color: gold; */
    }
    :nth-child(7) {
        width: 50px;
        /* background-color: gold; */
    }
`

export const StyledFeedbackLabel = styled.label`
    display: flex;
    :hover {
        cursor: pointer;
    }
`

export const StyledFeedbackList = styled.ul`
    ${tableReset}
    width: 200px;
    padding: 43px 0;
    display: flex;
    flex-direction: column;
`

export const StyledFeedbackListItem = styled.li`
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #394a64;

    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 10px;

    input {
        margin: 0;
        margin-right: 6px;
    }
`
export const StyledReadOnlyFeedbackStatus = styled.article`
    display: flex;
    width: 200px;
    padding: 30px 0;
    flex-direction: row;
    p {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #394a64;
        height: 30px;
    }
`

export const StyledEmptyStateContainer = styled.article`
    background-color: #ffffff;
    width: ${pxToRem(1145)};
    height: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        color: #293854;
        font-size: 18px;
        font-weight: 700;
        font-family: 'Inter', sans-serif;
        margin-bottom: 10px;
    }

    p {
        color: #73839c;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
        margin: 0;
    }
`
